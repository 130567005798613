<template>
  <generic-modal close-button :opened="open" size="lg" @close="$emit('close')">
    <template v-slot:header>
      <div class="modal-box__title">Отчёт об аккредитационной экспертизе</div>
    </template>
    <template v-slot:body>
      <div class="modal-box__block">
        <div class="block">
          <div class="block__main">
            <div class="row row--aife">
              <div class="col col--full">
                <text-area
                    label="Полное наименование ОО"
                    :model-value="form.organization.name"
                    is-disabled
                />
              </div>
              <div class="col col--full">
                <input-text
                    label="Адрес"
                    :model-value="form.organization.legalAddress"
                    is-disabled
                />
              </div>
              <div class="col col--half">
                <input-text
                    label="Уровень образования"
                    :model-value="form.educationLevel"
                    is-disabled
                />
              </div>
              <div class="col col--half">
                <input-text
                    label="Наименование направления подготовки"
                    :model-value="form.speciality.name"
                    is-disabled
                />
              </div>
              <div class="col col--half">
                <input-text label="Название УГСН" :model-value="form.egs.name" is-disabled />
              </div>
              <div class="col col--fourth">
                <input-text label="Код УГСН" :model-value="form.egs.code" is-disabled />
              </div>
              <div class="col col--fourth">
                <input-text label="Код направления подготовки" :model-value="form.speciality.code" is-disabled />
              </div>
              <div class="col col--full">
                <input-text
                    label="Адрес реализации ООП"
                    :model-value="form.organization.address"
                    is-disabled
                />
              </div>
              <div class="col col--half">
                <input-text
                    label="ФИО эксперта"
                    :model-value="form.expert.name"
                    is-disabled
                />
              </div>
              <div class="col col--fourth">
                <input-text
                    label="Дата распоряжения"
                    :model-value="this.$filters.formatDate(new Date(new Date(form.order.date).setHours(new Date(form.order.date).getHours() + 3)))"
                    button="calendar"
                    is-disabled
                />
              </div>
              <div class="col col--fourth">
                <input-text label="Номер распоряжения"
                            :model-value="form.order.number" is-disabled />
              </div>
              <div class="col col--full">
                <div class="block-subtitle">Не предоставленные документы</div>
                <input-text
                    v-for="(document, index) in form.missingDocuments"
                    :key="document.id"
                    :label="`Документ ${index + 1}`"
                    v-model="document.name"
                    placeholder="Название документа"
                    removable
                    @remove="form.missingDocuments.splice(index, 1)"
                />
              </div>
              <div class="col col--full">
                <custom-button :type="4" label="Добавить еще документ" icon="add" @click="addMissingDocument"/>
              </div>
              <div class="col col--full">
                <custom-select
                    label="Соответствие ООП требованиям аккредитационных показателей"
                    v-model="form.complies"
                    :has-error="vuelidate.form.complies.$error"
                    :vuelidate-errors="vuelidate.form.complies.$errors"
                    :options="[
                    {value: 'Соответствует'},
                    {value: 'Не соответствует'}
                    ]"
                />
              </div>
              <div class="col col--fourth">
                <input-text
                    label="Дата составления отчета"
                    v-model="form.date"
                    :model-value="new Date(new Date(form.date).setHours(new Date(form.date).getHours() + 3))"
                    :has-error="vuelidate.form.date.$error"
                    :vuelidate-errors="vuelidate.form.date.$errors"
                    is-date
                    button="calendar"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="line line--jcfe">
        <div class="line__item">
          <custom-button label="Сформировать" @click="generateReport"/>
        </div>
      </div>
    </template>
  </generic-modal>
</template>

<script>
import CustomButton from '@/components/CustomButton';
import InputText from '@/components/InputText';
import GenericModal from '@/components/modals/GenericModal';
import TextArea from '@/components/TextArea';
import axios from '@/api/axios';
import useVuelidate from '@vuelidate/core';
import { required } from '@/utils/validators';
import CustomSelect from "@/components/CustomSelect.vue";

export default {
  name: 'GenerateExpertiseReport',
  components: { CustomSelect, CustomButton, InputText, GenericModal, TextArea },
  watch: {
    organization(organization) {
      this.form.organization.name = organization.name;
      this.form.organization.address = organization.address;
      this.form.organization.legalAddress = organization.legalAddress;
    },
    specialityCode(code) {
      this.form.speciality.code = code;
    },
    specialityName(name) {
      this.form.speciality.name = name;
    },
    egsCode(code) {
      this.form.egs.code = code;
    },
    egsName(name) {
      this.form.egs.name = name;
    },
    order(order) {
      this.form.order.number = order.number;
      this.form.order.date = new Date(new Date(order.createdAt).setHours(new Date(order.createdAt).getHours() + 3));
    },
    educationLevel(educationLevel) {
      this.form.educationLevel = educationLevel;
    }
  },
  setup() {
    return { vuelidate: useVuelidate() };
  },
  data() {
    return {
      form: {
        organization: {
          name: null,
          address: null,
          legalAddress: null,
        },
        educationLevel: '',
        speciality: {
          name: null,
          code: null,
        },
        egs: {
          name: null,
          code: null,
        },
        expert: {
          name: this.expert,
        },
        order: {
          date: null,
          number: null,
        },
        missingDocuments: [],
        complies: 'Соответствует',
        date: new Date(new Date().setHours(new Date().getHours() + 3)),
      }
    };
  },
  validations() {
    return {
      form: {
        complies: { required },
        date: { required },
      }
    };
  },
  methods: {
    addMissingDocument() {
      this.form.missingDocuments.push({ id: performance.now(), name: null });
    },
    generateReport() {
      this.vuelidate.form.$touch();
      if (this.vuelidate.form.$invalid) {
        return;
      }

      const { organization, speciality, educationLevel, egs, expert, order, missingDocuments, date, complies } = this.form;
      const adjustedDate = new Date(date);
      adjustedDate.setHours(adjustedDate.getHours() + 3);
      const submitForm = {
        organization,
        speciality: {
          name: speciality.name,
          code: speciality.code,
          educationLevel: educationLevel,
          egs: egs
        },
        expert,
        order,
        missingDocuments: missingDocuments.map(document => document.name),
        compliance: complies,
        createdAt: adjustedDate,
        expertiseId: this.expertiseId,
        organizationId: this.organizationId
      };

      axios
          .post('templates/report', submitForm, { responseType: 'blob' })
          .then(this.downloadFile)
          .then(() => this.$emit('close'))
          .catch(() => window.alert('Не удалось создать шаблон отчета. Попробуйте, пожалуйста, позже!'));
    },
    downloadFile(response) {
      const blob = response.data;
      let anchor = document.createElement("a");
      document.body.appendChild(anchor);

      let objectUrl = window.URL.createObjectURL(blob);

      anchor.href = objectUrl;
      anchor.download = 'отчёт.docx';
      anchor.click();

      window.URL.revokeObjectURL(objectUrl);
    },
  },
  props: {
    organization: {
      type: Object,
      required: true,
    },
    expert: {
      type: String,
      required: true,
    },
    specialityCode: {
      type: String,
      required: true,
    },
    specialityName: {
      type: String,
      required: true,
    },
    egsCode: {
      type: String,
      required: true,
    },
    egsName: {
      type: String,
      required: true,
    },
    order: {
      type: Object,
      required: true,
    },
    educationLevel: {
      type: String,
      required: true,
    },
    open: Boolean,
    expertiseId: String,
    organizationId: String,
  },
  emits: ['close']
};
</script>
