<template>
  <div class="start-page">
    <div class="start-page__wrap">
      <div class="start-page__top">
        <div class="start-page__logo">
          <img
            src="~@/assets/images/logo-visual.svg"
            width="48"
            height="42"
            alt="Логотип ИС ГА"
            class="logo__img"
          />
        </div>
        <div class="start-page__title">
          Информационная система<br />государственной аккредитации
        </div>
      </div>

      <div class="start-page__main start-page__main--wide">
        <form class="start-page__form">
          <div class="start-page__groups">
            <div class="start-page__group" style="text-align: justify;">
              Документы на государственную аккредитацию необходимо направлять через Единый портал
              государственных и муниципальных услуг (функций) по соответствующей ссылке
              <a href="https://www.gosuslugi.ru/610173/1/form">https://www.gosuslugi.ru/610173/1/form</a>
            </div>
          </div>
        </form>
      </div>

      <div class="start-page__bottom">
        <div class="start-page__contacts">
          <p>Техническая поддержка</p>
          <p>
            <a href="tel:+74956404036" class="link link--gray link--underline"
              >+7 (8362) 41-38-84, +7 (495) 640-40-36 доб. 134, 136 </a
            >,
          </p>
          <p>
            <a href="mailto:support@msk.nica.ru" class="link link--gray link--underline"
              >Написать по e-mail</a
            >
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Maintenance',
};
</script>

<style scoped></style>
