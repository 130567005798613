<template>
  <table class="tbl">
    <thead>
      <tr>
        <th v-for="column in columns" :key="column">{{ column }}</th>
      </tr>
    </thead>
    <tbody>
      <template v-for="(group, index) in rowGroups" :key="group.title || index">
        <tr v-if="group.title">
          <td :colspan="columns.length" class="tbl-panel">{{ group.title }}</td>
        </tr>
        <tr v-for="(row, index) in group.rows" :key="index">
          <td v-for="(cell, index) in row" :key="cell + index">{{ cell }}</td>
        </tr>
      </template>
      <tr v-if="!rowGroups.length">
        <td :colspan="columns.length" class="align-center">Данные отсутствуют</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: 'CustomTable',
  props: {
    columns: {
      type: Array,
      default: () => ['Столбец 1'],
    },
    rowGroups: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
