import axios from '@/api/axios';

export const ExpertJobsRepository = {
  /**
   * @returns {Promise<Job[]>}
   */
  async getForAuthenticatedExpert() {
    const response = await axios.get('expert/jobs');
    return response.data.data;
  },
  /**
   * @param job
   * @returns {Promise<void>}
   */
  async addForAuthenticatedExpert(job) {
    await axios.post('expert/jobs', {
      organization_name: job.organizationName,
      started_at: job.startedAt,
      ended_at: job.endedAt,
      is_current: job.isCurrent,
      is_previous: job.isPrevious,
    });
  },
  /**
   * @param {string} jobID
   * @returns {Promise<void>}
   */
  async removeForAuthenticatedExpert(jobID) {
    await axios.delete(`expert/jobs/${jobID}`);
  },
};

/**
 * @typedef Job
 * @property {string} id
 * @property {Organization|null} organization
 * @property {string|null} text
 * @property {string|null} startedAt
 * @property {string|null} endedAt
 * @property {boolean} isCurrent
 * @property {boolean} isPrevious
 */

/**
 * @typedef Organization
 * @property {string} id
 * @property {string} name
 */
