<template>
  <div class="form-group">
    <label v-if="label" :for="id" class="form-label">{{ label }}</label>

    <form-field
      class="form-field--select"
      :class="{
        ['form-field--' + type]: true,
        ['form-field--' + size]: true,
        ['form-field--' + color]: true,
        'has-error': hasError,
        disabled: isDisabled,
      }"
    >
      <select
        v-model="selected"
        :name="name"
        :id="id"
        :disabled="isDisabled"
        :multiple="isMultiple"
        ref="select"
        class="form-control"
      >
        <option v-if="placeholder" value="" disabled data-placeholder="true">
          {{ placeholder }}
        </option>
        <option v-for="option in options" :value="option.value" :key="option.value + option.text">
          {{ option.text || option.value }}
        </option>
      </select>
    </form-field>

    <div v-if="errors.length > 0" class="error-text error-text--right">
      {{ errors.join('. ') }}
    </div>
  </div>
</template>

<script>
import FormField from '@/components/FormField';
import SlimSelect from 'slim-select';

export default {
  name: 'CustomSelect',
  components: { FormField },
  data() {
    return {
      slimSelect: null,
    };
  },
  computed: {
    selected: {
      get() {
        return this.modelValue;
      },
      set(value) {
        if (value === this.modelValue) {
          return;
        }

        this.$emit('update:modelValue', value);
      },
    },
    errors() {
      return this.vuelidateErrors.map((e) => e.$message);
    },
  },
  mounted() {
    this.slimSelect = new SlimSelect({
      select: this.$refs.select,
      showSearch: false,
    });
  },
  watch: {
    modelValue(newValue) {
      if (newValue == null) {
        return this.slimSelect.set();
      }
      return this.slimSelect.set(newValue);
    },
    isDisabled(newValue) {
      if (newValue) {
        return this.slimSelect.disable();
      }
      return this.slimSelect.enable();
    },
  },
  props: {
    type: {
      type: Number,
      default: 2,
    },
    size: {
      type: String,
      default: 's',
    },
    color: {
      type: String,
      default: 'primary',
    },
    modelValue: {
      type: [Array, String, Number],
      default: () => '',
    },
    vuelidateErrors: {
      type: Array,
      default: () => [],
    },
    options: Array,
    hasError: Boolean,
    isDisabled: Boolean,
    isMultiple: Boolean,
    placeholder: String,
    id: [Number, String],
    label: String,
    name: String,
  },
};
</script>

<style scoped></style>
