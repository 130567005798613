<template>
  <div class="col col--full">
    <hr />
    <div class="description">
      {{ order }}. {{ description }} <b v-if="required">*</b><span v-else>(при наличии)</span>
    </div>
  </div>
  <div class="col col--full">
    <input-text label="Ссылка на ресурс" :is-disabled="disabled" v-model="link.path" />
    <div v-if="error" class="error-text">{{ error }}</div>
  </div>
  <div class="col col--full">
    <request-document-uploader
      v-if="!disabled"
      :request-id="requestId"
      :document-type-id="id"
      :program-id="programId"
      @uploaded="$emit('uploaded')"
    />
    <div class="files">
      <downloadable-file
        v-for="document in files"
        :key="document.id"
        :name="document.name"
        :link="document.path"
        :deletable="!disabled"
        @delete="deleteDocument(document.id)"
      />
    </div>
  </div>
</template>

<script>
import InputText from '@/components/InputText';
import DownloadableFile from '@/components/DownloadableFile';
import RequestDocumentUploader from '@/components/temp/RequestDocumentUploader';
import { RequestDocuments } from '@/repositories';
export default {
  name: 'RequestedDocumentType',
  props: {
    order: [String, Number],
    description: String,
    id: {
      type: [String, Number],
      required: true,
    },
    requestId: {
      type: String,
      required: true,
    },
    programId: {
      type: String,
      required: true,
    },
    required: Boolean,
    disabled: Boolean,
    documents: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['uploaded', 'deleted'],
  data() {
    return {
      link: {
        id: null,
        path: null,
      },
      error: null,
    };
  },
  watch: {
    id() {
      const link = this.documents.find((document) => document.is_link);
      if (!link) {
        return this.resetLink();
      }
      this.link.path = link.path;
      this.link.id = link.id;
    },
    documents() {
      if (this.link.id || this.link.path) {
        return;
      }

      const link = this.documents.find((document) => document.is_link);
      if (!link) {
        return;
      }

      this.link.path = link.path;
      this.link.id = link.id;
    },
  },
  computed: {
    files() {
      return this.documents.filter((document) => !document.is_link);
    },
  },
  methods: {
    deleteDocument(documentId) {
      RequestDocuments.remove(this.requestId, documentId)
        .then(() => this.$emit('deleted'))
        .catch(() => window.alert('Не удалось удалить документ. Попробуйте, пожалуйста, позже!'));
    },
    submitLink() {
      // debugger
      /* Если у ссылки указан id, то это существующий документ и его нужно поменять/удалить. Иначе это новый */
      const link = this.link;
      if (!link.id && !link.path) {
        return;
      }

      if (!link.id) {
        return this.storeLink();
      }

      if (!link.path) {
        return this.deleteLink();
      }

      return this.updateLink();
    },
    storeLink() {
      const payload = {
        type_id: this.id,
        program_id: this.programId,
        path: this.link.path,
      };

      RequestDocuments.add(this.requestId, payload)
        .then((link) => (this.link.id = link.id))
        .then(() => (this.error = null))
        .catch(() => (this.error = 'Не удалось сохранить ссылку. Попробуйте, пожалуйста, позже!'));
    },
    updateLink() {
      RequestDocuments.update(this.link.id, this.link.path)
        .then(() => (this.error = null))
        .catch(() => (this.error = 'Не удалось изменить ссылку. Попробуйте, пожалуйста, позже!'));
    },
    deleteLink() {
      RequestDocuments.remove(this.requestId, this.link.id)
        .then(this.resetLink)
        .then(() => (this.error = null))
        .catch(() => (this.error = 'Не удалось изменить ссылку. Попробуйте, пожалуйста, позже!'));
    },
    resetLink() {
      this.link.id = null;
      this.link.path = null;
    },
  },
  components: { InputText, DownloadableFile, RequestDocumentUploader },
};
</script>
