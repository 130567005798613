<template>
  <main class="main">
    <div class="main__wrap main__wrap--sm">
      <div class="main__header">
        <div class="main__title">
          <div class="title">Аккредитационный мониторинг</div>
        </div>
      </div>
      <div class="main__body">
        <div class="main__block">
          <div class="notice notice--info notice--s">
            <div class="notice__icon">
              <svg-icon name="info" :size="24" />
            </div>
            <div class="notice__text">
              <strong>Служба поддержки</strong><br /><br />Адрес электронной почты по техническим
              вопросам <a href="mailto:support@msk.nica.ru">support@msk.nica.ru</a><br /><br /><a
                href="/storage/documents/monitoring/monitoring-instruction.pdf"
                download
                target="_blank"
                class="link link--underline"
                >Инструкция</a
              >
              <br />
              <br />
              <strong>ВНИМАНИЕ:</strong> При обращении на почту технической поддержки необходимо
              указать следующие данные ОО:
              <dl>
                <li>Тип организации (Школа, ССУЗ, ВУЗ (филиал ВУЗ))</li>
                <li>ИНН</li>
                <li>ОГРН</li>
                <li>КПП</li>
                <li>Наименование организации</li>
              </dl>
              Часть системы мониторинга с которой у Вас возникли проблемы
              <dl>
                <li>Список образовательных программ</li>
                <li>Вход в систему</li>
                <li>Ввод показателей</li>
                <li>Печать итогового отчета по образовательной программе и т.д.</li>
                <li>а так же прилагать скриншот возникшей проблемы.</li>
              </dl>

              В противном случае обращение рассмотрено не будет!
            </div>
          </div>
        </div>

        <div class="main__block">
          <div class="block">
            <div class="block__main">
              <div class="tbl-wrap">
                <table class="tbl-list">
                  <thead>
                    <tr>
                      <th>Дата начала мониторинга</th>
                      <th>Дата окончания мониторинга</th>
                      <th>Уровень образования</th>
                      <th style="width: 92px">Статус</th>
                    </tr>
                  </thead>
                  <tbody v-if="tasks.length">
                    <tr
                      v-for="row in rows"
                      :key="row.id"
                      @click="redirectToTaskPrograms(row.task.id, row.level.ids)"
                      class="tbl-link"
                    >
                      <td>{{ getDate(row.task.date_start) }}</td>
                      <td>{{ getDate(row.task.date_end) }}</td>
                      <td>{{ row.level.name }}</td>
                      <td>{{ getTaskStatus(row.task.status_id) }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div v-if="!tasks.length && !isLoading" class="main__block">
          <div class="blank">
            <div class="blank__cover">
              <svg-clr name="accreditation-monitoring" :size="24" />
            </div>
            <div class="blank__text">Данные отсутствуют</div>
          </div>
        </div>
      </div>

      <div v-if="isLoading" class="main__over">
        <div class="loader loader--l">
          <svg-clr name="loader" size="24" />
        </div>
      </div>
    </div>
  </main>
</template>

<script setup>
import { computed, onBeforeMount, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { Monitoring } from '@/repositories';
import SvgIcon from '@/components/SvgIcon';
import SvgClr from '@/components/SvgClr';
import { orderBy } from '@/utils/helpers';
import { unique } from '@/utils/helpers/unique';

const router = useRouter();
const store = useStore();

const tasks = ref([]);
const isLoading = ref();

const getTaskStatus = (status) => (status === 1 ? 'Проводится' : 'Завершен');
const getDate = (date) => {
  return date ? new Date(date).toLocaleDateString() : '';
};

const redirectToTaskPrograms = (taskId, levelsIds = null) => {
  if (store.state.user.isExternal) {
    router.push({
      name: 'external.monitoring.task-programs',
      params: { id: taskId },
      query: { levels: levelsIds },
    });
  } else {
    router.push({
      name: 'monitoring.task-programs',
      params: { id: taskId },
      query: { levels: levelsIds },
    });
  }
};

/*
 * Собираем список из уровней с их задачей мониторинга.
 * Объединяем уровни общего образования в один.
 */
const rows = computed(() => {
  return tasks.value
    .map((task) => {
      const levels = task.levels?.map((level) => {
        const GENERAL_EDUCATION_LEVELS_IDS = [2, 3, 4];
        const isGeneralEducationLevel = GENERAL_EDUCATION_LEVELS_IDS.includes(level.id);
        const levelsIds = isGeneralEducationLevel ? GENERAL_EDUCATION_LEVELS_IDS : [level.id];
        return {
          task: task,
          level: {
            ids: levelsIds,
            name: isGeneralEducationLevel ? 'Общее образование' : level.name,
          },
          id: task.id + levelsIds.join(''),
          order: level.order,
        };
      });
      return orderBy(unique(levels, 'id'), ['order']);
    })
    .flat(1);
});

async function getTasks() {
  try {
    const response = await Monitoring.getTasks();
    tasks.value = response;
  } catch (error) {
    console.log(error);
  } finally {
    isLoading.value = false;
  }
}

onBeforeMount(() => {
  isLoading.value = true;
});

onMounted(() => {
  getTasks();
});
</script>
