<template>
  <div class="form-group">
    <div v-if="label" :class="{ 'form-label--required': isRequired }" class="form-label">
      {{ label }}
    </div>
    <form-field
      class="form-field--files"
      :class="{
        [`form-field--${type}`]: true,
        [`form-field--${size}`]: true,
        [`form-field--${color}`]: true,
        'has-error': hasError,
        disabled: isDisabled,
      }"
    >
      <div class="file-attach">
        <div class="file-attach__main">
          <template v-if="inProgress || hasError">
            <div class="file-attach__cover" :class="{ 'file-attach__cover--error': hasError }">
              <div class="loader">
                <SvgClr v-if="inProgress" name="loader" :size="24" />
                <SvgIcon v-else-if="hasError" name="warning" :size="24" />
              </div>
            </div>
          </template>
          <div class="file-attach__placeholder">
            {{ fileName || 'Прикрепите файл' }}
          </div>
        </div>
        <template v-if="inProgress">
          <div class="file-attach__label">
            <button class="file-attach__btn" type="button">Отмена</button>
          </div>
        </template>
        <template v-else>
          <label class="file-attach__label">
            <input
              type="file"
              class="file-attach__input"
              @change="storeFiles"
              :accept="accept"
              ref="input"
            />
            <span class="file-attach__btn">Обзор</span>
          </label>
        </template>
      </div>
    </form-field>
    <div class="error-text error-text--right" v-if="errorText">
      {{ errorText }}
    </div>
  </div>
  <div class="files">
    <custom-file
      v-for="(file, index) in files"
      :key="file.lastModified + file.name"
      is-admin
      :name="getFileName(file)"
      :extension="getFileExtension(file)"
      :size="(file.size / 1048576).toFixed(2) + ' Мб'"
      @delete="deleteFile(index)"
    />
  </div>
</template>

<script>
import SvgClr from '@/components/SvgClr';
import SvgIcon from '@/components/SvgIcon';
import CustomFile from '@/components/FileItem';
import FormField from '@/components/FormField';

export default {
  name: 'FileAttach',
  components: { SvgClr, SvgIcon, CustomFile, FormField },
  methods: {
    storeFiles(event) {
      const value = this.multiple ? [...this.modelValue, ...event.target.files] : event.target.files[0];
      this.$emit('update:modelValue', value);
      this.$refs.input.value = '';
    },
    deleteFile(index) {
      if (this.multiple) {
        return this.$emit(
          'update:modelValue',
          this.modelValue.filter((_, i) => i !== index)
        );
      }

      return this.$emit('update:modelValue', null);
    },
    getFileName(file) {
      return file.name.replace(/\.[^/.]+$/, '');
    },
    getFileExtension(file) {
      return file.name.slice(((file.name.lastIndexOf('.') - 1) >>> 0) + 2);
    },
  },
  computed: {
    files() {
      if (!this.modelValue) {
        return [];
      }
      return Array.isArray(this.modelValue) ? this.modelValue : [this.modelValue];
    },
  },
  props: {
    type: {
      type: Number,
      default: 2,
    },
    size: {
      type: String,
      default: 's',
    },
    color: {
      type: String,
      default: 'primary',
    },
    modelValue: {
      type: [Array, File],
      default: () => [],
      validator: (prop) =>
        Array.isArray(prop) ? prop.every((item) => item instanceof File) : prop instanceof File,
    },
    multiple: Boolean,
    label: String,
    isDisabled: Boolean,
    hasError: Boolean,
    errorText: String,
    accept: String,
    inProgress: Boolean,
    fileName: String,
    isRequired: Boolean,
  },
};
</script>
