<template>
  <div class="block">
    <div class="block__main">
      <div class="row row--aife">
        <div class="col col--full">
          <input-text
            label="Полное наименование организации"
            :model-value="organization.name"
            is-disabled
          />
        </div>

        <div class="col col--full">
          <input-text
            label="Сокращенное наименование организации"
            :model-value="organization.shortName"
            is-disabled
          />
        </div>

        <div class="col col--full">
          <input-text
            label="Адрес местонахождения"
            :model-value="organization.address"
            is-disabled
          />
        </div>

        <div class="col col--full">
          <input-text
            label="Фактический адрес"
            :model-value="organization.actualAddress"
            is-disabled
          />
        </div>

        <div class="col col--third">
          <input-text
            label="Контактный телефон"
            :model-value="organization.contacts?.phoneNumber"
            is-disabled
          />
        </div>

        <div class="col col--third">
          <input-text
            label="Адрес электронной почты"
            :model-value="organization.contacts?.email"
            is-disabled
          />
        </div>

        <div class="col col--third">
          <input-text
            label='Адрес официального сайта в сети "Интернет"'
            :model-value="organization.contacts?.website_url"
            is-disabled
          />
        </div>

        <div class="col col--full">
          <input-text label="ФИО руководителя" :model-value="organization.head" is-disabled />
        </div>

        <div class="col col--third">
          <input-text label="ОГРН" :model-value="organization.ogrn" is-disabled />
        </div>

        <div class="col col--third">
          <input-text label="ИНН" :model-value="organization.inn" is-disabled />
        </div>

        <div class="col col--third">
          <input-text label="КПП" :model-value="organization.kpp" is-disabled />
        </div>

        <div class="col col--third">
          <switch-input
            type="checkbox"
            size="m"
            :model-value="organization.hasAccessToStateSecret"
            label="Доступ к гос. тайне"
            disabled
          />
        </div>

        <div v-if="organization.hasAccessToStateSecret" class="col col--two-thirds">
          <input-text
            label="Уровень доступа"
            :model-value="`${organization.accessLevel || 0}-й уровень`"
            is-disabled
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputText from '@/components/InputText';
import SwitchInput from '@/components/SwitchInput';

export default {
  name: 'OrganizationDetails',
  props: {
    organization: {
      type: Object,
      required: true,
    },
  },
  components: {
    SwitchInput,
    InputText,
  },
};
</script>
