import axios from '@/api/axios';

export const AcademicDegreesRepository = {
  /**
   * @returns {Promise<AcademicDegree[]>}
   */
  async all() {
    const response = await axios.get('academic-degrees');
    return response.data.data;
  },
};

/**
 * @typedef AcademicDegree
 * @property {string} id
 * @property {string} code
 * @property {string|null} name
 */
