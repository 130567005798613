<template>
  <GenericModal @close="$emit('close')" :opened="open" close-button>
    <template #header>
      <div class="modal-box__title">Добавление ученого звания</div>
    </template>
    <template #body>
      <div class="modal-box__block">
        <div class="block">
          <div class="block__main">
            <div class="row row--aife">
              <div class="col col--full">
                <CustomSelect
                  v-model="form.rankId"
                  label="Ученое звание"
                  placeholder="Укажите ученое звание"
                  :options="ranks.map((rank) => ({ value: rank.id, text: rank.name }))"
                  :has-error="vuelidate.form.rankId.$error"
                  :vuelidate-errors="vuelidate.form.rankId.$errors"
                />
              </div>
              <div class="col col--full">
                <InputText
                  v-model="form.fieldOfActivity"
                  label="Научная специальность"
                  placeholder="Введите название специальности, по которой присуждено звание"
                  :has-error="vuelidate.form.fieldOfActivity.$error"
                  :vuelidate-errors="vuelidate.form.fieldOfActivity.$errors"
                />
              </div>
              <div class="col col--full">
                <InputText
                  label="Год присуждения"
                  v-model.number="form.confermentYear"
                  input-type="number"
                  min="1900"
                  max="2100"
                  :has-error="vuelidate.form.confermentYear.$error"
                  :vuelidate-errors="vuelidate.form.confermentYear.$errors"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="line line--jcfe">
        <div class="line__item">
          <CustomButton label="Добавить" @click="add" />
        </div>
      </div>
    </template>
  </GenericModal>
</template>

<script>
import CustomButton from '@/components/CustomButton';
import CustomSelect from '@/components/CustomSelect';
import GenericModal from '@/components/modals/GenericModal';
import InputText from '@/components/InputText';
import { ExpertAcademicRanksRepository } from '@/repositories/expert/ExpertAcademicRanksRepository';
import useVuelidate from '@vuelidate/core';
import { required } from '@/utils/validators';
import { maxLength } from '@vuelidate/validators';

export default {
  name: 'AddRankModal',
  props: {
    open: Boolean,
    ranks: {
      type: Array,
      required: true,
    },
  },
  emits: ['adding', 'added', 'failed', 'close'],
  components: {
    CustomButton,
    CustomSelect,
    GenericModal,
    InputText,
  },
  setup() {
    return { vuelidate: useVuelidate() };
  },
  data() {
    return {
      form: {
        rankId: null,
        fieldOfActivity: null,
        confermentYear: null,
      },
    };
  },
  validations() {
    return {
      form: {
        rankId: { required },
        fieldOfActivity: { required, maxLength: maxLength(255) },
        confermentYear: { required },
      },
    };
  },
  methods: {
    async add() {
      this.vuelidate.form.$touch();
      if (this.vuelidate.form.$invalid) {
        return;
      }

      this.$emit('adding');

      try {
        await ExpertAcademicRanksRepository.addForAuthenticatedExpert({
          id: this.form.rankId,
          confermentYear: this.form.confermentYear,
          fieldOfActivity: this.form.fieldOfActivity,
        });
      } catch (error) {
        window.alert('Не удалось добавить ученое звание. Попробуйте, пожалуйста, позже!');
        this.$emit('failed');
        throw error;
      }

      this.$emit('added');
      this.resetForm();
    },
    resetForm() {
      this.form = {
        rankId: null,
        confermentYear: null,
      };
    },
  },
};
</script>
