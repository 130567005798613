<template>
  <div class="wrap wrap--side" :class="{ collapsed }">
  <navigation-sidebar :links="$options.links" />
  <router-view />
  </div>
</template>

<script>
import NavigationSidebar from '@/components/NavigationSidebar';
export default {
  name: 'ExpertisesLayout',
  components: { NavigationSidebar },
  links: [
    { label: 'Экспертизы', to: 'expert.expertises.index', icon: 'expertise' },
  ],
  computed: {
    collapsed() {
      return this.$store.state.sidebar.collapsed;
    },
  },
};
</script>
