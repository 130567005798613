<template>
  <main class="main">
    <div class="main__wrap main__wrap--wide">
      <div class="main__over-top">
        <custom-button
          type="4"
          label="Вернуться в основной раздел"
          icon="arrow-left"
          @click="redirectToOrganizationTasks"
        />
      </div>
      <div class="main__top">
        <div class="main__title">
          <div class="title">Образовательные программы</div>
        </div>
      </div>
      <div class="main__body">
        <div class="main__block">
          <div class="block">
            <div class="block__main">
              <table class="tbl tbl--sticky-head">
                <thead>
                  <tr>
                    <th style="width: 40px">№</th>
                    <th>Код и наименование УГСН</th>
                    <th>Уровень образования</th>
                    <th>Код и наименование профессии/специальности/направления подготовки</th>
                    <th>Наименование образовательной программы</th>
                    <th>Наличие набора в 2022 году</th>
                    <th>Наличие выпуска в 2021 году (ВО, СПО)</th>
                    <th>Наличие выпуска в 2022 году (СПО, общее образование)</th>
                    <th>ДВИ</th>
                    <th>Демо</th>
                    <th style="width: 188px">Приоритетная форма обучения</th>
                    <th>База набора</th>
                    <!-- <th>Адаптированная</th> -->
                    <th>Статус</th>
                    <!-- <th style="width: 64px">Баллы</th> -->
                    <th>Управление</th>
                  </tr>
                </thead>
                <tbody v-if="programs.length">
                  <tr
                    class="tbl-status"
                    v-for="program in programs"
                    :key="program.id"
                    :data-filled="program.complete || program.is_rejected ? true : null"
                  >
                    <td>{{ programs.indexOf(program) + 1 }}</td>
                    <td>
                      {{ program.ugs_code }} –
                      {{ program.ugs_name }}
                    </td>
                    <td>{{ program.edu_level }}</td>
                    <td>{{ program.licensed_program_code }} {{ program.licensed_program_name }}</td>
                    <td>
                      {{ program.edu_program_name }}
                    </td>
                    <td>
                      <custom-select
                        placeholder="Выберите"
                        :options="admissionFormats"
                        v-model="programsAdmissions[program.id]"
                        :isDisabled="true"
                      />
                    </td>
                    <td>
                      <custom-select
                        placeholder="Выберите"
                        :options="graduationFormats"
                        v-model="programsGraduationsIn2021[program.id]"
                        :isDisabled="true"
                      />
                    </td>
                    <td>
                      <custom-select
                        placeholder="Выберите"
                        :options="graduationFormats"
                        v-model="programsGraduationsIn2022[program.id]"
                        :isDisabled="true"
                      />
                    </td>
                    <td>
                      <switch-input
                        type="checkbox"
                        size="m"
                        v-model="activeProgramsWithDvi"
                        :value="String(program.id)"
                        :disabled="true"
                      />
                    </td>
                    <td>
                      <custom-select
                        placeholder="Выберите"
                        :options="demoFormats"
                        v-model="programDemos[program.id]"
                        :isDisabled="true"
                      />
                    </td>
                    <td>
                      <custom-select
                        placeholder="Выберите"
                        :options="eduFormats"
                        v-model="programsEduFormats[program.id]"
                        :isDisabled="true"
                      />
                    </td>
                    <td>
                      <custom-select
                        placeholder="Выберите"
                        :options="recruitBase"
                        :isDisabled="true"
                      />
                    </td>
                    <!--                     <td>
                      <switch-input
                        type="checkbox"
                        size="m"
                        v-model="activeAdaptedPrograms"
                        :value="String(program.id)"
                        :disabled="true"
                      />
                    </td> -->
                    <td>
                      {{
                        program.is_sent ? 'Завершен' : program.is_rejected ? 'Отказ' : 'Ввод данных'
                      }}
                    </td>
                    <!-- <td>{{ program.is_sent ? program.score : '' }}</td> -->
                    <td>
                      <button
                        v-if="!program.is_rejected"
                        href="#"
                        class="btn btn--4 btn--s btn--primary"
                        @click.prevent="redirectToProgramsMarks(program.id)"
                      >
                        <span class="btn__label">Просмотр </span>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div v-if="!programs.length && !isLoading" class="main__block">
          <div class="blank">
            <div class="blank__cover">
              <svg-clr name="accreditation-monitoring" :size="24" />
            </div>
            <div class="blank__text">Данные отсутствуют</div>
          </div>
        </div>
      </div>

      <div v-if="isLoading" class="main__over">
        <div class="loader loader--l">
          <svg-clr name="loader" :size="24" />
        </div>
      </div>
    </div>
  </main>
</template>

<script setup>
import { onBeforeMount, onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { Monitoring } from '@/repositories';
import CustomSelect from '@/components/CustomSelect.vue';
import SvgClr from '@/components/SvgClr';
import CustomButton from '@/components/CustomButton';
import SwitchInput from '@/components/SwitchInput.vue';

const router = useRouter();
const route = useRoute();

const isLoading = ref();
const taskStatusId = ref([]);
const taskId = route.params.id;

const admissionFormats = [
  { text: 'Нет', value: 'Нет' },
  { text: 'Да', value: 'Да' },
];

const graduationFormats = [
  { text: 'Нет', value: 'Нет' },
  { text: 'Да', value: 'Да' },
];

const demoFormats = [
  { text: 'Нет', value: 'Нет' },
  { text: 'Да', value: 'Да' },
];

const eduFormats = [
  { text: 'Очная', value: 'Очная' },
  { text: 'Очно-заочная', value: 'Очно-заочная' },
  { text: 'Заочная', value: 'Заочная' },
];

const recruitBase = [
  { text: '9 классов', value: '9 классов' },
  { text: '11 классов', value: '11 классов' },
];

const redirectToOrganizationTasks = () => {
  router.push({
    name: 'curator.monitoring.organization-tasks',
  });
};

const redirectToProgramsMarks = (id) => {
  router.push({
    name: 'curator.monitoring.program-marks',
    params: { id },
  });
};

const programs = ref([]);
const programsWithDVI = ref([]);
const activeProgramsWithDvi = ref([]);
const programsEduFormats = ref({});
const programsAdmissions = ref({});
const programsGraduationsIn2021 = ref({});
const programsGraduationsIn2022 = ref({});
const programDemos = ref({});
// const adaptedPrograms = ref([]);
// const activeAdaptedPrograms = ref([]);

async function getPrograms() {
  try {
    const response = await Monitoring.getPrograms(taskId);
    programs.value = response.programs.sort((a, b) => a.id - b.id);
    programsWithDVI.value = response.programs.map((program) => (program.dvi ? program.id : ''));
    activeProgramsWithDvi.value = programsWithDVI.value;

    /* adaptedPrograms.value = response.programs.map((program) =>
      program.is_adapted ? program.id : ''
    );
    activeAdaptedPrograms.value = adaptedPrograms.value; */

    response.programs.forEach((program) => {
      programsEduFormats.value[program.id] = program.education_form;
      programsAdmissions.value[program.id] = program.admission;
      programsGraduationsIn2021.value[program.id] = program.graduation_in_2021;
      programsGraduationsIn2022.value[program.id] = program.graduation_in_2022;
      programDemos.value[program.id] = program.demo;
    });
    await getTask();
  } catch (error) {
    window.alert('Не удалось загрузить список выбранных программ');
  } finally {
    isLoading.value = false;
  }
}

async function getTask() {
  isLoading.value = true;
  try {
    const response = await Monitoring.getTask(taskId);
    taskStatusId.value = response.status_id;
  } catch (error) {
    window.alert('Не удалось загрузить данные мониторинга');
  } finally {
    isLoading.value = false;
  }
}

onBeforeMount(() => {
  isLoading.value = true;
});

onMounted(() => {
  getPrograms();
});
</script>
