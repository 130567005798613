<template>
  <div :class="`notice notice--${type} notice--${size}`">
    <div class="notice__icon" v-if="icon">
      <svg-icon :name="icon" :size="iconSize" />
    </div>
    <div class="notice__text">
      <slot />
    </div>
  </div>
</template>

<script>
import SvgIcon from '@/components/SvgIcon';
export default {
  /* Название компонента должно быть из двух слов, но я не придумал второго слова 😞 */
  name: "Notice",
  props: {
    type: {
      type: String,
      default: 'info'
    },
    size: {
      type: String,
      default: 's',
    },
    icon: String,
  },
  components: { SvgIcon },
  computed: {
    iconSize() {
      const sizeToIconSize = {
        'm': 32,
        'xs': 16
      };
      const defaultIconSize = 24;

      return this.size in sizeToIconSize ? sizeToIconSize[this.size] : defaultIconSize;
    }
  }
}
</script>
