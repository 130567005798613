/**
 * Убирает все дубликаты из массива.
 * @param {array} array
 * @param {string} [key]
 * @returns {array}
 */
export function unique(array, key) {
  if (!key) {
    return [...new Set(array)];
  }

  const seenValues = new Set();
  return array.filter(function(item) {
    if (seenValues.has(item[key])) {
      return false;
    }
    seenValues.add(item[key]);
    return true;
  });
}