<template>
  <main class="main">
    <div class="main__wrap">
      <div class="main__body">
        <div class="main__block">
          <div class="block">
            <div class="block__top">
              <div class="block-title">Ваши аккредитации</div>
            </div>

            <div class="block__main">
              <div class="tbl-wrap">
                <table class="tbl">
                  <thead>
                    <tr>
                      <th v-for="column in $options.columns" :key="column">
                        {{ column }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-if="rows && !rows.length">
                      <td :colspan="$options.columns.length" class="align-center">Аккредитации не найдены</td>
                    </tr>
                    <template v-else>
                      <tr v-for="(row, index) in rows" :key="index">
                        <td v-for="(cell, index) in row" :key="index">
                          {{ cell }}
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { Expert } from '@/repositories';
export default {
  name: 'Accreditations',
  columns: ['Номер распоряжения', 'Тип документа', 'Дата документа', 'Срок полномочий'],
  created() {
    Expert.getCertificates().then((certificates) => (this.certificates = certificates));
  },
  data() {
    return {
      certificates: null,
    };
  },
  computed: {
    rows() {
      return this.certificates?.map((certificate) => [
        certificate.orderNumber,
        certificate.orderType,
        certificate.orderIssuedAt && this.$filters.formatDate(certificate.orderIssuedAt),
        certificate.expiresAt && this.$filters.formatDate(certificate.expiresAt),
      ]);
    },
  },
};
</script>
