<template>
  <a :href="path" @click="handleClick" :class="this.class">
    <slot />
  </a>
  <unsign-file-modal
    v-if="signed && modalIsOpen"
    :opened="modalIsOpen"
    @close="modalIsOpen = false"
    :signed-path="path"
    :unsigned-path="unsignedPath"
  />
</template>

<script>
import UnsignFileModal from '@/components/modals/UnsignFileModal';
export default {
  name: 'FileLink',
  components: { UnsignFileModal },
  data() {
    return {
      modalIsOpen: false,
    };
  },
  computed: {
    signed() {
      return this.path.endsWith('.sig');
    },
    unsignedPath() {
      return (
        `/api/spa/unsigned-file?publicFilePath=${this.path}` +
        (this.downloadAs ? `&downloadAs=${this.downloadAs}` : '')
      );
    },
  },
  methods: {
    handleClick(event) {
      if (!this.signed) {
        return;
      }

      event.preventDefault();
      this.modalIsOpen = true;
    },
  },
  props: {
    path: {
      type: String,
      required: true,
    },
    downloadAs: String,
    class: String,
  },
};
</script>
