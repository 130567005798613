<template>
  <div class="block">
    <div class="block__top">
      <div class="block-title">Адреса мест осуществления образовательной деятельности</div>
    </div>

    <div class="block__main">
      <div class="row row--aife">
        <div v-for="(location, index) in locations" :key="index" class="col col--full">
          <input-text
            :label="`Адрес ${index + 1}`"
            :model-value="`${location.postcode}, ${location.address}`"
            is-disabled
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputText from '@/components/InputText';
export default {
  name: 'LocationsBlock',
  components: { InputText },
  props: {
    locations: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
