import axios from '@/api/axios';

export const RegionsRepository = {
  /**
   * @returns {Promise<Region[]>}
   */
  async all() {
    const response = await axios.get('regions');
    return response.data.data;
  },
  /**
   * @returns {Promise<Region[]>}
   */
  async getByFederalDistrictId(id) {
    const response = await axios.get(`regions?federal_district_id=${id}`);
    return response.data.data;
  },
};

/**
 * @typedef Region
 * @property {string} Id
 * @property {string|null} Name
 */
