<template>
  <label class="switch" :class="classNames">
    <input
      :type="inputType"
      class="switch__input"
      :disabled="disabled"
      v-model="checked"
      :value="value"
    />
    <span class="switch__cover" :class="`${type}__cover`">
      <svg-icon v-if="type === 'checkbox'" name="check" :size="iconSize" />
    </span>
    <div v-if="label" class="switch__label" :class="`${type}__label`">
      {{ label }}
    </div>
  </label>
</template>

<script>
import SvgIcon from '@/components/SvgIcon';
export default {
  name: 'SwitchInput',
  components: { SvgIcon },
  computed: {
    checked: {
      get() {
        return this.modelValue;
      },
      set(isChecked) {
        this.$emit('update:modelValue', isChecked);
      },
    },
    iconSize() {
      const sizeToIcon = { l: 24, m: 16 };
      return sizeToIcon[this.size] || 8;
    },
    inputType() {
      return this.type === 'radio' ? 'radio' : 'checkbox';
    },
    classNames() {
      return {
        'switch--disabled': this.disabled,
        [`${this.type}--${this.size}`]: this.type && this.size,
        [this.type]: this.type,
        [`switch--${this.size}`]: this.size,
      };
    },
  },
  props: {
    label: String,
    type: String,
    size: String,
    disabled: Boolean,
    modelValue: [Boolean, Array],
    value: String,
  },
};
</script>
