<template>
  <GenericModal @close="$emit('close')" :opened="open" close-button>
    <template #header>
      <div class="modal-box__title">Добавление места работы</div>
    </template>
    <template #body>
      <div class="modal-box__block">
        <div class="block">
          <div class="block__main">
            <div class="row row--aife">
              <div class="col col--full">
                <InputText
                  v-model="form.organizationName"
                  label="Наименование организации"
                  placeholder="Введите наименование организации"
                  :has-error="vuelidate.form.organizationName.$error"
                  :vuelidate-errors="vuelidate.form.organizationName.$errors"
                />
              </div>
              <div class="col col--half">
                <InputText
                  v-model="form.startedAt"
                  label="Период работы с"
                  input-type="date"
                  :has-error="vuelidate.form.startedAt.$error"
                  :vuelidate-errors="vuelidate.form.startedAt.$errors"
                />
              </div>
              <div class="col col--half">
                <InputText v-model="form.endedAt" label="по" input-type="date" />
              </div>
              <div class="col col--half">
                <SwitchInput
                  type="checkbox"
                  size="m"
                  label="Основное"
                  v-model="form.isCurrent"
                  :disabled="form.isPrevious"
                />
              </div>
              <div class="col col--half">
                <SwitchInput
                  type="checkbox"
                  size="m"
                  label="Предыдущее"
                  v-model="form.isPrevious"
                  :disabled="form.isCurrent"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="line line--jcfe">
        <div class="line__item">
          <CustomButton label="Добавить" @click="add" />
        </div>
      </div>
    </template>
  </GenericModal>
</template>

<script>
import GenericModal from '@/components/modals/GenericModal';
import InputText from '@/components/InputText';
import CustomButton from '@/components/CustomButton';
import useVuelidate from '@vuelidate/core';
import { ExpertJobsRepository } from '@/repositories/expert/ExpertJobsRepository';
import { required } from '@/utils/validators';
import SwitchInput from '@/components/SwitchInput';
export default {
  name: 'AddJobModal',
  props: {
    open: Boolean,
  },
  emits: ['adding', 'added', 'failed', 'close'],
  components: {
    CustomButton,
    InputText,
    GenericModal,
    SwitchInput,
  },
  setup() {
    return { vuelidate: useVuelidate() };
  },
  data() {
    return {
      form: {
        organizationName: null,
        startedAt: null,
        endedAt: null,
        isCurrent: false,
        isPrevious: false,
      },
    };
  },
  validations() {
    return {
      form: {
        organizationName: { required },
        startedAt: { required },
      },
    };
  },
  methods: {
    async add() {
      this.vuelidate.form.$touch();
      if (this.vuelidate.form.$invalid) {
        return;
      }

      this.$emit('adding');

      try {
        await ExpertJobsRepository.addForAuthenticatedExpert(this.form);
      } catch (error) {
        window.alert('Не удалось добавить место работы. Попробуйте, пожалуйста, позже!');
        this.$emit('failed');
        throw error;
      }

      this.$emit('added');
      this.resetForm();
      this.vuelidate.form.$reset();
    },
    resetForm() {
      this.form = {
        organizationName: null,
        startedAt: null,
        endedAt: null,
        isCurrent: false,
        isPrevious: false,
      };
    },
  },
};
</script>
