<template>
  <div class="start-page">
    <div class="start-page__wrap">
      <div class="start-page__top">
        <div class="start-page__notice">
          <notice icon="warning" >
            Документы на&nbsp;государственную аккредитацию необходимо направлять через Единый портал
            государственных и&nbsp;муниципальных услуг (функций) по&nbsp;соответствующей ссылке
            <a href="https://www.gosuslugi.ru/610173/1/form">https://www.gosuslugi.ru/610173/1/form</a>
          </notice>
        </div>
        <div class="start-page__logo">
          <img src="~@/assets/images/logo-visual.svg" width="48" height="42" alt="Логотип ИС ГА" class="logo__img" />
        </div>
        <div class="start-page__title">
          Информационная система<br />государственной аккредитации
        </div>

        <div class="start-page__tabs tabs">
          <ul class="tabs__list tabs__list--center">
            <li class="tabs__item">
              <router-link
                :to="{ name: 'login' }"
                exact-active-class="tabs__link--active"
                class="tabs__link"
              >
                Авторизация
              </router-link>
            </li>
<!--            <li class="tabs__item">-->
<!--              <router-link-->
<!--                :to="{ name: 'registration' }"-->
<!--                active-class="tabs__link&#45;&#45;active"-->
<!--                class="tabs__link"-->
<!--              >-->
<!--                Регистрация-->
<!--              </router-link>-->
<!--            </li>-->
          </ul>
        </div>
      </div>
      <router-view />
    </div>
  </div>
</template>

<script>
import Notice from '@/components/Notice.vue';

export default {
  name: 'AuthenticationLayout',
  components: { Notice },
};
</script>
