<template>
  <div class="file-item">
    <file-link
      :path="link"
      :download-as="this.$filters.removeExtension(name, 'sig')"
      class="file-item__main"
    >
      <div class="file-item__cover">
        <svg-clr v-if="extension" :name="'file-' + extension" :size="32" />
      </div>
      <div class="file-item__info">
        <div class="file-item__name">
          {{ name }}
          <span v-if="signed" class="tag tag--success">
            <svg-icon name="check" :size="8" /> ЭЦП
          </span>
        </div>
        <div class="file-item__description">{{ size }} {{ description }}</div>
      </div>
    </file-link>
    <div class="file-item__extra">
      <button
        @click="$emit('delete')"
        v-if="deletable"
        type="button"
        class="file-item__delete link link--gray"
      >
        <svg-icon name="delete" :size="16" />
      </button>
    </div>
  </div>
</template>

<script>
import SvgClr from '@/components/SvgClr';
import SvgIcon from '@/components/SvgIcon';
import FileLink from '@/components/FileLink';
import { getFileExtension } from '@/utils/helpers';
export default {
  name: 'DownloadableFile',
  components: { SvgClr, SvgIcon, FileLink },
  computed: {
    extension() {
      if (!this.name) {
        return null;
      }

      const fileName = this.name.endsWith('.sig') ? this.name.slice(0, -4) : this.name;
      return getFileExtension(fileName) || 'sig';
    },
    signed() {
      return this.name && this.name.endsWith('.sig');
    },
  },
  props: {
    name: String,
    size: String,
    description: String,
    link: String,
    deletable: Boolean,
  },
  emits: ['delete'],
};
</script>