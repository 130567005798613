import axios from '@/api/axios';

export const ExpertEducationsRepository = {
  /**
   * @returns {Promise<ExpertEducation[]>}
   */
  async getForAuthenticatedExpert() {
    const response = await axios.get('expert/educations');
    return response.data.data;
  },
  /**
   * @param {object} education
   * @param {string} education.text
   * @returns {Promise<void>}
   */
  async addForAuthenticatedExpert(education) {
    await axios.post('expert/educations', education);
  },
  async remove(id) {
    await axios.delete(`expert/educations/${id}`);
  },
};

/**
 * @typedef ExpertEducation
 * @property {string} id
 * @property {string} text
 * @property {string} expert_id
 */
