import axios from '@/api/axios';

export const FederalDistrictsRepository = {
  /**
   * @returns {Promise<FederalDistrict[]>}
   */
  async all() {
    const response = await axios.get('federal-districts');
    return response.data.data;
  },
};

/**
 * @typedef FederalDistrict
 * @property {string} Id
 * @property {string|null} name
 * @property {string|null} shortName
 */
