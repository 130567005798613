<template>
  <button
    class="btn"
    :class="classNames"
    :type="buttonType"
    :disabled="disabled"
    :title="title"
    :data-modal="modal ? `modal-${modal}` : null"
  >
    <span v-if="icon" class="btn__icon">
      <svg-icon :name="icon" :size="label ? 16 : 24"></svg-icon>
    </span>
    <span v-if="isLoading" class="btn__spinner">
      <svg
        width="24"
        height="24"
        stroke="#fff"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g class="spinner-ring-resize">
          <circle cx="12" cy="12" r="9.5" fill="none" stroke-width="3"></circle>
        </g>
      </svg>
    </span>
    <span v-if="label" class="btn__label">{{ label }}</span>
  </button>
</template>

<script>
import SvgIcon from '@/components/SvgIcon';
export default {
  name: 'CustomButton',
  components: { SvgIcon },
  computed: {
    classNames() {
      return {
        'btn--icon': !this.label,
        ['btn--' + this.type]: true,
        ['btn--' + this.size]: true,
        ['btn--' + this.color]: true,
        'btn--expandable': this.isExpandable,
      };
    },
  },
  props: {
    type: {
      type: Number,
      default: 1,
    },
    size: {
      type: String,
      default: 's',
    },
    color: {
      type: String,
      default: 'primary',
    },
    buttonType: {
      type: String,
      default: 'button',
    },
    disabled: Boolean,
    title: String,
    label: String,
    icon: String,
    isExpandable: Boolean,
    isLoading: Boolean,
    modal: String,
  },
};
</script>
