import axios from '@/api/axios';

export const ExpertApplicationService = {
  async submit(id) {
    await axios.patch(`expert/applications/${id}/submit`);
  },
  async getTemplate(id) {
    const response = await axios.get(`expert/applications/${id}/template`, {
      responseType: 'blob',
    });
    /* TODO Изменить логику формирования имени файла на что-то опирающееся на данные от сервера */
    return new File([response.data], `Заявление №${id}.docx`);
  },
};
