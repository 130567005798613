import axios from '@/api/axios';

export const ExpertiseDocumentsRequest = {
  async get(expertiseId) {
    const response = await axios.get(
      `expert/expertises/${expertiseId}/documents-request`
    );
    return response.data.data;
  },
  async programs(expertiseId, branchId, typeId) {
    const response = await axios.get(
      `expert/expertises/${expertiseId}/documents-request/programs?branch=${branchId}&type=${typeId}`
    );
    return response.data.data;
  }
}