import axios from '@/api/axios';

export const RequestDocuments = {
  /**
   * @param {string|number} requestId
   * @param {string|number} programId
   * @param {string|number} typeId
   * @return {Promise<*>}
   */
  async all(requestId, { programId, typeId }) {
    const response = await axios.get(
      `user/requests/${requestId}/documents?program=${programId}&type=${typeId}`
    );
    return response.data.data;
  },
  async remove(requestId, documentId) {
    await axios.delete(
      `user/requests/${requestId}/documents/${documentId}`
    );
  },
  /**
   * @param {string} requestId
   * @param {object} document
   * @param {string} document.type_id
   * @param {string} document.program_id
   * @param {string} document.path
   * @returns {Promise<*>}
   */
  async add(requestId, document) {
    const response = await axios.post(
        `user/requests/${requestId}/documents/links`,
        document
    );
    return response.data.data;
  },
  /**
   * @param {string} documentId
   * @param {string} path
   * @returns {Promise<void>}
   */
  async update(documentId, path) {
    await axios.patch(
        `user/request-documents/${documentId}`,
        {path}
    )
  },
}