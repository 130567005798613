import axios from '@/api/axios';

export const eo = {
  namespaced: true,
  state() {
    return {
      name: null
    };
  },
  mutations: {
    storeName(state, name) {
      state.name = name;
    },
  },
  actions: {
    async getName({ commit }) {
      const response = await axios.get('user/organization/name')
      commit('storeName', response.data.name);
      return response.data.name;
    }
  }
};