<template>
  <div class="wrap wrap--side" :class="{ collapsed }">
    <navigation-sidebar :links="links" />
    <router-view />
  </div>
</template>

<script>
import { educationalOrganization } from '@/repositories';
import NavigationSidebar from '@/components/NavigationSidebar';
export default {
  name: 'MainPageLayout',
  components: { NavigationSidebar },
  created() {
    educationalOrganization.getBranchesCount().then((count) => (this.branchesCount = count));
  },
  data() {
    return {
      branchesCount: null,
    };
  },
  computed: {
    links() {
      return [
        { label: 'Общие сведения', to: 'home', icon: 'general-info' },
        {
          label: 'Филиалы',
          to: 'branches',
          icon: 'branches',
          qty: this.branchesCount,
        },
        { label: 'Лицензии', to: 'licenses', icon: 'licenses' },
        {
          label: 'Свидетельство об аккредитации',
          to: 'accreditation-certificate',
          icon: 'accreditation-cert',
        },
      ];
    },
    collapsed() {
      return this.$store.state.sidebar.collapsed;
    },
  },
};
</script>
