import axios from '@/api/axios';

export const ExpertApplicationProgramsRepository = {
  /**
   * @param {string} id
   * @returns {Promise<ApplicationProgram[]>}
   */
  async getByApplicationId(id) {
    const response = await axios.get(`expert/applications/${id}/programs`);
    return response.data.data;
  },
  /**
   *
   * @param {object} program
   * @param {number} program.expert_application_id
   * @param {string} program.egs_id
   * @param {string} program.education_level_id
   * @returns {Promise<void>}
   */
  async add(program) {
    await axios.post(`expert/applications/${program.expert_application_id}/programs`, {
      egs_id: program.egs_id,
      education_level_id: program.education_level_id,
    });
  },
  async remove(program) {
    await axios.delete(`expert/application-programs/${program.id}`);
  },
};

/**
 * @typedef ApplicationProgram
 * @property {number} id
 * @property {object} egs
 * @property {string} egs.id
 * @property {string|null} egs.name
 * @property {string|null} egs.code
 * @property {object} education_level
 * @property {string} education_level.id
 * @property {string|null} education_level.name
 * @property {string|null} education_level.short_name
 * @property {string|null} education_level.code
 */
