import axios from '@/api/axios';

export const ExpertApplicationsRepository = {
  async find(id) {
    const response = await axios.get(`expert/applications/${id}/`);
    return response.data.data;
  },
  async remove(application) {
    await axios.delete(`expert/applications/${application.id}`);
  },
};
