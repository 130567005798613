<template>
  <template v-if="expert && expert.is_candidate">
    <CandidateOrganizationDetails v-if="expert.is_organization" :organization="expert" />
    <CandidateExpertDetails v-else :expert="expert" />
  </template>
  <template v-if="expert && !expert.is_candidate">
    <OrganizationDetails v-if="expert.is_organization" :organization="expert" />
    <ExpertDetails v-else :expert="expert" />
  </template>
</template>

<script>
import ExpertDetails from '@/components/blocks/expert/ExpertDetails';
import OrganizationDetails from '@/components/blocks/expert/OrganizationDetails';
import CandidateExpertDetails from '@/components/blocks/expert/CandidateExpertDetails';
import CandidateOrganizationDetails from '@/components/blocks/expert/CandidateOrganizationDetails';
import { Expert } from '@/repositories';
export default {
  name: 'GeneralInfo',
  components: {
    ExpertDetails,
    OrganizationDetails,
    CandidateExpertDetails,
    CandidateOrganizationDetails,
  },
  async created() {
    this.expert = await Expert.getInfo();
  },
  data() {
    return {
      expert: null,
    };
  },
};
</script>
