<template>
  <div class="wrap wrap--side" :class="{ collapsed }">
    <navigation-sidebar :links="links" />
    <router-view />
  </div>
</template>

<script>
import NavigationSidebar from '@/components/NavigationSidebar';
import { Expert } from '@/repositories';
export default {
  name: 'MainPageLayout',
  components: { NavigationSidebar },
  async created() {
    this.links = await this.getLinks();
  },
  data() {
    return {
      links: [],
    };
  },
  computed: {
    collapsed() {
      return this.$store.state.sidebar.collapsed;
    },
  },
  methods: {
    async getLinks() {
      const links = [
        { label: 'Общие сведения', to: 'expert.home', icon: 'general-info' },
        {
          label: 'Аккредитация',
          to: 'expert.accreditation',
          icon: 'accreditation-cert',
        },
      ];

      if ((await Expert.getInfo())?.is_candidate) {
        links.pop();
        links.push({ label: 'Заявления', to: 'expert.applications.index', icon: 'new-statement' });
      }

      return links;
    },
  },
};
</script>
