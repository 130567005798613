<template>
  <div class="form-group" :class="{ 'form-group--line': isLine, 'form-group--grid': grid }">
    <label
      v-if="label"
      :for="id"
      class="form-label"
      :class="{ 'form-label--required': required }"
      >{{ label }}</label
    >
    <form-field
      class="form-field--text"
      :class="{
        ['form-field--' + type]: true,
        ['form-field--' + size]: true,
        ['form-field--' + color]: true,
        'has-error': hasError,
        disabled: isDisabled,
      }"
    >
      <span v-if="icon" class="form-field__icon form-icon">
        <svg-icon :name="icon" :size="iconSize" />
      </span>
      <span v-if="prefix" class="form-field__prefix">{{ prefix }}</span>
      <input
        @input="$emit('update:modelValue', $event.target.value)"
        ref="input"
        :id="id"
        :type="inputFieldType"
        :placeholder="placeholder"
        :value="value"
        :disabled="isDisabled"
        :inputmode="inputType === 'tel' ? 'numeric' : null"
        :data-mask="inputType === 'tel' ? '(ddd) ddd dd dd' : isDate ? 'dd.dd.dddd' : null"
        :maxlength="inputType === 'tel' ? 15 : maxLength"
        :minlength="minLength ? minLength : null"
        :max="max"
        :min="min"
        :class="{ 'form-date': isDate }"
        class="form-control"
      />
      <button
        v-if="button"
        @click="handleButtonClick"
        type="button"
        :disabled="isDisabled"
        class="form-field__btn form-btn"
        :class="{
          'check-btn': inputType === 'password',
          'focus-btn': isDate,
          'is-active': datepicker && datepicker.active,
          checked: revealPassword,
        }"
      >
        <svg-icon :name="button" :size="iconSize" />
      </button>
    </form-field>
    <div v-if="errorText" class="error-text error-text--right">
      {{ errorText }}
    </div>
    <div v-if="errors.length > 0" class="error-text error-text--right">
      {{ errors.join('. ') }}
    </div>
    <remove-field-button v-if="removable" @click="$emit('remove')" />
  </div>
</template>

<script>
import { Datepicker } from 'vanillajs-datepicker';
import SvgIcon from '@/components/SvgIcon';
import FormField from '@/components/FormField';
import RemoveFieldButton from '@/components/RemoveFieldButton';

export default {
  name: 'InputText',
  components: { SvgIcon, FormField, RemoveFieldButton },
  mounted() {
    if (this.isDate) {
      this.datepicker = new Datepicker(this.$refs.input, {
        format: 'dd.mm.yyyy',
        language: 'ru',
      });

      this.$refs.input.addEventListener('changeDate', this.updateDate);
    }
  },
  beforeUnmount() {
    this.$refs.input.removeEventListener('changeDate', this.updateDate);
  },
  data() {
    return {
      iconSize: this.size === 'l' ? 24 : 16,
      revealPassword: false,
      datepicker: null,
    };
  },
  computed: {
    inputFieldType() {
      if (this.inputType === 'password') {
        return this.revealPassword ? 'text' : 'password';
      }
      return this.inputType;
    },
    errors() {
      return this.vuelidateErrors.map((e) => e.$message);
    },
    value() {
      if (this.isDate && this.modelValue != null) {
        const date = this.modelValue instanceof Date ? +this.modelValue : this.modelValue;
        return this.$filters.formatDate(date);
      }

      return this.modelValue;
    },
  },
  methods: {
    handleButtonClick() {
      this.revealPassword = !this.revealPassword;

      if (this.datepicker) {
        this.datepicker.show();
      }

      this.$emit('click:button');
    },
    updateDate(event) {
      this.$emit('update:modelValue', event.detail.date);
    },
  },
  props: {
    size: {
      type: String,
      default: 's',
    },
    type: {
      type: Number,
      default: 2,
    },
    color: {
      type: String,
      default: 'primary',
    },
    inputType: {
      type: String,
      default: 'text',
    },
    vuelidateErrors: {
      type: Array,
      default: () => [],
    },
    hasError: Boolean,
    errorText: String,
    icon: String,
    prefix: String,
    isDate: Boolean,
    placeholder: String,
    button: String,
    id: String,
    label: String,
    isDisabled: Boolean,
    modelValue: [String, Number, Date],
    isLine: Boolean,
    grid: Boolean,
    removable: Boolean,
    maxLength: Number,
    minLength: Number,
    max: [String, Number],
    min: [String, Number],
    required: Boolean,
  },
  emits: ['update:modelValue', 'remove', 'click:button'],
};
</script>
