<template>
  <main class="main">
    <div class="main__wrap">
      <div class="main__header">
        <div class="title">
          {{ organizationName }}
        </div>
      </div>

      <div class="main__body">
        <div class="main__block">
          <div class="block">
            <div class="block__main">
              <div class="row row--aife">
                <div class="col col--full">
                  <custom-select
                    label="Свидетельство"
                    placeholder="Выберите"
                    :options="certificatesOptions"
                    v-model="selectedCertIndex"
                  />
                </div>
                <div class="col col--third">
                  <input-text
                    label="Регистрационный номер"
                    :model-value="selectedCert.registrationNumber"
                    is-disabled
                  />
                </div>
                <div class="col col--third">
                  <input-text
                    label="Вид свидетельства"
                    :model-value="selectedCert.type"
                    is-disabled
                  />
                </div>
                <div class="col col--third">
                  <input-text
                    label="Дата выдачи свидетельства"
                    :model-value="selectedCert.issuedAt"
                    button="calendar"
                    is-date
                    is-disabled
                  />
                </div>
                <div class="col col--third">
                  <input-text
                    label="Срок действия свидетельства"
                    :model-value="selectedCert.expiresAt"
                    button="calendar"
                    is-date
                    is-disabled
                  />
                </div>
                <div class="col col--third">
                  <input-text
                    label="Текущий статус свидетельства"
                    :model-value="selectedCert.status?.name"
                    is-disabled
                  />
                </div>
                <div class="col col--third">
                  <input-text
                    label="Серия, номер бланка"
                    :model-value="`${selectedCert.serialNumber || ''} ${
                      selectedCert.formNumber || ''
                    }`"
                    is-disabled
                  />
                </div>
                <div class="col col--full">
                  <input-text
                    label="Наименование органа, выдавшего свидетельство"
                    :model-value="selectedCert.controlOrgan && selectedCert.controlOrgan.name"
                    is-disabled
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="main__block">
          <div class="block">
            <div class="block__top">
              <div class="block-title">Приложение к свидетельству</div>
            </div>

            <div class="block__main">
              <div class="row row--aife">
                <div class="col col--full">
                  <custom-select
                    label="Приложение"
                    placeholder="Выберите"
                    :options="supplementsOptions"
                    v-model="selectedSuppIndex"
                  />
                </div>
                <div class="col col--third">
                  <input-text label="Статус" :model-value="selectedSupp.status" is-disabled />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="main__block">
          <div class="block">
            <div class="block__main">
              <div class="tbl-wrap">
                <custom-table :columns="$options.tableColumns" :row-groups="rows && [rows]" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="main__footer main__footer--sticky">
        <div class="line line--jcfe">
          <div class="line__item">
            <report-error-button />
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { educationalOrganization } from '@/repositories';
import InputText from '@/components/InputText';
import CustomSelect from '@/components/CustomSelect';
import ReportErrorButton from '@/components/ReportErrorButton';
import CustomTable from '@/components/CustomTable';
export default {
  name: 'AccreditationCertificate',
  components: { InputText, CustomSelect, ReportErrorButton, CustomTable },
  created() {
    educationalOrganization
      .getAccreditationCertificates()
      .then((certificates) => (this.certificates = certificates));

    if (this.$store.state.eo.name != null) {
      this.organizationName = this.$store.state.eo.name;
    } else {
      this.$store.dispatch('eo/getName').then((name) => (this.organizationName = name));
    }
  },
  data() {
    return {
      certificates: [],
      selectedCertificateIndex: 0,
      selectedSuppIndex: 0,
      organizationName: '',
    };
  },
  computed: {
    selectedCertIndex: {
      get() {
        return this.selectedCertificateIndex;
      },
      set(index) {
        this.selectedSuppIndex = 0;
        this.selectedCertificateIndex = index;
      },
    },
    selectedCert() {
      return this.certificates[this.selectedCertIndex] || {};
    },
    certificatesOptions() {
      return this.certificates.map((cert, index) => {
        const date = this.$filters.formatDate(cert.issuedAt);
        const regNumber = cert.registrationNumber || '';
        const status = cert.status?.name || '';
        return {
          value: index,
          text: `№ ${regNumber} от ${date} - ${status}`,
        };
      });
    },
    selectedSupp() {
      return (
        (this.selectedCert.supplements && this.selectedCert.supplements[this.selectedSuppIndex]) ||
        {}
      );
    },
    supplementsOptions() {
      return (
        this.selectedCert.supplements &&
        this.selectedCert.supplements.map((supplement, index) => {
          const organizationName = supplement.organizationName || '';
          return {
            value: index,
            text: `#${index + 1} ${organizationName}`,
          };
        })
      );
    },
    rows() {
      return (
        this.selectedSupp.accreditedPrograms && {
          rows: this.selectedSupp.accreditedPrograms.map((program) => {
            const specialtyName =
              program.enlargedGroupSpeciality && program.enlargedGroupSpeciality.name;
            const specialtyCode =
              program.enlargedGroupSpeciality && program.enlargedGroupSpeciality.code;
            return [
              specialtyCode,
              specialtyName,
              program.code,
              program.name,
              program.educationLevel,
            ];
          }), /* Сортировка по коду УГСН */
        }
      );
    },
  },
  tableColumns: ['Код УГСН', 'Наименование УГСН', 'Код ОП', 'Наименование ОП', 'Уровень образования'],
};
</script>