<template>
  <div class="form-group">
    <label :for="id" v-if="label" class="form-label">{{ label }}</label>
    <form-field class="form-field--textarea" :class="classNames">
      <template v-if="attachFile">
        <label class="form-field__attach file-attach">
          <input type="file" multiple :accept="accept" class="file-attach__input" />
        </label>
      </template>

      <textarea
        ref="textarea"
        :id="id"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
        type="text"
        class="form-control"
        :rows="rows"
        :placeholder="placeholder"
        :disabled="isDisabled"
      />
      <button v-if="type === 2" class="form-field__btn form-btn" type="submit">
        <svg-icon name="send" :size="24" />
      </button>
    </form-field>
    <div v-if="errors.length > 0" class="error-text error-text--right">
      {{ errors.join('. ') }}
    </div>
  </div>
</template>

<script>
import autosize from 'autosize';
import FormField from '@/components/FormField';
import SvgIcon from '@/components/SvgIcon';
export default {
  name: 'TextArea',
  components: { FormField, SvgIcon },
  computed: {
    classNames() {
      return {
        ['form-field--' + (this.type || 2)]: true,
        ['form-field--' + this.size]: true,
        ['form-field--' + this.color]: true,
        'has-error': this.hasError,
        disabled: this.isDisabled,
      };
    },
    errors() {
      return this.vuelidateErrors.map((e) => e.$message);
    },
  },
  mounted() {
    autosize(this.$refs.textarea);
  },
  watch: {
    modelValue() {
      this.$nextTick(() => autosize.update(this.$refs.textarea));
    },
  },
  props: {
    size: {
      type: String,
      default: 's',
    },
    color: {
      type: String,
      default: 'primary',
    },
    accept: {
      type: String,
      default:
        '.pdf,.xls,.xlsx,.doc,.docx,.odt,.rtf,.rar,.zip,.tiff,.png,.jpg,.jpeg,.bmp,.gif,.ico,.psd',
    },
    rows: {
      type: Number,
      default: 1,
    },
    vuelidateErrors: {
      type: Array,
      default: () => [],
    },
    type: Number,
    label: String,
    attachFile: Boolean,
    placeholder: String,
    isDisabled: Boolean,
    hasError: Boolean,
    modelValue: [String, Number],
    id: [Number, String],
  },
};
</script>
