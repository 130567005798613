<template>
  <div class="main-wrap">
    <nav-header :links="links" />
    <router-view />
  </div>
</template>

<script>
import NavHeader from '@/components/NavHeader';
import { Expert } from '@/repositories';

export default {
  name: 'ExpertLayout',
  components: { NavHeader },
  async created() {
    this.links = await this.getLinks();
  },
  data() {
    return {
      links: [],
    };
  },
  computed: {
    collapsed() {
      return this.$store.state.sidebar.collapsed;
    },
  },
  methods: {
    async getLinks() {
      const links = [
        { label: 'Главная', to: 'expert.home' },
        { label: 'Текущая экспертиза', to: 'expert.expertises.index' },
      ];

      if ((await Expert.getInfo())?.is_candidate) {
        links.pop();
      }

      return links;
    },
  },
};
</script>
