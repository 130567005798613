<template>
  <file-attach
    :label="documentType.name"
    :in-progress="uploading"
    @update:modelValue="addDocument"
    :has-error="errors"
    :error-text="errors?.join(', ')"
  />
</template>

<script>
import FileAttach from '@/components/FileAttach';
import { ExpertApplicationDocumentsRepository } from '@/repositories/expert/ExpertApplicationDocumentsRepository';
import { ValidationError } from '@/exceptions';

export default {
  name: 'ExpertApplicationDocumentUploader',
  props: {
    applicationId: {
      type: [String, Number],
      required: true,
    },
    documentType: {
      type: Object,
      required: true,
      validator(value) {
        return typeof value === 'object' && 'id' in value && 'name' in value;
      },
    },
  },
  emits: ['uploaded'],
  components: {
    FileAttach,
  },
  data() {
    return {
      uploading: false,
      errors: null,
    };
  },
  methods: {
    async addDocument(file) {
      this.errors = null;
      this.uploading = true;

      try {
        await ExpertApplicationDocumentsRepository.add({
          expert_application_id: this.applicationId,
          document_type_id: this.documentType.id,
          file,
        });

        this.$emit('uploaded');
      } catch (error) {
        if (!(error instanceof ValidationError)) {
          this.errors = ['Не удалось загрузить файл. Попробуйте, пожалуйста, позже!'];
          throw error;
        }

        this.errors = error.getAllErrors();
      } finally {
        this.uploading = false;
      }
    },
  },
};
</script>
