<template>
  <div class="file-item">
    <a :href="link" class="file-item__main">
      <div class="file-item__cover">
        <svg-clr v-if="extension" :name="'file-' + extension" :size="32" />
      </div>
      <div class="file-item__info">
        <div class="file-item__name">
          {{ name }}.{{ extension }}
          <span v-if="signed" class="tag tag--success">
            <svg-icon name="check" :size="8" /> ЭЦП
          </span>
        </div>
        <div class="file-item__description">{{ size }} {{ description }}</div>
      </div>
    </a>
    <div class="file-item__extra">
      <button
        @click="$emit('delete')"
        v-if="isAdmin"
        type="button"
        class="file-item__delete link link--gray"
      >
        <svg-icon name="delete" :size="16" />
      </button>
    </div>
  </div>
</template>

<script>
import SvgClr from '@/components/SvgClr';
import SvgIcon from '@/components/SvgIcon';
export default {
  name: 'FileItem',
  components: { SvgClr, SvgIcon },
  emits: ['delete'],
  props: {
    extension: String,
    name: String,
    signed: Boolean,
    size: String,
    description: String,
    isAdmin: Boolean,
    link: String,
  },
};
</script>
