import axios from '@/api/axios';

export const EGSRepository = {
  /**
   * @param {string} id
   * @returns {Promise<EGS[]>}
   */
  async getByEducationLevelId(id) {
    const response = await axios.get('egs', { params: { education_level_id: id } });
    return response.data.data;
  },
};

/**
 * @typedef EGS
 * @property {string} id
 * @property {string|null} name
 * @property {string|null} code
 */
