/**
 * Группирует массив объектов по переданному ключу
 * @param {Object[]|Array[]} array
 * @param {string|number} key Ключ, по значению в котором будет производиться группирование. Можно указать вложенный ключ через точку.
 * @param {string} [rest] - Ключ для объектов без значения в поле key
 * @return {object}
 */
export function groupBy(array, key, rest = 'rest') {
  const keys = typeof key === 'string' ? key.split('.') : [key];
  return array.reduce(function (result, current) {
    const groupKey = keys.reduce(
      /* TODO решить что делать со значением null */
      (value, key) => (value !== undefined && value[key] !== undefined ? value[key] : rest),
      current
    );
    if (!result[groupKey]) {
      result[groupKey] = [];
    }
    result[groupKey].push(current);
    return result;
  }, {});
}
