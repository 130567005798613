<template>
  <div class="start-page__main" :class="{ 'start-page__main--wide': !submittedSuccessfully }">
    <form v-show="!submittedSuccessfully" @submit.prevent="submit" class="start-page__form">
      <div class="start-page__groups row">
        <div class="col col--full">
          <input-text
            v-model="form.name"
            @change="vuelidate.form.name.$touch"
            :has-error="vuelidate.form.name.$error"
            :vuelidate-errors="vuelidate.form.name.$errors"
            id="organization_name"
            label="Полное наименование образовательной организации"
            placeholder="Полное наименование образовательной организации"
          />
        </div>
        <div class="col col--full">
          <custom-select
            v-model="form.specificity"
            @change="vuelidate.form.specificity.$touch"
            :has-error="vuelidate.form.specificity.$error"
            :vuelidate-errors="vuelidate.form.specificity.$errors"
            id="activity_spec"
            label="Специфика деятельности"
            placeholder="Выберите"
            :options="specOptions"
          />
        </div>
        <div class="col col--half">
          <input-text
            v-model="form.inn"
            @change="vuelidate.form.inn.$touch"
            :has-error="vuelidate.form.inn.$error"
            :vuelidate-errors="vuelidate.form.inn.$errors"
            id="organization_inn"
            label="ИНН организации"
            placeholder="0000000000"
          />
        </div>
        <div class="col col--half">
          <input-text
            v-model="form.email"
            @change="vuelidate.form.email.$touch"
            :has-error="vuelidate.form.email.$error"
            :vuelidate-errors="vuelidate.form.email.$errors"
            id="email"
            label="Эл. почта"
            placeholder="example@mail.com"
            input-type="email"
          />
        </div>
        <div class="col col--half">
          <input-text
            v-model="form.phoneNumber"
            @change="vuelidate.form.phoneNumber.$touch"
            :has-error="vuelidate.form.phoneNumber.$error"
            :vuelidate-errors="vuelidate.form.phoneNumber.$errors"
            id="tel"
            label="Контактный телефон"
            placeholder="(000) 000 00 00"
            input-type="tel"
            prefix="+7"
          />
        </div>
        <div class="col col--half">
          <input-text
            v-model="form.fio"
            @change="vuelidate.form.fio.$touch"
            :has-error="vuelidate.form.fio.$error"
            :vuelidate-errors="vuelidate.form.fio.$errors"
            id="contact_name"
            label="ФИО контактного лица"
            placeholder="Иванов Иван Иванович"
          />
        </div>

      </div>

      <div class="start-page__submit">
        <custom-button button-type="submit" label="Зарегистрироваться" />
      </div>
    </form>
    <template v-if="submittedSuccessfully">
      <div class="start-page__cover">
        <img src="@/assets/images/img-reg-ok.svg" alt="" />
      </div>

      <div class="start-page__message">Регистрация прошла успешно!</div>

      <div class="start-page__text">
        В ближайшее время наши специалисты свяжутся с Вами и предоставят учетные данные для работы с
        системой.
      </div>
    </template>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import {required, email, exactLength, numeric} from '@/utils/validators';
import axios from '@/api/axios';
import { Specificities } from '@/repositories';
import InputText from '@/components/InputText';
import CustomButton from '@/components/CustomButton';
import CustomSelect from '@/components/CustomSelect';
export default {
  name: 'Registration',
  components: { InputText, CustomButton, CustomSelect },
  setup() {
    return {
      vuelidate: useVuelidate(),
    };
  },
  created() {
    Specificities.get().then((specs) => (this.specificities = specs));
  },
  data() {
    return {
      form: {
        name: '',
        specificity: '',
        inn: '',
        email: '',
        phoneNumber: '',
        fio: '',
        application: null,
      },
      specificities: [],
      submittedSuccessfully: false,
    };
  },
  validations() {
    return {
      form: {
        name: { required },
        specificity: { required },
        inn: { required, exactLength: exactLength(10) },
        email: { required, email },
        phoneNumber: { required, numeric, exactLength: exactLength(10) }, // TODO добавить маску и проверку формата
        fio: { required },
      },
    };
  },
  computed: {
    specOptions() {
      return this.specificities.map(({ id, name }) => ({
        value: id,
        text: name,
      }));
    },
  },
  methods: {
    async submit() {
      this.vuelidate.form.$touch();
      if (this.vuelidate.form.$invalid) {
        console.log('Validation invalid', this.vuelidate.form);
        return;
      }

      await axios.post('registration', this.getFormData()); // TODO обработка ошибок, вывод сообщения об успехе
      this.submittedSuccessfully = true;
      this.resetForm();
      this.vuelidate.form.$reset();
    },
    getFormData() {
      const formData = new FormData();
      for (const field in this.form) {
        if (this.form[field]) {
          formData.append(field, this.form[field]);
        }
      }
      return formData;
    },
    resetForm() {
      this.form = {
        name: '',
        specificity: '',
        inn: '',
        email: '',
        phoneNumber: '',
        fio: '',
        application: null,
      };

      this.vuelidate.form.$reset();
    },
  },
};
</script>