<template>
  <main class="main">
    <div class="main__wrap">
      <div class="main__body">
        <div class="main__block">
          <div class="block">
            <div class="block__main">
              <div class="tbl-wrap">
                <table class="tbl">
                  <thead>
                    <tr>
                      <th v-for="column in $options.columns" :key="column">{{ column }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-if="expertises && !expertises.length">
                      <td :colspan="$options.columns.length" class="align-center">Экспертизы не найдены</td>
                    </tr>
                    <template v-else>
                      <tr
                        v-for="expertise in expertises"
                        :key="expertise.id"
                        class="tbl-link"
                        @click="redirectToExpertise(expertise.id)"
                      >
                        <td>{{ expertise.number }}</td>
                        <td>{{ expertise.type === 'FullAccreditation' ? 'Аккр.' : 'ООП' }}</td>
                        <td>{{ this.$filters.formatDate(expertise.startsAt) }}</td>
                        <td>{{ this.$filters.formatDate(expertise.endsAt) }}</td>
                        <td>{{ expertise.organization?.name }}</td>
                        <td>{{ expertise.expertIsChairman ? 'Руководитель' : 'Эксперт' }}</td>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { Expertises } from '@/repositories';

export default {
  name: 'Expertises',
  columns: ['№ процедуры', 'Тип процедуры', 'Дата начала', 'Дата окончания', 'Организация', 'Роль'],
  created() {
    Expertises.get().then((expertises) => (this.expertises = expertises));
  },
  data() {
    return {
      expertises: null,
    };
  },
  methods: {
    redirectToExpertise(expertiseId) {
      this.$router.push({ name: 'expert.expertises.show', params: { id: expertiseId } });
    },
  },
};
</script>