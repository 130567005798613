<template>
  <generic-modal :opened="open" size="lg" @close="$emit('close')">
    <template v-slot:header>
      <div class="modal-box__title">Уточнение деталей запроса</div>
      <button @click="$emit('close')" class="link link--gray modal-close" type="button">
        <svg-icon name="cross" :size="24" />
      </button>
    </template>
    <template v-slot:body>
      <div class="row row--aife">
        <div class="col col--full">
          <input-text label="Уровень образования" :model-value="educationType?.name" is-disabled />
        </div>
        <div class="col col--fourth">
          <input-text label="Код УГСН" is-disabled :model-value="educationProgram?.egs?.code" />
        </div>
        <div class="col col--three-fourths">
          <input-text
            label="Наименование УГСН"
            is-disabled
            :model-value="educationProgram?.egs?.name"
          />
        </div>
        <div class="col col--fourth">
          <input-text label="Код ОП" is-disabled :model-value="educationProgram?.code" />
        </div>
        <div class="col col--three-fourths">
          <input-text label="Наименование ОП" is-disabled :model-value="educationProgram?.name" />
        </div>
      </div>
      {{ resetRefs() }}
      <requested-document-type
          v-for="(type, index) in documentTypes"
          :id="type.id"
          :key="type.id"
          :request-id="requestId"
          :program-id="educationProgram.id"
          :documents="documentsPerType[type.id]"
          :required="type.required"
          :disabled="!editable"
          :order="index + 1"
          :description="type.name"
          :ref="setTypeRef"
          @uploaded="fetchDocuments"
          @deleted="fetchDocuments"
      />
    </template>
    <template v-slot:footer>
      <div class="line line--jcfe">
        <div class="line__item">
          <custom-button v-if="editable" label="Сохранить" @click="submitLinks" />
        </div>
      </div>
    </template>
  </generic-modal>
</template>

<script>
import GenericModal from '@/components/modals/GenericModal';
import SvgIcon from '@/components/SvgIcon';
import CustomButton from '@/components/CustomButton';
import InputText from '@/components/InputText';
import { RequestDocumentTypes } from '@/repositories/user/RequestDocumentTypes';
import { RequestDocuments } from '@/repositories';
import RequestedDocumentType from '@/components/RequestedDocumentType';
import {groupBy} from "@/utils/helpers";
export default {
  name: 'RequestedDocuments',
  components: {
    GenericModal,
    SvgIcon,
    CustomButton,
    InputText,
    RequestedDocumentType,
  },
  watch: {
    open(open) {
      if (open) {
        this.fetchDocumentTypes();
        this.fetchDocuments();
        return;
      }
      this.documentTypes = [];
      this.documentsPerType = {};
    },
  },
  data() {
    return {
      documentTypes: [],
      documentsPerType: {},
      typeRefs: [],
    };
  },
  methods: {
    async fetchDocumentTypes() {
      this.documentTypes = await RequestDocumentTypes.forProgramType(this.educationType?.id);
    },
    async fetchDocuments() {
      const documents = await RequestDocuments.all(this.requestId, {
        programId: this.educationProgram?.id,
        typeId: this.educationType?.id,
      });
      this.documentsPerType = groupBy(documents, 'type.id');
    },
    submitLinks() {
      this.typeRefs.forEach((typeRef) => typeRef.submitLink());
    },
    setTypeRef(el) {
      if (el) {
        this.typeRefs.push(el);
      }
    },
    resetRefs() {
      this.typeRefs = [];
    },
    deleteDocument(documentId) {
      RequestDocuments
        .remove(this.requestId, documentId)
        .then(this.fetchDocuments)
        .catch(() => window.alert('Не удалось удалить документ. Попробуйте, пожалуйста, позже!'));
    },
  },
  props: {
    educationProgram: Object,
    educationType: Object,
    requestId: [String, Number],
    open: Boolean,
    editable: Boolean,
  },
  emits: ['close'],
};
</script>
