import { createStore } from 'vuex';
import { user } from './modules/user';
import { sidebar } from '@/store/modules/sidebar';
import { eo } from '@/store/modules/eo';

export default createStore({
  modules: {
    user,
    sidebar,
    eo,
  },
  state: {},
  getters: {},
  mutations: {},
  actions: {},
});
