import CuratorLayout from '@/layouts/CuratorLayout';
import MonitoringLayout from '@/layouts/curator/MonitoringLayout';
import MonitoringOrganizationTasks from '@/views/curator/MonitoringOrganizationTasks';
import MonitoringTaskPrograms from '@/views/curator/MonitoringTaskPrograms';
import MonitoringProgramMarksLayout from '@/layouts/user/MonitoringProgramMarksLayout';
import MonitoringProgramMarks from '@/views/curator/MonitoringProgramMarks';

export const routes = [
  {
    path: '/curator',
    component: CuratorLayout,
    redirect: { name: 'curator.monitoring.organization-tasks' },
    meta: {
      requiresAuth: true,
      role: 'curator',
    },
    children: [
      {
        path: 'monitoring',
        component: MonitoringLayout,
        children: [
          {
            path: '',
            name: 'curator.monitoring.organization-tasks',
            component: MonitoringOrganizationTasks,
          },
          {
            path: 'task-programs/:id',
            name: 'curator.monitoring.task-programs',
            component: MonitoringTaskPrograms,
          },
        ],
      },
      {
        path: 'monitoring',
        component: MonitoringProgramMarksLayout,
        children: [
          {
            path: 'program-marks/:id',
            component: MonitoringProgramMarks,
            name: 'curator.monitoring.program-marks',
          },
        ],
      },
    ],
  },
];
