<template>
  <GenericModal :size="size" close-button opened @close="$emit('close')">
    <template #title>Подпись документов</template>
    <template #body>
      <div class="block">
        <div class="block__main">
          <div class="row rof--aife">
            <div class="col col--full">
              <div v-if="!cryptoPro.loaded" class="loader">
                <SvgClr name="loader" :size="24" />
                Загружаем плагин
              </div>
              <div v-if="cryptoPro.loaded && !cryptoPro.isValidSystemSetup">Не удалось загрузить плагин</div>
              <template v-if="cryptoPro.loaded && cryptoPro.isValidSystemSetup">
                <div>Плагин загружен</div>
                <div v-if="cryptoPro.systemInfo">Версия плагина: {{ cryptoPro.systemInfo.cadesVersion }}</div>
                <div v-if="cryptoPro.systemInfo">Версия криптопровайдера: {{ cryptoPro.systemInfo.cspVersion }}</div>
              </template>
            </div>
          </div>
          <div class="row row--aife">
            <div class="col col--full">
              <CustomSelect
                label="Сертификат"
                placeholder="Выберите сертификат"
                :options="cryptoPro.certificates.map(certificateToOption)"
                @update:modelValue="handleCertificateSelected"
                :model="certificateThumbprint"
              />
            </div>
          </div>
          <slot name="body"></slot>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="line line--jcfe">
        <div class="line__item">
          <CustomButton
            label="Подписать"
            @click="handleSign"
            :disabled="hashes?.length < 1 || certificateThumbprint == null || loading"
            :is-loading="loading"
          />
        </div>
      </div>
    </template>
  </GenericModal>
</template>

<script setup>
import GenericModal from '@/components/modals/GenericModal.vue';
import CustomButton from '@/components/CustomButton.vue';
import CustomSelect from '@/components/CustomSelect.vue';
import { createDetachedSignature, getSystemInfo, getUserCertificates, isValidSystemSetup } from 'crypto-pro';
import { reactive, ref } from 'vue';
import SvgClr from '@/components/SvgClr.vue';

// eslint-disable-next-line no-undef
const props = defineProps({
  hashes: {
    type: Array,
    required: true,
    validator(value) {
      return Array.isArray(value) && value.every((item) => typeof item === 'string');
    },
  },
  size: {
    type: String,
    default: 'sm',
    validator(size) {
      return ['sm', 'lg'].includes(size);
    },
  },
  loading: Boolean,
});

// eslint-disable-next-line no-undef
const emit = defineEmits(['changed:certificate', 'signed:hashes', 'close']);

/**
 * @type {UnwrapNestedRefs<{
 * loaded: boolean,
 * error: string|null,
 * systemInfo: SystemInfo,
 * isValidSystemSetup: boolean|null,
 * certificates: Certificate[],
 * }>}
 */
const cryptoPro = reactive({
  loaded: false,
  error: null,
  isValidSystemSetup: null,
  systemInfo: null,
  certificates: [],
});

isValidSystemSetup()
  .then((valid) => (cryptoPro.isValidSystemSetup = valid))
  .catch(() => (cryptoPro.isValidSystemSetup = false))
  .finally(() => (cryptoPro.loaded = true));
getSystemInfo().then((info) => (cryptoPro.systemInfo = info));
getUserCertificates().then((certificates) => (cryptoPro.certificates = certificates));

/** @type {ToRef<string|null>} */
const certificateThumbprint = ref(null);
function handleCertificateSelected(thumbprint) {
  certificateThumbprint.value = thumbprint;
  const certificate = cryptoPro.certificates.find((cert) => cert.thumbprint === thumbprint);
  emit('changed:certificate', certificate);
}

async function handleSign() {
  try {
    const result = await signHashes(props.hashes, certificateThumbprint.value);
    emit('signed:hashes', result);
  } catch (e) {
    console.error(e);
    window.alert('При подписании что-то пошло не так. Попробуйте, пожалуйста, позже!');
  }
}

/**
 * @param {string[]} hashes
 * @param {string} thumbprint
 * @returns {Promise<{hash: string, signature: string}[]>}
 */
async function signHashes(hashes, thumbprint) {
  const signed = [];
  for (const hash of hashes) {
    const signature = await createDetachedSignature(thumbprint, hash);
    signed.push({ hash, signature });
  }
  return signed;
}

/**
 * @param {Certificate} certificate
 * @returns {{value: string, text: string}}
 */
function certificateToOption(certificate) {
  return {
    value: certificate.thumbprint,
    text: certificate.name,
  };
}
</script>
