import NotFound from '@/views/errors/NotFound';
import Maintenance from '@/views/errors/Maintenance';

export const routes = [
  {
    path: '/404',
    component: NotFound,
    name: 'not-found',
  },
  {
    path: '/maintenance',
    component: Maintenance,
    name: 'maintenance',
  },
];
