<template>
  <main class="main">
    <div class="main__wrap main__wrap--md">
      <div class="main__top">
        <div class="main__title">
          <div class="title">Прохождение аккредитационного мониторинга</div>
        </div>
      </div>

      <div class="main__body">
        <div class="main__block">
          <div class="row row--aife">
            <div class="col col--full">
              <input-text
                label="Наименование образовательной организации/ОГРН/Адрес место нахождения
                  образовательной организации"
                v-model="filters.searchQuery"
              />
            </div>
            <div class="col col--third">
              <custom-select label="Статус" v-model="filters.status" :options="statuses" />
            </div>
            <div class="col col--third">
              <custom-select
                label="Примечание"
                v-model="filters.note"
                placeholder="Выберите"
                :options="notes"
              />
            </div>
            <div class="col col--third">
              <input-text
                label="Доля прохождения"
                v-model.number="filters.completionPercent"
                min="0"
                max="100"
                inputType="number"
              />
            </div>
            <div class="col col--full">
              <div class="line">
                <div class="line__item">
                  Количество: <strong>{{ organizationTasks.length }}</strong>
                </div>
                <div class="line__item line__item--mla">
                  <custom-button label="Сбросить" color="gray" @click="resetFilters" />
                </div>
                <div class="line__item">
                  <custom-button label="Применить" @click="submitFilters" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="main__block">
          <div class="block">
            <div class="block__main">
              <div class="tbl-wrap">
                <table class="tbl">
                  <thead>
                    <tr>
                      <th>Наименование образовательной организации</th>
                      <th>ОГРН</th>
                      <th>Адрес места нахождения</th>
                      <th>Дата начала</th>
                      <th>Дата окончания</th>
                      <th>Статус</th>
                      <th>Примечание</th>
                      <th>Доля прохождения</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="task in organizationTasks"
                      :key="task.id"
                      @click="redirectToTaskPrograms(task.id)"
                      class="tbl-link"
                    >
                      <td>
                        {{ task.organization?.RegularName || task.organization?.FullName }}
                      </td>
                      <td>{{ task.organization?.GosRegNum }}</td>
                      <td>{{ task.organization?.Address || task.organization?.LawAddress }}</td>
                      <td>
                        {{ task.date_start && new Date(task.date_start).toLocaleDateString() }}
                      </td>
                      <td>
                        {{ task.date_end && new Date(task.date_end).toLocaleDateString() }}
                      </td>
                      <td>
                        <span
                          class="status"
                          :class="{
                            'status--not-start': task.status_id === 1 && !task.has_started_to_fill,
                            'status--in-progress': task.status_id === 1 && task.has_started_to_fill,
                            'status--done': task.status_id === 2,
                          }"
                        >
                          {{ getTaskStatus(task) }}</span
                        >
                      </td>
                      <td>{{ task.is_noted ? 'Имеется' : 'Не имеется' }}</td>
                      <td>
                        {{ hasTaskAtLeastOneSendProgram(task) ? task.completion_percent : 0 }}%
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div v-if="!organizationTasks.length && !isLoading" class="main__block">
          <div class="blank">
            <div class="blank__cover">
              <svg-clr name="accreditation-monitoring" :size="24" />
            </div>
            <div class="blank__text">Данные отсутствуют</div>
          </div>
        </div>
      </div>

      <div v-if="isLoading" class="main__over">
        <div class="loader loader--l">
          <svg-clr name="loader" size="24" />
        </div>
      </div>
    </div>
  </main>
</template>

<script setup>
import { useRouter } from 'vue-router';
import CustomSelect from '@/components/CustomSelect.vue';
import InputText from '@/components/InputText';
import CustomButton from '@/components/CustomButton';
import { onBeforeMount, onMounted, ref } from 'vue';
import { Monitoring } from '@/repositories';
import SvgClr from '@/components/SvgClr';

const defaultFilters = { searchQuery: '', status: 'Все', note: 'Все', completionPercent: null };

const router = useRouter();
const organizationTasks = ref([]);
const isLoading = ref();
const filters = ref({ ...defaultFilters });

const statuses = [
  { text: 'Все', value: 'Все' },
  { text: 'Не приступила', value: 'Не приступила' },
  { text: 'Приступила', value: 'Приступила' },
  { text: 'Завершено', value: 'Завершено' },
];

const notes = [
  { text: 'Все', value: 'Все' },
  { text: 'Имеется', value: 'Имеется' },
  { text: 'Не имеется', value: 'Не имеется' },
];

const getTaskStatus = (task) => {
  if (task.status_id === 1 && !task.has_started_to_fill) {
    return 'Не приступила';
  } else if (task.status_id === 1 && task.has_started_to_fill) {
    return 'Приступила';
  } else if (task.status_id === 2) {
    return 'Завершено';
  }
};

// Доля прохождения, которая отображается после отправки программы в закрытую часть
// Добавить долю заполнения/запрос на получение нового статуса, от которой будет меняться статус
// Статус - изначально "Не приступила", потом меняется на "Приступила", как появилась хотя бы одна программа, по которой заполнили хотя бы один показатель

const resetFilters = () => {
  filters.value = { ...defaultFilters };
  getOrganizationTasks();
};

const submitFilters = () => {
  getOrganizationTasks();
};

const redirectToTaskPrograms = (id) => {
  router.push({ name: 'curator.monitoring.task-programs', params: { id: id } });
};

async function getOrganizationTasks() {
  try {
    const response = await Monitoring.getOrganizationTasks(filters.value);
    organizationTasks.value = response;
  } catch (error) {
    console.log(error);
  } finally {
    isLoading.value = false;
  }
}

onBeforeMount(() => {
  isLoading.value = true;
});

onMounted(() => {
  getOrganizationTasks();
});

const hasTaskAtLeastOneSendProgram = (task) => {
  return task.programs.find((program) => program.is_sent || program.is_rejected);
};
</script>
