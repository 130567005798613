import ExternalLayout from '@/layouts/ExternalLayout';
import MainPageLayout from '@/layouts/external/MainPageLayout';
import GeneralInfo from '@/views/user/GeneralInfo';
import MonitoringLayout from '@/layouts/external/MonitoringLayout';
import MonitoringTasks from '@/views/user/MonitoringTasks';
import MonitoringTaskPrograms from '@/views/user/MonitoringTaskPrograms';
import MonitoringProgramMarksLayout from '@/layouts/user/MonitoringProgramMarksLayout';
import MonitoringProgramMarks from '@/views/user/MonitoringProgramMarks';

export const routes = [
  {
    path: '/external',
    component: ExternalLayout,
    redirect: { name: 'external.home' },
    meta: {
      requiresAuth: true,
      role: 'user',
    },
    children: [
      {
        path: 'home',
        component: MainPageLayout,
        children: [
          {
            path: '',
            name: 'external.home',
            component: GeneralInfo,
          },
        ],
      },
      {
        path: 'monitoring',
        component: MonitoringLayout,
        children: [
          {
            path: '',
            name: 'external.monitoring.tasks',
            component: MonitoringTasks,
          },
          {
            path: 'task-programs/:id',
            name: 'external.monitoring.task-programs',
            component: MonitoringTaskPrograms,
          },
        ],
      },
      {
        path: 'monitoring',
        component: MonitoringProgramMarksLayout,
        children: [
          {
            path: 'program-marks/:id',
            component: MonitoringProgramMarks,
            name: 'external.monitoring.program-marks',
          },
        ],
      },
    ],
  },
];
