<template>
  <div class="main__body">
    <div class="main__block">
      <div class="block">
        <div class="block__main">
          <div class="row row--aife">
            <div v-for="documentType in documentTypes" :key="documentType.id" class="col col--full">
              <div class="form-label">{{ documentType.name }}</div>
              <div class="files">
                <div v-for="file in documentType.applicationFiles" :key="file.id">
                  <a v-if="file.is_link" :href="file.name">
                    {{ file.name }}
                  </a>
                  <downloadable-file
                      v-else
                      :name="file.name"
                      :link="file.path"
                  />
                </div>
              </div>
            </div>

            <div v-for="groupType in documentGroupTypes" :key="groupType.id" class="col col--full">
              <div class="form-label">{{ groupType.name }}</div>
              <div class="files">
                <downloadable-file
                    v-for="document in groupType.documents"
                    :key="document.id"
                    :name="document.fileName"
                    :link="document.filePath"
                />
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getFileName, getFileExtension } from '@/utils/helpers';
import { Expertises } from '@/repositories';
import DownloadableFile from '@/components/DownloadableFile';
export default {
  name: 'ApplicationDocuments',
  components: { DownloadableFile },
  created() {
    this.getDocuments();
  },
  watch: {
    id() {
      this.getDocuments();
    },
  },
  data() {
    return {
      documentTypes: [],
      documentGroupTypes: [],
    };
  },
  methods: {
    getDocuments() {
      Expertises
          .getApplicationDocuments(this.id)
          .then((documents) => {
            this.documentTypes = documents.documentTypes;
            this.documentGroupTypes = documents.documentGroupTypes;
          })
    },
    getFileName(filePath) {
      if (filePath == null) {
        return;
      }
      if (filePath.endsWith('.sig')) {
        filePath = filePath.replace(/\.sig$/i, '');
      }

      return getFileName(filePath);
    },
    /** @param {string} filePath */
    getFileExtension(filePath) {
      if (filePath == null) {
        return;
      }
      if (filePath.endsWith('.sig')) {
        filePath = filePath.replace(/\.sig$/i, '');
      }

      return getFileExtension(filePath);
    },
    isSigned(fileName) {
      return fileName.endsWith('.sig');
    },
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
};
</script>
