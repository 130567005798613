<template>
  <main class="main">
    <div class="main__wrap">
      <div class="main__body">
        <div class="main__block">
          <div class="block">
            <div class="block__top">
              <div class="block-title">Личные данные</div>
            </div>
            <div class="block__main">
              <div class="row row--aife">
                <div class="col col--third">
                  <input-text label="Регистрационный номер" :model-value="expert.code" isDisabled />
                </div>
                <div class="col col--two-thirds"></div>
                <div class="col col--third">
                  <input-text label="Фамилия" :model-value="expert.surname" isDisabled />
                </div>
                <div class="col col--third">
                  <input-text label="Имя" :model-value="expert.name" isDisabled />
                </div>
                <div class="col col--third">
                  <input-text label="Отчество" :model-value="expert.patronymic" isDisabled />
                </div>
                <div class="col col--third">
                  <input-text label="ИНН" :model-value="expert.inn" isDisabled />
                </div>
                <div class="col col--third">
                  <input-text label="Пол" :model-value="expert.sex" isDisabled />
                </div>
                <div class="col col--third">
                  <input-text
                    label="Дата рождения"
                    :model-value="$filters.formatDate(expert.birthDate)"
                    button="calendar"
                    isDisabled
                  />
                </div>
                <div class="col col--full">
                  <text-area
                    label="Основное (текущее) место работы"
                    :model-value="currentWorkplace"
                    isDisabled
                  />
                </div>
                <div class="col col--full">
                  <text-area label="Сведения об образовании" :model-value="education" isDisabled />
                </div>
                <div class="col col--full">
                  <div class="form-label">Ученые степени</div>
                  <div class="tbl-wrap">
                    <table class="tbl-list">
                      <thead>
                        <tr>
                          <th style="width: 70%">Ученая степень</th>
                          <th style="width: 25%">Год присуждения</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="degree in expert.academic_degrees" :key="degree.id">
                          <td>{{ degree.name }}</td>
                          <td>{{ degree.conferment_year ?? 'Не указан' }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div class="col col--full">
                  <div class="form-label">Ученые звания</div>
                  <div class="tbl-wrap">
                    <table class="tbl-list">
                      <thead>
                        <tr>
                          <th style="width: 40%">Ученое звание</th>
                          <th style="width: 40%">Научная специальность</th>
                          <th style="width: 15%">Год присуждения</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="rank in expert.academic_ranks" :key="rank.id">
                          <td>{{ rank.name }}</td>
                          <td>{{ rank.field_of_activity ?? 'Не указана' }}</td>
                          <td>{{ rank.conferment_year ?? 'Не указан' }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="col col--full">
                  <input-text label="Должность" :model-value="expert.position" isDisabled />
                </div>
                <div class="col col--full">
                  <input-text
                    label="Федеральный округ"
                    :model-value="expert.region?.federal_district?.name"
                    isDisabled
                  />
                </div>
                <div class="col col--full">
                  <input-text label="Регион" :model-value="expert.region?.name" isDisabled />
                </div>
                <div class="col col--full">
                  <input-text
                    label="Адрес местонахождения"
                    :model-value="expert.address"
                    isDisabled
                  />
                </div>
                <div class="col col--third">
                  <input-text
                    label="Уровень доступа"
                    :model-value="expert.accessLevel"
                    isDisabled
                  />
                </div>
                <div class="col col--full">
                  <div class="line">
                    <div class="line__item">
                      <switch-input
                        type="checkbox"
                        size="m"
                        label="Допуск к гос. тайне"
                        :model-value="expert.hasAccessToStateSecret"
                        disabled
                      />
                    </div>
                    <div class="line__item">
                      <switch-input
                        type="checkbox"
                        size="m"
                        :model-value="expert.belongsToReligiousOrganization"
                        label="Из религиозной организации"
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="main__block">
          <div class="block">
            <div class="block__top">
              <div class="block-title">Контактная информация</div>
            </div>
            <div class="block__main">
              <div class="row row--aife">
                <div class="col col--third">
                  <input-text
                    label="Электронная почта"
                    :model-value="expert.contacts?.email"
                    isDisabled
                  />
                </div>
                <div class="col col--third">
                  <input-text label="Факс" :model-value="expert.contacts?.fax" isDisabled />
                </div>
                <div class="col col--third">
                  <input-text
                    inputType="tel"
                    label="Телефон"
                    :model-value="expert.contacts?.phoneNumber"
                    isDisabled
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="main__block">
          <div class="block">
            <div class="block__top">
              <div class="block-title">Текущие полномочия</div>
            </div>

            <div class="block__main">
              <div class="tbl-wrap">
                <table class="tbl">
                  <thead>
                    <tr>
                      <th v-for="column in $options.columns" :key="column">
                        {{ column }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(row, index) in rows" :key="index">
                      <td v-for="(cell, index) in row" :key="index">
                        {{ cell }}
                      </td>
                    </tr>
                    <tr v-if="!rows.length">
                      <td :colspan="$options.columns.length" class="align-center">
                        Полномочия не найдены
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { orderBy } from '@/utils/helpers';
import { Expert } from '@/repositories';
import SwitchInput from '@/components/SwitchInput';
import InputText from '@/components/InputText';
import TextArea from '@/components/TextArea';
export default {
  name: 'ExpertDetails',
  components: { SwitchInput, InputText, TextArea },
  columns: ['Код УГСН', 'УГСН', 'Уровень образования', 'Срок полномочий'],
  created() {
    Expert.getInfo().then((expert) => (this.expert = expert));
    Expert.getPowers()
      .then((powers) => orderBy(powers, [{ key: 'educationLevel', order: 'desc' }, 'egsCode']))
      .then((powers) => (this.powers = powers));
  },
  data() {
    return {
      expert: {},
      powers: [],
    };
  },
  computed: {
    currentWorkplace() {
      const currentWorkplaces = (this.expert.workplaces || []).filter(
        (workplace) => workplace.isCurrent
      );
      return currentWorkplaces
        .map((w) => `${w.organization?.name ?? w.text}, с ${w.startedAt} по ${w.endedAt || 'настоящее время'}`)
        .join('; ');
    },
    education() {
      return (this.expert.educations || []).map((education) => education.organization).join('; ');
    },
    accessLevel() {
      /** Если понадобится выводить нулевой уровень, то необходимо изменить */
      return this.expert.accessLevel ? `${this.expert.accessLevel}-й уровень` : null;
    },
    rows() {
      return this.powers.map((i) => [
        i.egsCode,
        i.egsName,
        i.educationLevel,
        i.expiresAt ? this.$filters.formatDate(i.expiresAt) : '-',
      ]);
    },
  },
};
</script>
