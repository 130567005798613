<template>
  <svg :width="size" :height="size" class="svg">
    <use :href="require('@/assets/images/sprite.svg') + `#${name}-${size}`" />
  </svg>
</template>

<script>
export default {
  name: 'SvgIcon',
  props: {
    size: {
      type: Number,
      default: 16,
    },
    name: String,
  },
};
</script>
