/**
 * Скачивает содержимое blob в браузере пользователя
 * @param {Blob} blob - Blob файла, который нужно скачать
 * @param {string} [as] - Название и расширение, под которым скачать файл
 */
export function downloadFile(blob, as) {
  const objectUrl = window.URL.createObjectURL(blob);

  const anchor = document.createElement('a');
  anchor.download = as;
  anchor.href = objectUrl;
  document.body.appendChild(anchor);
  anchor.click();
  anchor.remove();

  window.URL.revokeObjectURL(objectUrl);
}