<template>
  <div class="start-page">
    <div class="start-page__wrap">
      <div class="start-page__top">
        <div class="start-page__logo">
          <img
            src="~@/assets/images/logo-visual.svg"
            width="48"
            height="42"
            alt="Логотип ИС ГА"
            class="logo__img"
          />
        </div>
        <div class="start-page__title">
          Информационная система<br />государственной аккредитации
        </div>
      </div>

      <div class="start-page__main">
        <div class="start-page__cover">
          <img src="~@/assets/images/img-error-404.svg" alt="404" />
        </div>

        <div class="start-page__error-block error-block">
          <div class="error-block__title">Страница не найдена</div>
          <div class="error-block__description">Вы попали в раздел, которого не существует</div>
        </div>
      </div>
      <div class="start-page__bottom">
        <div class="start-page__error-links">
          <p>
            <a @click="$router.back()" class="link link--underline link--primary"
              >Вернуться назад</a
            >
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotFound',
};
</script>
