<template>
  <GenericModal @close="$emit('close')" :opened="open">
    <template v-slot:header>
      <div class="modal-box__title">Добавление сведений об образовании</div>
      <button @click="$emit('close')" class="link link--gray modal-close" type="button">
        <SvgIcon name="cross" :size="24" />
      </button>
    </template>
    <template v-slot:body>
      <div class="modal-box__block">
        <div class="block">
          <div class="block__main">
            <div class="row row--aife">
              <div class="col col--full">
                <TextArea
                  label="Название организации, квалификации и год окончания"
                  placeholder="Укажите полное наименование организации, год окончания и наименование присвоенной квалификации"
                  v-model="form.text"
                  :has-error="vuelidate.form.text.$error"
                  :vuelidate-errors="vuelidate.form.text.$errors"
                  :rows="3"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="line line--jcfe">
        <div class="line__item">
          <CustomButton label="Добавить" @click="add" />
        </div>
      </div>
    </template>
  </GenericModal>
</template>

<script>
import CustomButton from '@/components/CustomButton';
import GenericModal from '@/components/modals/GenericModal';
import SvgIcon from '@/components/SvgIcon';
import TextArea from '@/components/TextArea';
import { ExpertEducationsRepository } from '@/repositories/expert/ExpertEducationsRepository';
import useVuelidate from '@vuelidate/core';
import { required } from '@/utils/validators';
export default {
  name: 'AddEducationModal',
  props: {
    open: Boolean,
  },
  emits: ['closed', 'adding', 'added'],
  components: {
    CustomButton,
    GenericModal,
    SvgIcon,
    TextArea,
  },
  setup() {
    return { vuelidate: useVuelidate() };
  },
  data() {
    return {
      form: {
        text: null,
      },
    };
  },
  validations() {
    return {
      form: {
        text: { required },
      },
    };
  },
  methods: {
    async add() {
      this.vuelidate.form.$touch();
      if (this.vuelidate.form.$invalid) {
        return;
      }

      this.$emit('adding');

      try {
        await ExpertEducationsRepository.addForAuthenticatedExpert({ text: this.form.text });
      } catch (error) {
        window.alert('Не удалось добавить образование. Попробуйте, пожалуйста, позже!');
        throw error;
      }

      this.$emit('added');
      this.form.text = null;
    },
  },
};
</script>
