import axios from '@/api/axios';

export const ExpertSignaturesRepository = {
  async getForAuthenticatedExpert() {
    const response = await axios.get('expert/signatures');
    return response.data.data;
  },
  /**
   * @param  {object} signature
   * @param  {string|number} signature.serial_number
   * @param  {string} signature.valid_from
   * @param  {string} signature.valid_until
   * @returns {Promise<void>}
   */
  async addForAuthenticatedExpert(signature) {
    await axios.post('expert/signatures', signature);
  },
  async remove(id) {
    await axios.delete(`expert/signatures/${id}`);
  },
};
