<template>
  <div class="start-page__main start-page__main--narrow">
    <form class="start-page__form" @submit.prevent="submit">
      <div class="start-page__groups">
        <div class="start-page__group">
          <input-text
            v-model="form.login"
            @change="vuelidate.form.login.$touch"
            :has-error="vuelidate.form.login.$error || hasErrors"
            :vuelidate-errors="vuelidate.form.login.$errors"
            id="login"
            label="Логин"
          />
        </div>
        <div class="start-page__group">
          <input-text
            v-model="form.password"
            @change="vuelidate.form.password.$touch"
            :has-error="vuelidate.form.password.$error || hasErrors"
            :vuelidate-errors="vuelidate.form.password.$errors"
            input-type="password"
            id="password"
            label="Пароль"
          />
        </div>
      </div>

      <div v-if="hasErrors" class="start-page__error">
        <div v-for="error in errors" :key="error" class="error-text error-text--center">
          {{ error }}
        </div>
      </div>

      <div class="start-page__submit start-page__submit--auth">
        <custom-button button-type="submit" label="Войти" :disabled="vuelidate.form.$invalid" />
      </div>
    </form>

    <div class="start-page__extra">
      <a href="#" class="c-btn c-btn--primary c-btn--s c-btn--light">
        <span class="c-btn__icon">
          <svg-clr name="logo-gosuslugi" :size="24" />
        </span>
        <div class="c-btn__label">Войти через Госуслуги</div>
      </a>
    </div>
  </div>

  <div class="start-page__bottom">
    <div class="start-page__link">
      <!-- Ссылка на документ в закрытой части -->
      <!--suppress HtmlUnknownTarget -->
      <a href="/storage/manuals/user.pdf" download target="_blank" class="link link--primary link--underline">
        Руководство пользователя (ЛК ОО)
      </a>
    </div>
    <div class="start-page__link">
      <!-- Ссылка на документ в закрытой части -->
      <!--suppress HtmlUnknownTarget -->
      <a href="/storage/manuals/expert.pdf" download target="_blank" class="link link--primary link--underline">
        Руководство пользователя (ЛК Эксперта)
      </a>
    </div>

    <div class="start-page__contacts">
      <p>Техническая поддержка</p>
      <p>
        <a href="tel:+74956404036" class="link link--gray link--underline">+7 (8362) 41-38-84, +7 (495) 640-40-36 доб. 134, 136 </a>,
      </p>
      <p>
        <a href="mailto:support@msk.nica.ru" class="link link--gray link--underline">Написать по e-mail</a>
      </p>
    </div>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { required } from '@/utils/validators';
import CustomButton from '@/components/CustomButton';
import InputText from '@/components/InputText';
import SvgClr from '@/components/SvgClr';
import { ValidationError } from '@/exceptions';
export default {
  name: 'Login',
  components: { CustomButton, InputText, SvgClr },
  setup() {
    return { vuelidate: useVuelidate() };
  },
  data() {
    return {
      form: {
        login: '',
        password: '',
      },
      errors: [],
    };
  },
  computed: {
    hasErrors() {
      return this.errors.length > 0;
    },
  },
  validations() {
    return {
      form: {
        login: { required },
        password: { required },
      },
    };
  },
  methods: {
    async submit() {
      this.vuelidate.form.$touch();
      if (this.vuelidate.form.$invalid) {
        return;
      }

      try {
        await this.$store.dispatch('authenticate', this.form);
        await this.$router.push({ path: this.$store.getters.homePath });
      } catch (error) {
        this.errors =
          error instanceof ValidationError
            ? error.getAllErrors()
            : ['Что-то пошло не так. Попробуйте, пожалуйста, позже!'];
      }
    },
  },
};
</script>
