import axios from '@/api/axios';

export const Specificities = {
  /**
   * @return {Promise<EducationalOrganizationSpecificity[]>}
   */
  async get() {
    const response = await axios.get('/specificities');
    return response.data.data;
  },
};

/**
 * @typedef {object} EducationalOrganizationSpecificity
 * @property {number} id
 * @property {string|null} name
 */
