<template>
  <div class="main__body">
    <div class="main__block">
      <div class="block">
        <div class="block__main">
          <div class="tbl-wrap">
            <table class="tbl">
              <thead>
                <tr>
                  <th style="width: 15%">Код УГСН</th>
                  <th style="width: 40%">Наименование УГСН</th>
                  <th style="width: 40%">Уровень образования</th>
                  <th v-if="canProgramsBeDeleted" style="width: 5%"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="program in programs" :key="program.id">
                  <td>{{ program.egs.code }}</td>
                  <td>{{ program.egs.name }}</td>
                  <td>{{ program.education_level.name }}</td>
                  <td v-if="canProgramsBeDeleted" class="align-center">
                    <button
                      @click.stop="handleDeleteProgramClick(program)"
                      class="link link--gray"
                      type="button"
                    >
                      <SvgIcon name="delete" :size="16" />
                    </button>
                  </td>
                </tr>

                <tr v-if="programs && programs.length === 0">
                  <td colspan="3" class="align-center">Программы не найдены.</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div v-if="editable" class="main__block">
      <div class="block">
        <div class="row row--aife">
          <div class="col col--third">
            <custom-select
              label="Уровень образования"
              placeholder="Выберите уровень образования"
              v-model="form.educationLevelId"
              :options="educationLevelOptions"
              @update:modelValue="loadEGS"
            />
          </div>
          <div class="col col--third">
            <custom-select
              label="УГСН"
              placeholder="Выберите УГСН"
              v-model="form.egsId"
              :options="egsOptions"
            />
          </div>
          <div class="col col--third">
            <custom-button
              label="Добавить"
              :disabled="!(form.egsId && form.educationLevelId)"
              @click="submitForm"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomButton from '@/components/CustomButton';
import CustomSelect from '@/components/CustomSelect';
import { ExpertApplicationProgramsRepository } from '@/repositories/expert/ExpertApplicationProgramsRepository';
import { ExpertApplicationsRepository } from '@/repositories/expert/ExpertApplicationsRepository';
import { EducationLevelsRepository } from '@/repositories/EducationLevelsRepository';
import { EGSRepository } from '@/repositories/EGSRepository';
import { STATUS_ID } from '@/constants/expert/applications/status';
import SvgIcon from '@/components/SvgIcon.vue';

export default {
  name: 'ApplicationPrograms',
  components: {
    SvgIcon,
    CustomButton,
    CustomSelect,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  created() {
    this.loadResources();
  },
  watch: {
    id() {
      this.loadResources();
    },
  },
  data() {
    return {
      programs: null,
      application: null,
      educationLevels: [],
      egs: [],
      form: {
        egsId: null,
        educationLevelId: null,
      },
    };
  },
  computed: {
    editable() {
      return [STATUS_ID.DRAFT, STATUS_ID.REVISION].includes(this.application?.status?.id);
    },
    educationLevelOptions() {
      return this.educationLevels.map((level) => ({
        value: level.id,
        text: level.name,
      }));
    },
    egsOptions() {
      return this.egs.map((group) => ({ value: group.id, text: `${group.code} ${group.name}` }));
    },
    canProgramsBeDeleted() {
      return this.application?.status?.id === STATUS_ID.DRAFT;
    },
  },
  methods: {
    loadResources() {
      this.loadPrograms();

      ExpertApplicationsRepository.find(this.id)
        .then((application) => (this.application = application))
        .then(() => {
          if (!this.editable) {
            return;
          }
          EducationLevelsRepository.allForExpert().then(
            (educationLevels) => (this.educationLevels = educationLevels)
          );
        });
    },
    async loadPrograms() {
      this.programs = await ExpertApplicationProgramsRepository.getByApplicationId(this.id);
    },
    async loadEGS(educationLevelId) {
      this.egs = await EGSRepository.getByEducationLevelId(educationLevelId);
    },
    async submitForm() {
      await ExpertApplicationProgramsRepository.add({
        expert_application_id: this.id,
        egs_id: this.form.egsId,
        education_level_id: this.form.educationLevelId,
      });
      this.loadPrograms();

      this.resetForm();
    },
    resetForm() {
      this.form = {
        egsId: null,
        educationLevelId: null,
      };
    },
    async handleDeleteProgramClick(program) {
      if (!window.confirm('Вы уверены, что хотите удалить программу?')) {
        return;
      }

      const programIndex = this.programs?.indexOf(program);
      if (programIndex !== -1) {
        this.programs?.splice(programIndex, 1);
      }

      try {
        await ExpertApplicationProgramsRepository.remove(program);
      } catch (e) {
        console.error(e);
        window.alert('Не удалось удалить программу. Попробуйте, пожалуйста, позже!');
        if (programIndex !== -1) {
          this.programs?.splice(programIndex, 0, program);
        }
      }
    },
  },
};
</script>
