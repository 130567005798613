<template>
  <teleport to="body">
    <div class="modal" :class="{ open: opened }" :id="id">
      <div @click="$emit('close')" class="modal-close modal__bg"></div>

      <div :class="`modal-box modal-box--${size}`">
        <div class="modal-box__top">
          <slot name="header">
            <div class="modal-box__title"><slot name="title"></slot></div>
          </slot>
          <button v-if="closeButton" @click="$emit('close')" class="link link--gray modal-close" type="button">
            <svg-icon name="cross" :size="24"/>
          </button>
        </div>

        <div class="modal-box__main">
          <div class="modal-box__body">
            <slot name="body"></slot>
          </div>
        </div>

        <div class="modal-box__bottom">
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
import SvgIcon from '@/components/SvgIcon';
export default {
  name: 'GenericModal',
  components: { SvgIcon },
  emits: ['close'],
  props: {
    size: {
      type: String,
      default: 'sm',
      validator: (size) => ['sm', 'lg'].includes(size),
    },
    id: String,
    opened: Boolean,
    closeButton: Boolean,
    title: String,
  },
};
</script>
