<template>
  <main class="main">
    <div class="main__wrap">
      <div class="main__body">
        <div class="main__block">
          <div class="block">
            <div class="block__top">
              <div class="block-title">Личные данные</div>
            </div>
            <div class="block__main">
              <div class="row row--aife">
                <div class="col col--third">
                  <InputText label="Фамилия" :model-value="expert.surname" is-disabled />
                </div>
                <div class="col col--third">
                  <InputText label="Имя" :model-value="expert.name" is-disabled />
                </div>
                <div class="col col--third">
                  <InputText label="Отчество" :model-value="expert.patronymic" is-disabled />
                </div>
                <div class="col col--half">
                  <InputText
                    label="Реквизиты документа, удостоверяющего личность"
                    v-model="form.identity_document_details"
                    :has-error="vuelidate.form.identity_document_details.$error"
                    :vuelidate-errors="vuelidate.form.identity_document_details.$errors"
                  />
                </div>
                <div class="col col--fourth">
                  <InputText label="ИНН" :model-value="expert.inn" is-disabled />
                </div>
                <div class="col col--fourth">
                  <InputText label="СНИЛС" :model-value="expert.snils" is-disabled />
                </div>
                <div class="col col--half">
                  <CustomSelect
                    label="Федеральный округ"
                    placeholder="Выберите федеральный округ"
                    v-model="federalDistrictId"
                    :options="federalDistrictOptions"
                  />
                </div>
                <div class="col col--half">
                  <CustomSelect
                    label="Регион"
                    placeholder="Выберите регион"
                    v-model="form.region_id"
                    :options="regionOptions"
                    :has-error="vuelidate.form.region_id.$error"
                    :vuelidate-errors="vuelidate.form.region_id.$errors"
                  />
                </div>
                <div class="col col--full">
                  <InputText
                    label="Адрес места жительства"
                    v-model="form.address"
                    :has-error="vuelidate.form.address.$error"
                    :vuelidate-errors="vuelidate.form.address.$errors"
                  />
                </div>
                <div class="col col--full">
                  <InputText label="Адрес места пребывания" v-model="form.fact_address" />
                </div>
                <div class="col col--third">
                  <InputText label="Рабочий телефон" v-model="form.contacts.fax" />
                </div>
                <div class="col col--third">
                  <InputText label="Мобильный телефон" v-model="form.contacts.phone" />
                </div>
                <div class="col col--third">
                  <InputText label="Email" v-model="form.contacts.email" />
                </div>
                <div class="col col--full">
                  <div class="form-label">Ученые степени</div>
                  <div class="tbl-wrap">
                    <table class="tbl-list">
                      <thead>
                        <tr>
                          <th style="width: 70%">Ученая степень</th>
                          <th style="width: 25%">Год присуждения</th>
                          <th style="width: 5%"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="degree in expertDegrees" :key="degree.id">
                          <td>{{ degree.name }}</td>
                          <td>{{ degree.conferment_year ?? 'Не указан' }}</td>
                          <td class="align-center">
                            <button
                              class="link link--gray"
                              type="button"
                              @click="removeDegree(degree)"
                            >
                              <SvgIcon name="delete" :size="16" />
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <CustomButton
                    :label="modals.degrees.loading ? 'Добавляем' : 'Добавить'"
                    :disabled="modals.degrees.loading"
                    @click="modals.degrees.open = true"
                    :type="2"
                    icon="add"
                  />
                </div>
                <div class="col col--full">
                  <div class="form-label">Ученые звания</div>
                  <div class="tbl-wrap">
                    <table class="tbl-list">
                      <thead>
                        <tr>
                          <th style="width: 40%">Ученое звание</th>
                          <th style="width: 40%">Научная специальность</th>
                          <th style="width: 15%">Год присуждения</th>
                          <th style="width: 5%"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="rank in expertRanks" :key="rank.id">
                          <td>{{ rank.name }}</td>
                          <td>{{ rank.field_of_activity }}</td>
                          <td>{{ rank.conferment_year ?? 'Не указан' }}</td>
                          <td class="align-center">
                            <button class="link link--gray" type="button" @click="removeRank(rank)">
                              <SvgIcon name="delete" :size="16" />
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <CustomButton
                    :label="modals.ranks.loading ? 'Добавляем' : 'Добавить'"
                    :disabled="modals.ranks.loading"
                    @click="modals.ranks.open = true"
                    :type="2"
                    icon="add"
                  />
                </div>

                <div class="col col--full"></div>
                <div class="col col--third">
                  <SwitchInput
                    label="Доступ к гос. тайне"
                    v-model="form.is_gos_secret"
                    type="checkbox"
                    size="m"
                  />
                </div>
                <div class="col col--two-thirds">
                  <CustomSelect
                    v-if="form.is_gos_secret"
                    label="Уровень доступа"
                    placeholder="Укажите уровень доступа"
                    :options="accessLevelOptions"
                    v-model="form.level_access"
                  />
                </div>
              </div>
            </div>
            <div class="block__bottom">
              <div class="line line--jcsb">
                <div class="line__item"></div>
                <div class="line__item">
                  <CustomButton label="Сохранить" @click="submit" :disabled="formLoading" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="main__block">
          <div class="block">
            <div class="block__main">
              <div class="row row--aife">
                <div class="col col--full">
                  <div class="form-label">Сведения об образовании</div>
                  <div class="tbl-wrap">
                    <table class="tbl-list">
                      <thead>
                        <tr>
                          <th>Образовательные учреждения</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="education in educations" :key="education.id">
                          <td>{{ education.text }}</td>
                          <td class="align-center">
                            <button
                              class="link link--gray"
                              type="button"
                              @click="removeEducation(education)"
                            >
                              <SvgIcon name="delete" :size="16" />
                            </button>
                          </td>
                        </tr>
                        <tr v-if="educations && educations.length === 0">
                          <td colspan="2" class="align-center">Образования не найдены</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <CustomButton
                    :label="modals.educations.loading ? 'Добавляем' : 'Добавить'"
                    :disabled="modals.educations.loading"
                    @click="modals.educations.open = true"
                    :type="2"
                    icon="add"
                  />
                </div>
                <div class="col col--full">
                  <div class="form-label">Сведения о местах работы</div>
                  <div class="tbl-wrap">
                    <table class="tbl-list">
                      <thead>
                        <tr>
                          <th style="width: 50%">Название ВУЗа</th>
                          <th style="width: 15%">Период работы</th>
                          <th style="width: 10%"></th>
                          <th style="width: 5%"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="job in jobs" :key="job.id">
                          <td>{{ job.organization?.name ?? job.text }}</td>
                          <td>
                            {{ job.startedAt ? `c ${job.startedAt} ` : '' }}
                            {{ job.endedAt ? `по ${job.endedAt}` : 'по н.в.' }}
                          </td>
                          <td>
                            {{ job.isCurrent ? 'Основное' : '' }}
                            {{ job.isPrevious ? 'Предыдущее' : '' }}
                          </td>
                          <td class="align-center">
                            <button class="link link--gray" type="button" @click="removeJob(job)">
                              <SvgIcon name="delete" :size="16" />
                            </button>
                          </td>
                        </tr>
                        <tr v-if="jobs && jobs.length === 0">
                          <td colspan="2" class="align-center">Места работы не найдены</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <CustomButton
                    :label="modals.jobs.loading ? 'Добавляем' : 'Добавить'"
                    :disabled="modals.jobs.loading"
                    @click="modals.jobs.open = true"
                    :type="2"
                    icon="add"
                  />
                </div>

                <div class="col col--full">
                  <div class="form-label">Сертификаты ЭП</div>
                  <div class="tbl-wrap">
                    <table class="tbl-list">
                      <thead>
                        <tr>
                          <th>№</th>
                          <th>Серийный номер</th>
                          <th>Действует с</th>
                          <th>Действует по</th>
                          <th>Отозван</th>
                          <th>Статус</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(signature, index) in signatures" :key="signature.id">
                          <td>{{ index + 1 }}</td>
                          <td>{{ signature.SerialNumber }}</td>
                          <td>{{ $filters.formatDate(signature.DateBegin) }}</td>
                          <td>{{ $filters.formatDate(signature.DateEnd) }}</td>
                          <td>{{ $filters.formatDate(signature.DateRevoke) }}</td>
                          <td>{{ signature.status }}</td>
                          <td class="align-center">
                            <button
                              class="link link--gray"
                              type="button"
                              @click="removeSignature(signature)"
                            >
                              <SvgIcon name="delete" :size="16" />
                            </button>
                          </td>
                        </tr>
                        <tr v-if="signatures && signatures.length === 0">
                          <td colspan="7" class="align-center">Сертификаты не найдены</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <CustomButton
                    :label="modals.signatures.loading ? 'Добавляем' : 'Добавить'"
                    :disabled="modals.signatures.loading"
                    @click="modals.signatures.open = true"
                    :type="2"
                    icon="add"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
  <AddEducationModal
    :open="modals.educations.open"
    @close="modals.educations.open = false"
    @adding="
      modals.educations.open = false;
      modals.educations.loading = true;
    "
    @added="loadEducations().finally(() => (modals.educations.loading = false))"
  />
  <AddSignatureModal
    :open="modals.signatures.open"
    @close="modals.signatures.open = false"
    @adding="
      modals.signatures.open = false;
      modals.signatures.loading = true;
    "
    @added="loadSignatures().finally(() => (modals.signatures.loading = false))"
  />
  <AddRankModal
    :ranks="academicRanks"
    :open="modals.ranks.open"
    @close="modals.ranks.open = false"
    @adding="
      modals.ranks.open = false;
      modals.ranks.loading = true;
    "
    @failed="modals.ranks.loading = false"
    @added="loadRanks().finally(() => (modals.ranks.loading = false))"
  />
  <AddDegreeModal
    :degrees="academicDegrees"
    :open="modals.degrees.open"
    @close="modals.degrees.open = false"
    @failed="modals.degrees.loading = false"
    @adding="
      modals.degrees.open = false;
      modals.degrees.loading = true;
    "
    @added="loadDegrees().finally(() => (modals.degrees.loading = false))"
  />
  <AddJobModal
    :open="modals.jobs.open"
    @close="modals.jobs.open = false"
    @adding="
      modals.jobs.open = false;
      modals.jobs.loading = true;
    "
    @failed="modals.jobs.loading = false"
    @added="loadJobs().finally(() => (modals.jobs.loading = false))"
  />
</template>

<script>
import AddEducationModal from '@/components/modals/expert/AddEducationModal';
import CustomSelect from '@/components/CustomSelect';
import InputText from '@/components/InputText';
import SwitchInput from '@/components/SwitchInput';
import { ExpertEducationsRepository } from '@/repositories/expert/ExpertEducationsRepository';
import { ExpertSignaturesRepository } from '@/repositories/expert/ExpertSignaturesRepository';
import { AcademicDegreesRepository } from '@/repositories/AcademicDegreesRepository';
import { AcademicRanksRepository } from '@/repositories/AcademicRanksRepository';
import CustomButton from '@/components/CustomButton';
import SvgIcon from '@/components/SvgIcon';
import AddSignatureModal from '@/components/modals/expert/AddSignatureModal';
import useVuelidate from '@vuelidate/core';
import { required } from '@/utils/validators';
import { Expert } from '@/repositories';
import { FederalDistrictsRepository } from '@/repositories/FederalDistrictsRepository';
import { RegionsRepository } from '@/repositories/RegionsRepository';
import AddRankModal from '@/components/modals/expert/AddRankModal';
import AddDegreeModal from '@/components/modals/expert/AddDegreeModal';
import { ExpertAcademicDegreesRepository } from '@/repositories/expert/ExpertAcademicDegreesRepository';
import { ExpertAcademicRanksRepository } from '@/repositories/expert/ExpertAcademicRanksRepository';
import { ExpertJobsRepository } from '@/repositories/expert/ExpertJobsRepository';
import AddJobModal from '@/components/modals/expert/AddJobModal';

export default {
  name: 'CandidateExpertDetails',
  props: {
    expert: {
      type: Object,
      required: true,
    },
  },
  components: {
    AddJobModal,
    AddSignatureModal,
    AddRankModal,
    AddDegreeModal,
    SvgIcon,
    AddEducationModal,
    CustomButton,
    CustomSelect,
    InputText,
    SwitchInput,
  },
  setup() {
    return { vuelidate: useVuelidate() };
  },
  created() {
    this.loadEducations();
    this.loadSignatures();
    this.loadJobs();

    AcademicDegreesRepository.all().then((degrees) => (this.academicDegrees = degrees));
    AcademicRanksRepository.all().then((ranks) => (this.academicRanks = ranks));

    FederalDistrictsRepository.all().then((districts) => (this.federalDistricts = districts));
    if (this.federalDistrictId) {
      RegionsRepository.getByFederalDistrictId(this.federalDistrictId).then(
        (regions) => (this.regions = regions)
      );
    }
  },
  watch: {
    federalDistrictId(newId) {
      RegionsRepository.getByFederalDistrictId(newId).then((regions) => (this.regions = regions));
      this.form.region_id = null;
    },
  },
  data() {
    return {
      form: {
        identity_document_details: this.expert.identity_document_details,
        address: this.expert.address,
        fact_address: this.expert.actual_address,
        region_id: this.expert.region?.id,
        contacts: {
          fax: this.expert.contacts?.fax,
          phone: this.expert.contacts?.phoneNumber,
          email: this.expert.contacts?.email,
        },
        is_gos_secret: this.expert.hasAccessToStateSecret,
        level_access: this.expert.accessLevel,
      },
      formLoading: false,
      accessLevelOptions: [
        { value: 1, text: '1-ый уровень' },
        { value: 2, text: '2-ой уровень' },
        { value: 3, text: '3-ий уровень' },
      ],
      educations: null,
      signatures: null,
      jobs: null,
      academicDegrees: [],
      expertDegrees: this.expert.academic_degrees,
      academicRanks: [],
      expertRanks: this.expert.academic_ranks,
      federalDistricts: [],
      federalDistrictId: this.expert.region?.federal_district?.id,
      regions: [],
      modals: {
        educations: {
          open: false,
          loading: false,
        },
        signatures: {
          open: false,
          loading: false,
        },
        ranks: {
          open: false,
          loading: false,
        },
        degrees: {
          open: false,
          loading: false,
        },
        jobs: {
          open: false,
          loading: false,
        },
      },
    };
  },
  validations() {
    return {
      form: {
        identity_document_details: { required },
        address: { required },
        region_id: { required },
      },
    };
  },
  computed: {
    federalDistrictOptions() {
      return this.federalDistricts.map((district) => ({ value: district.id, text: district.name }));
    },
    regionOptions() {
      return this.regions.map((region) => ({ value: region.id, text: region.name }));
    },
  },
  methods: {
    async loadEducations() {
      this.educations = await ExpertEducationsRepository.getForAuthenticatedExpert();
    },
    async loadSignatures() {
      this.signatures = await ExpertSignaturesRepository.getForAuthenticatedExpert();
    },
    async loadRanks() {
      this.expertRanks = await Expert.getAcademicRanks();
    },
    async loadDegrees() {
      this.expertDegrees = await Expert.getAcademicDegrees();
    },
    async loadJobs() {
      this.jobs = await ExpertJobsRepository.getForAuthenticatedExpert();
    },
    async removeEducation(education) {
      if (!window.confirm('Вы уверены, что хотите удалить сведения об образовании?')) {
        return;
      }

      const index = this.educations.indexOf(education);
      this.educations.splice(index, 1);

      try {
        await ExpertEducationsRepository.remove(education.id);
      } catch (error) {
        window.alert('Не удалось удалить сведения об образовании. Попробуйте, пожалуйста, позже!');
        this.educations.splice(index, 0, education);
        throw error;
      }
    },
    async removeSignature(signature) {
      if (!window.confirm('Вы уверены, что хотите удалить сертификат ЭП?')) {
        return;
      }

      const index = this.signatures.indexOf(signature);
      this.signatures.splice(index, 1);

      try {
        await ExpertSignaturesRepository.remove(signature.id);
      } catch (error) {
        window.alert('Не удалось удалить сертификат ЭП. Попробуйте, пожалуйста, позже!');
        this.signatures.splice(index, 0, signature);
        throw error;
      }
    },
    async removeDegree(degree) {
      if (!window.confirm('Вы уверены, что хотите удалить ученую степень?')) {
        return;
      }

      const index = this.expertDegrees.indexOf(degree);
      this.expertDegrees.splice(index, 1);

      try {
        await ExpertAcademicDegreesRepository.removeForAuthenticatedExpert(degree.id);
      } catch (error) {
        window.alert('Не удалось удалить ученую степень. Попробуйте, пожалуйста, позже!');
        this.expertDegrees.splice(index, 0, degree);
        throw error;
      }
    },
    async removeRank(rank) {
      if (!window.confirm('Вы уверены, что хотите удалить ученое звание?')) {
        return;
      }

      const index = this.expertRanks.indexOf(rank);
      this.expertRanks.splice(index, 1);

      try {
        await ExpertAcademicRanksRepository.removeForAuthenticatedExpert(rank.id);
      } catch (error) {
        window.alert('Не удалось удалить ученую степень. Попробуйте, пожалуйста, позже!');
        this.expertRanks.splice(index, 0, rank);
        throw error;
      }
    },
    async submit() {
      this.vuelidate.form.$touch();
      if (this.vuelidate.form.$invalid) {
        return;
      }

      if (!window.confirm('Вы уверены, что хотите сохранить данные?')) {
        return;
      }

      this.formLoading = true;

      try {
        await Expert.update(this.form);
      } catch (error) {
        window.alert('Не удалось сохранить данные. Попробуйте, пожалуйста, позже!');
        throw error;
      } finally {
        this.formLoading = false;
      }
    },

    async removeJob(job) {
      if (!window.confirm('Вы уверены, что хотите удалить место работы?')) {
        return;
      }

      const index = this.jobs.indexOf(job);
      this.jobs.splice(index, 1);

      try {
        await ExpertJobsRepository.removeForAuthenticatedExpert(job.id);
      } catch (error) {
        window.alert('Не удалось удалить место работы. Попробуйте, пожалуйста, позже!');
        this.jobs.splice(index, 0, job);
        throw error;
      }
    },
  },
};
</script>
