import axios from '@/api/axios';
import { ValidationError } from '@/exceptions';

export const Request = {
  async all() {
    const response = await axios.get('user/requests');
    return response.data.data;
  },
  async get(requestId) {
    const response = await axios.get(`user/requests/${requestId}`);
    return response.data.data;
  },
  async programs(requestId, branchId, typeId) {
    const response = await axios.get(
      `user/requests/${requestId}/programs?branch=${branchId}&type=${typeId}`
    );
    return response.data.data;
  },
  /**
   * @param {string|number} requestId
   * @param {FormData} form
   * @return {Promise<void>}
   */
  async addDocument(requestId, form) {
    try {
      await axios.post(`user/requests/${requestId}/documents`, form);
    } catch (error) {
      if (error.response?.status !== 422) {
        throw error;
      }
      throw new ValidationError(error.response.data.errors);
    }
  },
  async fulfill(requestId) {
    try {
      await axios.patch(
        `user/requests/${requestId}/fulfill`
      );
    } catch (error) {
      if (error.response?.status !== 422) {
        throw error;
      }
      throw new ValidationError(error.response.data.errors);
    }
  },
  /** @return {Promise<*>} */
  async requestList() {
    const response =  await axios.get(`user/request`);
    return response.data;
  },

  async eduPrograms(applicationId) {
    const response =  await axios.get(`user/request/${applicationId}`);
    return response.data;
  },

  async saveUgForm(applicationId, ugId, form) {
    const response =  await axios.post(`user/request/${applicationId}/ug/${ugId}`, form);
    return response.data;
  },
  async getUgForm(applicationId, ugId) {
    const response =  await axios.get(`user/request/${applicationId}/ug/${ugId}`);
    return response.data;
  },
};

