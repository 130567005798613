<template>
  <div class="wrap wrap--side" :class="{ collapsed }">
    <navigation-sidebar :links="links" />
    <router-view />
  </div>
</template>

<script>
import { educationalOrganization } from '@/repositories';
import NavigationSidebar from '@/components/NavigationSidebar';
export default {
  name: 'MainPageLayout',
  components: { NavigationSidebar },
  created() {
    educationalOrganization.getBranchesCount().then((count) => (this.branchesCount = count));
  },
  data() {
    return {
      branchesCount: null,
    };
  },
  computed: {
    links() {
      return [{ label: 'Общие сведения', to: 'external.home', icon: 'general-info' }];
    },
    collapsed() {
      return this.$store.state.sidebar.collapsed;
    },
  },
};
</script>
