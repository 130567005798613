<template>
  <GenericModal @close="$emit('close')" :opened="open">
    <template v-slot:header>
      <div class="modal-box__title">Добавление сертификата ЭП</div>
      <button @click="$emit('close')" class="link link--gray modal-close" type="button">
        <SvgIcon name="cross" :size="24" />
      </button>
    </template>
    <template v-slot:body>
      <div class="modal-box__block">
        <div class="block">
          <div class="block__main">
            <div class="row row--aife">
              <div class="col col--full">
                <InputText
                  label="Серийный номер"
                  v-model="form.serialNumber"
                />
              </div>
              <div class="col col--half">
                <InputText label="Действует с" v-model="form.validFrom" is-date />
              </div>
              <div class="col col--half">
                <InputText label="Действует по" v-model="form.validUntil" is-date />
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="line line--jcfe">
        <div class="line__item">
          <CustomButton label="Добавить" @click="add" />
        </div>
      </div>
    </template>
  </GenericModal>
</template>

<script>
import CustomButton from '@/components/CustomButton';
import GenericModal from '@/components/modals/GenericModal';
import SvgIcon from '@/components/SvgIcon';
import InputText from '@/components/InputText';
import useVuelidate from '@vuelidate/core';
import { required } from '@/utils/validators';
import { ExpertSignaturesRepository } from '@/repositories/expert/ExpertSignaturesRepository';

export default {
  name: 'AddSignatureModal',
  props: {
    open: Boolean,
  },
  emits: ['closed', 'adding', 'added'],
  components: {
    CustomButton,
    GenericModal,
    SvgIcon,
    InputText,
  },
  setup() {
    return { vuelidate: useVuelidate() };
  },
  data() {
    return {
      form: {
        serialNumber: null,
        validFrom: null,
        validUntil: null,
      },
    };
  },
  validations() {
    return {
      form: {
        serialNumber: { required },
        validFrom: { required },
        validUntil: { required },
      },
    };
  },
  methods: {
    async add() {
      this.vuelidate.form.$touch();
      if (this.vuelidate.form.$invalid) {
        return;
      }

      this.$emit('adding');

      try {
        await ExpertSignaturesRepository.addForAuthenticatedExpert({
          serial_number: this.form.serialNumber,
          valid_from: this.form.validFrom?.toISOString(),
          valid_until: this.form.validUntil?.toISOString(),
        });
      } catch (error) {
        window.alert(
          'Не удалось добавить сведения о сертификате ЭП. Попробуйте, пожалуйста, позже!'
        );
        throw error;
      }

      this.$emit('added');
      this.resetForm();
    },
    resetForm() {
      this.form = {
        serialNumber: null,
        validFrom: null,
        validUntil: null,
      };
    },
  },
};
</script>
