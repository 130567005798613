<template>
  <div class="block">
    <div class="block__top">
      <div class="block-title">Контактные данные</div>
    </div>

    <div class="block__main">
      <div class="row row--aife">
        <div class="col col--full">
          <input-text label="Телефон(ы)" :model-value="phoneNumbers" is-disabled />
        </div>
        <div class="col col--full">
          <input-text label="Факс(ы)" :model-value="faxes" is-disabled />
        </div>
        <div class="col col--full">
          <input-text label="E-mail" :model-value="emails" is-disabled />
        </div>
        <div class="col col--full">
          <input-text input-type="url" label="Веб-сайт" :model-value="site" is-disabled />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputText from '@/components/InputText';
export default {
  name: 'ContactsBlock',
  components: { InputText },
  props: {
    phoneNumbers: String,
    faxes: String,
    emails: String,
    site: String,
  },
};
</script>
