<template>
  <main class="main">
    <div class="main__wrap">
      <div class="main__header">
        <div class="title">{{ selectedBranch.fullName }}</div>
      </div>

      <div class="main__body">
        <div class="main__block">
          <div class="block">
            <div class="block__main">
              <div class="row row--aife">
                <div class="col col--full">
                  <custom-select
                    label="Филиал"
                    :options="selectOptions"
                    v-model="selectedIndex"
                    placeholder="Выберите"
                  />
                </div>
                <div class="col col--full">
                  <text-area
                    label="Полное наименование образовательной организации"
                    :model-value="selectedBranch.fullName"
                    is-disabled
                  />
                </div>
                <div class="col col--full">
                  <text-area
                    label="Сокращенное наименование образовательной организации"
                    :model-value="selectedBranch.shortName"
                    is-disabled
                  />
                </div>
                <div class="col col--full">
                  <input-text
                    label="Вид организационно-правовой формы"
                    :model-value="selectedBranch.legalForm"
                    is-disabled
                  />
                </div>
                <div class="col col--full">
                  <text-area
                    label="Учредитель"
                    :model-value="getFounderNames(selectedBranch.founders)"
                    is-disabled
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="main__block">
          <location-block v-bind="selectedBranch.address" />
        </div>

        <div class="main__block">
          <locations-block :locations="selectedBranch.locations" />
        </div>

        <div class="main__block">
          <organization-details :tax-registration="selectedBranch.taxRegistration" />
        </div>

        <div class="main__block">
          <contacts-block v-bind="selectedBranch.contacts" />
        </div>

        <div class="main__block">
          <organization-head v-bind="selectedBranch.head" :phone-number="selectedBranch.head?.phoneNumbers" />
        </div>
      </div>

      <div class="main__footer main__footer--sticky">
        <div class="line line--jcsb">
          <div class="line__item">
            <custom-button label="Сохранить" />
          </div>
          <div class="line__item">
            <report-error-button />
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { educationalOrganization } from '@/repositories';
import CustomSelect from '@/components/CustomSelect';
import TextArea from '@/components/TextArea';
import CustomButton from '@/components/CustomButton';
import InputText from '@/components/InputText';
import OrganizationDetails from '@/components/blocks/OrganizationDetails';
import ContactsBlock from '@/components/blocks/ContactsBlock';
import OrganizationHead from '@/components/blocks/OrganizationHead';
import LocationBlock from '@/components/blocks/LocationBlock';
import LocationsBlock from '@/components/blocks/LocationsBlock';
import ReportErrorButton from '@/components/ReportErrorButton';
export default {
  name: 'Branches',
  components: {
    CustomSelect,
    TextArea,
    CustomButton,
    InputText,
    OrganizationDetails,
    ContactsBlock,
    OrganizationHead,
    LocationBlock,
    LocationsBlock,
    ReportErrorButton,
  },
  created() {
    educationalOrganization
      .getBranches()
      .then((branches) => branches.map(this.removeInnFromBranch))
      .then((branches) => (this.branches = branches));
  },
  data() {
    return {
      branches: [],
      selectedIndex: 0,
    };
  },
  computed: {
    selectOptions() {
      return this.branches.map((branch, index) => ({
        value: index,
        text: branch.fullName,
      }));
    },
    selectedBranch() {
      return this.branches[this.selectedIndex] || {};
    },
  },
  methods: {
    removeInnFromBranch(branch) {
      delete branch.taxRegistration.inn;
      return branch;
    },
    getFounderNames(founders) {
      return founders ? founders.map((founder) => founder.name).join(', ') : '';
    },
  },
};
</script>
