<template>
  <div class="main-wrap">
    <div class="header">
      <div class="header__wrap">
        <div class="header__logo">
          <div class="logo">
            <img
              src="~@/assets/images/logo-visual.svg"
              width="42"
              height="36"
              alt="Логотип ИС ГА"
              class="logo__img"
            />
            <img
              src="~@/assets/images/logo-text.svg"
              width="81"
              height="19"
              alt="ИС ГА"
              class="logo__text"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="wrap">
      <main class="main">
        <div class="main__wrap main__wrap--single">
          <div class="main__header">
            <div class="main__title">
              <div class="title">{{ expert.full_name }}</div>
            </div>
          </div>
          <div class="main__body">
            <div class="main__block">
              <div class="block">
                <div class="row row--aife">
                  <template v-if="expert.is_organization">
                    <div class="col col--full">
                      <InputText
                        label="Сокращенное наименование организации"
                        :model-value="expert.short"
                        is-disabled
                      />
                    </div>
                    <div class="col col--full">
                      <InputText label="ОГРН" :model-value="expert.ogrn" is-disabled />
                    </div>
                  </template>

                  <div class="col col--full">
                    <div class="form-label">Распорядительные акты</div>
                    <div class="tbl-wrap">
                      <table class="tbl-list">
                        <thead>
                          <tr>
                            <th>Номер распоряжения</th>
                            <th>Дата распоряжения</th>
                            <th>Окончание полномочий</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="certificate in expert.certificates" :key="certificate.id">
                            <td>{{ certificate.doc_number }}</td>
                            <td>{{ certificate.doc_date }}</td>
                            <td>{{ certificate.date_end }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div class="col col--full">
                    <div class="form-label">Текущие полномочия</div>
                    <div class="tbl-wrap">
                      <table class="tbl-list">
                        <thead>
                          <tr>
                            <th>Код</th>
                            <th>УГСН / профессия, специальность, направление подготовки</th>
                            <th>Уровень образования</th>
                            <th>Срок полномочий</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="program in expert.programs" :key="program.id">
                            <td>{{ program.egs?.Code ?? program.speciality?.Code }}</td>
                            <td>{{ program.egs?.Name ?? program.speciality?.Name }}</td>
                            <td>{{ program.educationLevel?.Name }}</td>
                            <td>{{ program.certificate?.date_end ?? 'Бессрочно' }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="main__footer main__footer--sticky">
            <div class="line line--jcsb">
              <div class="line__item line__item--mla">
                <CustomButton label="Скачать реестровую выписку" @click="downloadRegistryExtract" />
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script setup>
import axios from '@/api/axios';
import { ref, watch } from 'vue';
import InputText from '@/components/InputText';
import CustomButton from '@/components/CustomButton.vue';
import { downloadFile } from '@/utils/helpers/downloadFile';

// eslint-disable-next-line no-undef
const props = defineProps({
  id: {
    type: String,
    required: true,
  },
});

const expert = ref({});

fetchExpert();
watch(props.id, fetchExpert);

async function fetchExpert() {
  try {
    const response = await axios.get(`experts/${props.id}`);
    expert.value = response.data.data;
  } catch (error) {
    window.alert('Не удалось получить данные об эксперте. Попробуйте, пожалуйста, позже!');
    throw error;
  }
}

async function downloadRegistryExtract() {
  try {
    const response = await axios.get(`experts/${props.id}/extract`, {
      responseType: 'blob',
    });
    downloadFile(response.data, 'Реестровая выписка');
  } catch (error) {
    window.alert(
      'Не удалось скачать реестровую выписку по эксперту. Попробуйте, пожалуйста, позже!'
    );
    throw error;
  }
}
</script>
