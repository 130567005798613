/**
 * Убирает последнее расширение файла из пути к файлу, если оно есть.
 * @param {string|null} path - Путь к файлу
 * @param {string|null} extension - Расширение, которое нужно убрать, если оно есть.
 * @returns {string|null} Путь без последнего расширения
 */
export function removeExtension(path, extension = null) {
  if (path == null) {
    return path;
  }

  if (extension) {
    if (path.endsWith(extension)) {
      return path.slice(0, -(extension.length + 1));
    }

    return path;
  }

  return path.replace(/\.[^/.]+$/, '');
}