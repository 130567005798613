<template>
  <div class="wrap">
    <main class="main">
      <div class="main__wrap main__wrap--single">
        <div class="main__header">
          <div class="main__title">
            <div class="title">
              Заявление об аккредитации
              <span v-if="application.id"> № {{ application.id }}</span>
            </div>
          </div>
        </div>

        <div class="main__tabs">
          <nav-tabs :links="tabs" />
        </div>

        <router-view />
      </div>
    </main>
  </div>
</template>

<script>
import NavTabs from '@/components/NavTabs';
import { ExpertApplicationsRepository } from '@/repositories/expert/ExpertApplicationsRepository';

export default {
  name: 'ApplicationLayout',
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  components: {
    NavTabs,
  },
  async created() {
    this.application = await ExpertApplicationsRepository.find(this.id);
  },
  data() {
    return { application: {} };
  },
  computed: {
    tabs() {
      return [
        {
          label: 'Общие сведения',
          to: { name: 'expert.applications.show.details' },
        },
        {
          label: 'УГСН',
          to: { name: 'expert.applications.show.programs' },
        },
        {
          label: 'Документы',
          to: { name: 'expert.applications.show.documents' },
        },
      ];
    },
  },
};
</script>
