<template>
  <div class="main__body">
    <div class="main__block">
      <div class="block">
        <div class="block__main">
          <div class="row row--aife">
            <div v-for="type in documentTypes" :key="type.id" class="col col--full">
              <expert-application-document-uploader
                v-if="editable"
                :application-id="id"
                :document-type="type"
                @uploaded="loadDocuments"
              />
              <div v-else class="form-label">{{ type.name }}</div>
              <div class="files">
                <downloadable-file
                  v-for="document in documentsByTypeId[type.id]"
                  :key="document.id"
                  :name="document.name"
                  :link="document.path"
                  :deletable="editable"
                  @delete="deleteDocument(document)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="editable" class="main__footer main__footer--sticky">
    <div class="line">
      <div class="line__item line__item--mra">
        <CustomButton label="Печать макета" @click="downloadApplicationTemplate" />
      </div>
      <div class="line__item">
        <custom-button label="Отправить" @click="submitApplication" />
      </div>
    </div>
  </div>
</template>

<script>
import CustomButton from '@/components/CustomButton';
import DownloadableFile from '@/components/DownloadableFile';
import { ExpertApplicationsRepository } from '@/repositories/expert/ExpertApplicationsRepository';
import { ExpertApplicationDocumentsRepository } from '@/repositories/expert/ExpertApplicationDocumentsRepository';
import { ExpertApplicationDocumentTypesRepository } from '@/repositories/expert/ExpertApplicationDocumentTypesRepository';
import { groupBy } from '@/utils/helpers';
import ExpertApplicationDocumentUploader from '@/components/ExpertApplicationDocumentUploader';
import { ExpertApplicationService } from '@/services/expert/ExpertApplicationService';
import { downloadFile } from '@/utils/helpers/downloadFile';

export default {
  name: 'ApplicationDocuments',
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  components: {
    CustomButton,
    ExpertApplicationDocumentUploader,
    DownloadableFile,
  },
  created() {
    this.loadResources();
  },
  watch: {
    id() {
      this.loadResources();
    },
  },
  data() {
    return {
      application: null,
      documents: [],
      documentTypes: [],
    };
  },
  computed: {
    editable() {
      return [1, 3].includes(this.application?.status?.id);
    },
    documentsByTypeId() {
      return groupBy(this.documents, 'document_type_id');
    },
  },
  methods: {
    loadResources() {
      ExpertApplicationsRepository.find(this.id).then(
        (application) => (this.application = application)
      );

      this.loadDocuments();

      ExpertApplicationDocumentTypesRepository.getByApplicationId(this.id).then(
        (documentTypes) => (this.documentTypes = documentTypes)
      );
    },
    async loadDocuments() {
      this.documents = await ExpertApplicationDocumentsRepository.getByApplicationId(this.id);
    },
    async deleteDocument(document) {
      const index = this.documents.indexOf(document);
      this.documents.splice(index, 1);
      try {
        await ExpertApplicationDocumentsRepository.remove(document.id);
      } catch (error) {
        window.alert('Не удалось удалить документ. Попробуйте, пожалуйста, позже!');
        this.documents.splice(index, 0, document);
      }
    },
    async submitApplication() {
      try {
        await ExpertApplicationService.submit(this.id);
        this.$router.push({ name: 'expert.applications.index' });
      } catch (error) {
        window.alert('Не удалось отправить заявление. Попробуйте, пожалуйста, позже!');
        throw error;
      }
    },
    async downloadApplicationTemplate() {
      try {
        const template = await ExpertApplicationService.getTemplate(this.id);
        downloadFile(template, template.name);
      } catch (error) {
        window.alert('Не удалось загрузить макет заявления. Попробуйте, пожалуйста, позже!');
        console.error(error);
      }
    },
  },
};
</script>
