import axios from '@/api/axios';

export const EducationLevelsRepository = {
  /**
   * @returns {Promise<EducationLevel[]>}
   */
  async allForExpert() {
    const response = await axios.get('/education_levels/expert');
    return response.data.data;
  },
};

/**
 * @typedef EducationLevel
 * @property {string} id
 * @property {string|null} name
 * @property {string|null} short_name
 * @property {string|null} code
 */
