<template>
  <main class="main">
    <div class="main__wrap">
      <div class="main__body">
        <div class="main__block">
          <div class="block">
            <div class="block__top">
              <div class="block-title">Ваши заявления</div>
            </div>

            <div class="block__main">
              <div class="tbl-wrap">
                <table class="tbl">
                  <thead>
                    <tr>
                      <th style="width: 30%">Дата заявления</th>
                      <th style="width: 30%">Номер заявления</th>
                      <th style="width: 35%">Статус</th>
                      <th style="width: 5%"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-if="applications && !applications.length">
                      <td colspan="4" class="align-center">Заявления не найдены</td>
                    </tr>
                    <tr
                      v-else
                      v-for="application in applications"
                      :key="application.id"
                      @click="
                        $router.push({
                          name: 'expert.applications.show.details',
                          params: { id: application.id },
                        })
                      "
                    >
                      <td>{{ $filters.formatDate(application.created_at) }}</td>
                      <td>{{ application.id }}</td>
                      <td>{{ application.status.name }}</td>
                      <td class="align-center">
                        <button
                          v-if="isDeletable(application)"
                          @click.stop="handleDeleteApplicationClick(application)"
                          class="link link--gray"
                          type="button"
                        >
                          <SvgIcon name="delete" :size="16" />
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="main__footer main__footer--sticky">
        <div class="line line--jcsb">
          <div class="line__item"></div>
          <div class="line__item">
            <CustomButton
              :label="creating ? 'Создаем' : 'Создать'"
              @click="createApplication"
              :disabled="creating"
            />
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import CustomButton from '@/components/CustomButton';
import { Expert } from '@/repositories';
import SvgIcon from '@/components/SvgIcon.vue';
import { STATUS_ID } from '@/constants/expert/applications/status';
import { ExpertApplicationsRepository } from '@/repositories/expert/ExpertApplicationsRepository';
export default {
  name: 'Application',
  components: {
    SvgIcon,
    CustomButton,
  },
  columns: ['Дата заявления', 'Номер заявления', 'Статус'],
  async created() {
    this.applications = await Expert.getApplications();
  },
  data() {
    return {
      applications: null,
      creating: false,
    };
  },
  methods: {
    async createApplication() {
      this.creating = true;

      try {
        await Expert.createApplication();
      } catch (error) {
        window.alert('Не удалось создать заявление. Попробуйте, пожалуйста, позже!');
        throw error;
      } finally {
        this.creating = false;
      }

      this.applications = await Expert.getApplications();
    },
    isDeletable(application) {
      return application.status?.id === STATUS_ID.DRAFT;
    },
    async handleDeleteApplicationClick(application) {
      if (!window.confirm('Вы уверены, что хотите удалить заявление?')) {
        return;
      }

      const applicationIndex = this.applications?.indexOf(application);
      if (applicationIndex !== -1) {
        this.applications?.splice(applicationIndex, 1);
      }

      try {
        await ExpertApplicationsRepository.remove(application);
      } catch (e) {
        console.error(e);
        window.alert('Не удалось удалить заявление. Попробуйте, пожалуйста, позже!');
        if (applicationIndex !== -1) {
          this.applications?.splice(applicationIndex, 0, application);
        }
      }
    },
  },
};
</script>
