<template>
  <generic-modal :opened="opened" @close="$emit('close')">
    <template v-slot:header>
      <div class="modal-box__title">Добавление доп. документа</div>
      <button @click="$emit('close')" class="link link--gray modal-close" type="button">
        <svg-icon name="cross" :size="24" />
      </button>
    </template>

    <template v-slot:body>
      <div class="modal-box__block">
        <div class="block">
          <div class="block__main">
            <div class="row row--aife">
              <div class="col col--full">
                <custom-select
                  label="Тип документа"
                  placeholder="Выберите тип документа"
                  :options="types"
                  v-model="form.documentType"
                  @change="vuelidate.form.documentType.$touch"
                  :has-error="vuelidate.form.documentType.$error"
                  :vuelidate-errors="vuelidate.form.documentType.$errors"
                />
              </div>
              <div class="col col--full">
                <input-text
                  label="Дата загрузки"
                  v-model="form.uploadedAt"
                  is-date
                  button="calendar"
                  @change="vuelidate.form.uploadedAt.$touch"
                  :has-error="vuelidate.form.uploadedAt.$error"
                  :vuelidate-errors="vuelidate.form.uploadedAt.$errors"
                />
              </div>
              <div class="col col--full">
                <text-area label="Примечание" v-model="form.comment" />
              </div>
              <div class="col col--full">
                <file-attach
                  label="Файл"
                  v-model="form.file"
                  @update:model-value="vuelidate.form.file.$touch"
                  :has-error="vuelidate.form.file.$error"
                  :error-text="vuelidate.form.file.$errors[0]?.$message"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template v-slot:footer>
      <div class="line line--jcfe">
        <div class="line__item">
          <custom-button
            form="report-error-form"
            button-type="submit"
            label="Отправить"
            @click="submit"
          />
        </div>
      </div>
    </template>
  </generic-modal>
</template>

<script>
import GenericModal from '@/components/modals/GenericModal';
import SvgIcon from '@/components/SvgIcon';
import CustomSelect from '@/components/CustomSelect';
import TextArea from '@/components/TextArea';
import InputText from '@/components/InputText';
import CustomButton from '@/components/CustomButton';
import FileAttach from '@/components/FileAttach';
import { Expertises } from '@/repositories';
import useVuelidate from '@vuelidate/core';
import { required } from '@/utils/validators';
import { ValidationError } from '@/exceptions';
export default {
  name: 'CreateExpertiseDocument',
  components: {
    GenericModal,
    SvgIcon,
    CustomSelect,
    TextArea,
    InputText,
    CustomButton,
    FileAttach,
  },
  created() {
    Expertises.getDocumentTypes().then((types) => {
      this.types = types.map((type) => ({ value: type.id, text: type.name }));
    });
  },
  setup() {
    return { vuelidate: useVuelidate() };
  },
  data() {
    return {
      form: {
        documentType: null,
        uploadedAt: new Date(),
        comment: null,
        file: null,
      },
      vuelidateExternalResults: {form: {}},
      types: [],
    };
  },
  validations() {
    return {
      form: {
        documentType: { required },
        uploadedAt: { required },
        file: { required },
      },
    };
  },
  methods: {
    submit() {
      this.vuelidateExternalResults.form = {};
      this.vuelidate.form.$touch();
      if (this.vuelidate.form.$invalid) {
        return;
      }

      Expertises.addDocument(this.expertiseId, this.getFormData())
        .then(() => this.$emit('created'))
        .then(() => this.$emit('close'))
        .then(this.resetForm)
        .catch(this.handleSubmissionError);
    },
    handleSubmissionError(error) {
      if (!(error instanceof ValidationError)) {
        throw error;
      }

      this.vuelidateExternalResults.form = error.errors;
    },
    /* Хак для получения FormData для отправки файла */
    getFormData() {
      const formData = new FormData();
      for (const field in this.form) {
        let fieldData = this.form[field];
        if (fieldData == null) {
          continue;
        }
        if (fieldData instanceof Date) {
          fieldData = fieldData.toISOString();
        }
        formData.append(field, fieldData);
      }
      return formData;
    },
    resetForm() {
      this.form = {
        documentType: null,
        uploadedAt: new Date(),
        comment: null,
        file: null,
      };

      this.vuelidate.form.$reset();
    }
  },
  props: {
    opened: Boolean,
    expertiseId: {
      type: String,
      required: true,
    },
  },
  emits: ['close', 'created'],
};
</script>
