<template>
  <div class="wrap wrap--side" :class="{ collapsed }">
    <aside class="side" :class="{ collapsed }">
      <div class="side__wrap">
        <div class="side__main">
          <div class="side-nav">
            <ul class="side-nav__list">
              <li class="side-nav__item" v-for="item in marks" :key="item.id">
                <a
                  href="#"
                  class="side-link"
                  :class="{
                    'side-link--active': item.id === activeSideLinkId,
                  }"
                  @click.prevent="changeMarkData(item)"
                >
                  <span class="side-link__icons">
                    <svg-clr name="item" :size="24" />
                    <svg-clr name="item-on" :size="24" />
                  </span>
                  <span class="side-link__label">Показатель {{ item.mark.number }}</span>
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div class="side__bottom">
          <button @click="toggle" class="side-btn" type="button" :class="{ collapsed }">
            <svg-clr name="roll-up" :size="24" />
            <svg-clr name="roll-up-on" :size="24" />
          </button>
        </div>
      </div>
    </aside>

    <main class="main">
      <div class="main__wrap main__wrap--sm">
        <div class="main__over-top">
          <custom-button
            type="4"
            label="Вернуться в основной раздел"
            icon="arrow-left"
            @click="redirectToTaskPrograms(program.task_id, program.level_id)"
          />
          <div v-if="isProgramFinished" class="notice notice--info notice--s">
            <div class="notice__icon">
              <svg-icon name="check-filled" :size="24" />
            </div>
            <div class="notice__text">
              Ваши данные успешно переданы в Рособрнадзор! Редактировать ничего больше нельзя.
            </div>
          </div>
        </div>
        <div v-if="program" class="main__top">
          <div class="main__title">
            <div class="title">
              {{ program.licensed_program_code }} {{ program.licensed_program_name }}
            </div>
            <div class="subtitle">{{ program.edu_program_name }}</div>
          </div>
        </div>
        <div class="main__body">
          <div v-if="activeMark" class="main__block">
            <div class="block">
              <div class="block__top">
                <div class="title title--sub">
                  {{ activeMark.mark.number }}. {{ activeMark.mark.name }}
                </div>
              </div>

              <div class="block__main">
                <div class="list list--sm">
                  <div class="list__item">
                    <div class="i-item i-item--primary">
                      <svg-icon name="calendar" size="24" />

                      <span class="i-item__label"
                        >Отчетный период: {{ activeMark.mark.report_period_year }}
                      </span>
                    </div>
                  </div>
                  <!--            <div class="list__item" v-if="activeMark.mark.description">
                    <div class="notice notice--info notice--s">
                      <div class="notice__icon">
                        <svg-icon name="info" size="24" />
                      </div>
                      <div class="notice__text">
                        {{ activeMark.mark.description }}
                      </div>
                    </div>
                  </div> -->
                  <div
                    v-if="
                      isIndicatorPreloadable(levelId, activeMark.mark_id) && activeMark.is_preloaded
                    "
                    class="list__item"
                  >
                    <input-text
                      :model-value="activeMark.result"
                      label="Результат показателя был предварительно загружен. Изменить значение в этом поле невозможно."
                      grid
                      is-disabled
                    />
                  </div>
                  <div v-else v-for="item in markData" :key="item.id" class="list__item">
                    <div class="form-group">
                      <switch-input
                        v-if="item?.type === 'checkbox'"
                        :label="item.value"
                        type="checkbox"
                        size="m"
                        v-model="form[item.data_id].checkbox_values_ids"
                        :value="item.id"
                        :disabled="
                          isTaskFinished ||
                          isProgramFinished ||
                          isCheckboxLinkFilled(form[item.data_id].resourse_links[item.id]) ||
                          isDataCheckboxAndPrefilled ||
                          preloadedCheckboxesIds.includes(item.id)
                        "
                      />
                    </div>

                    <div v-if="item?.type === 'checkbox'" class="form-group">
                      <input-text
                        v-if="item?.type === 'checkbox'"
                        color="red"
                        :placeholder="
                          isCheckboxAlreadyChecked(form[item.data_id].checkbox_values_ids, item.id)
                            ? 'Заполните данное поле для сохранения значения'
                            : 'Ссылка на ресурс, подтверждающий критерий'
                        "
                        v-model="form[item.data_id].resourse_links[item.id]"
                        :isDisabled="
                          isTaskFinished ||
                          isProgramFinished ||
                          isDataCheckboxAndPrefilled ||
                          preloadedCheckboxesIds.includes(item.id)
                        "
                        @update:modelValue="
                          updateCheckboxesLinks(
                            form[item.data_id].resourse_links[item.id],
                            form[item.data_id].checkbox_values_ids,
                            item.id
                          )
                        "
                      />
                    </div>

                    <input-text
                      v-else-if="
                        item.data.values[0]?.type !== 'checkbox' &&
                        item.data.values[0]?.type !== 'radio'
                      "
                      inputType="number"
                      v-model="form[item.data_id].score"
                      :max="item.data.values[0]?.value"
                      :label="item.data.name"
                      :grid="true"
                      :hasError="errors[item.data_id] !== ''"
                      :errorText="errors[item.data_id]"
                      :isDisabled="isTaskFinished || isProgramFinished"
                      @update:modelValue="resetNumberInputError(item.data_id)"
                    />

                    <div class="group" v-else-if="item.data.values[0]?.type === 'radio'">
                      <div class="label">{{ item.data.name }}</div>
                      <div class="line">
                        <div class="line__item">
                          <label class="switch switch--m radio radio--m">
                            <input
                              type="radio"
                              class="switch__input"
                              name="participation"
                              v-model="form[item.data_id].radio_value_id"
                              :value="item.data.values[0].id"
                              :disabled="isTaskFinished || isProgramFinished"
                            />
                            <span class="switch__cover radio__cover"> </span>
                            <div class="switch__label radio__label">
                              {{ item.data.values[0].value }}
                            </div>
                          </label>
                        </div>
                        <div class="line__item">
                          <label class="switch switch--m radio radio--m">
                            <input
                              type="radio"
                              class="switch__input"
                              name="participation"
                              v-model="form[item.data_id].radio_value_id"
                              :value="item.data.values[1].id"
                              :disabled="isTaskFinished || isProgramFinished"
                            />
                            <span class="switch__cover radio__cover"> </span>
                            <div class="switch__label radio__label">
                              {{ item.data.values[1].value }}
                            </div>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="block__bottom">
                <div class="line">
                  <div class="line__item">
                    <custom-button
                      label="Назад"
                      type="2"
                      @click="toggleMarkData(marks[marks.indexOf(activeMark) - 1])"
                      :disabled="isAnyResourceLinkNotFilled || marks[0] === activeMark"
                    />
                  </div>
                  <div class="line__item line__item--mla">
                    <custom-button
                      :label="isMarkDataLast ? 'Сохранить и завершить' : 'Сохранить и далее'"
                      @click="toggleMarkData(marks[marks.indexOf(activeMark) + 1])"
                      :disabled="
                        isErrosInMarkDataScore ||
                        isAnyResourceLinkNotFilled ||
                        (isMarkDataLast && isProgramFinished || isTaskFinished)
                      "
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="activeMark" class="main__block">
            <div class="tabs-wrap">
              <div class="d-tabs d-tabs--base">
                <ul class="d-tabs__list" data-tabs>
                  <li
                    class="d-tabs__item"
                    :aria-selected="activeTab === 'calc'"
                    @click="activeTab = 'calc'"
                  >
                    <div class="d-tabs__link">
                      <span class="d-tabs__label">Методика расчета</span>
                    </div>
                  </li>
                  <li
                    class="d-tabs__item"
                    :aria-selected="activeTab === 'comment'"
                    @click="activeTab = 'comment'"
                  >
                    <div class="d-tabs__link">
                      <span class="d-tabs__label"
                        >Причины непредоставления данных или несогласия с данными</span
                      >
                    </div>
                  </li>
                </ul>
              </div>
              <div class="d-panes" data-panes>
                <div class="d-pane" role="tabpanel" :hidden="activeTab === 'comment'">
                  <div class="d-pane__wrap">
                    <div class="d-pane__body">
                      <img v-if="calcMethodImagePath" :src="calcMethodImagePath" alt="" />
                    </div>
                  </div>
                </div>

                <div class="d-pane" role="tabpanel" :hidden="activeTab === 'calc'">
                  <div class="d-pane__wrap">
                    <div class="d-pane__body">
                      <text-area
                        label="Комментарий"
                        type="1"
                        rows="2"
                        v-model="activeMark.disagreement_reasons"
                        :isDisabled="isTaskFinished || isProgramFinished"
                      />
                    </div>
                    <div class="d-pane__footer">
                      <div class="line">
                        <div class="line__item line__item--mla">
                          <custom-button
                            label="Сохранить комментарий"
                            :disabled="isTaskFinished || isProgramFinished"
                            @click="saveProgramMarkDisagreementReasons"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="!activeMark && !isLoading" class="main__block">
            <div class="blank">
              <div class="blank__cover">
                <svg-clr name="accreditation-monitoring" :size="24" />
              </div>
              <div class="blank__text">Данные отсутствуют</div>
            </div>
          </div>

          <div v-if="isLoading" class="main__over">
            <div class="loader loader--l">
              <svg-clr name="loader" size="24" />
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script setup>
import { onBeforeMount, onMounted, ref, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { Monitoring } from '@/repositories';
import SvgIcon from '@/components/SvgIcon';
import SvgClr from '@/components/SvgClr';
import InputText from '@/components/InputText';
import CustomButton from '@/components/CustomButton';
import SwitchInput from '@/components/SwitchInput.vue';
import TextArea from '@/components/TextArea.vue';
import { MONITORING_STATUS_ID } from "@/constants/monitoring/status";

const route = useRoute();
const router = useRouter();
const store = useStore();

const programId = route.params.id;
const activeTab = ref('calc');

const isLoading = ref();
const program = ref();
const task = ref();
const marks = ref([]);
const levelId = ref();
const activeSideLinkId = ref();

const activeMark = ref();
const calcMethodImagePath = ref();
const markData = ref([]);
const form = ref({});
const errors = ref({});

const isDataCheckboxAndPrefilled = ref(false);
const preloadedCheckboxesIds = ref([]);

const isMarkDataLast = computed(() => {
  return marks.value[marks.value.length - 1] === activeMark.value;
});

const collapsed = computed(() => {
  return store.state.sidebar.collapsed;
});

const isProgramFinished = computed(() => {
  return program.value?.is_sent;
});

// Если уровень образования СПО и показатель 2 или 4, возвращаем true
const isIndicatorPreloadable = (programLevel, markId) => {
  return (
    (programLevel === 5 && [27, 29].includes(markId)) ||
    (programLevel === 4 && [24, 25].includes(markId)) ||
    (programLevel === 3 && [18, 19].includes(markId))
  );
};

const toggle = () => {
  store.dispatch('sidebar/toggle');
};

/**
 * @param {number} taskId
 * @param {number|null} levelId
 */
const redirectToTaskPrograms = (taskId, levelId = null) => {
  const GENERAL_EDUCATION_LEVELS_IDS = [2, 3, 4];
  const levelsIds = GENERAL_EDUCATION_LEVELS_IDS.includes(levelId)
    ? GENERAL_EDUCATION_LEVELS_IDS
    : [levelId];
  if (store.state.user.isExternal) {
    router.push({
      name: 'external.monitoring.task-programs',
      params: { id: taskId },
      query: { levels: levelsIds },
    });
  } else {
    router.push({
      name: 'monitoring.task-programs',
      params: { id: taskId },
      query: { levels: levelsIds },
    });
  }
};

const toggleMarkData = (newMark) => {
  isLoading.value = true;
  if (isProgramFinished.value && isMarkDataLast.value && !newMark) {
    redirectToTaskPrograms(program.value.task_id, program.value.level_id);
  } else if (isProgramFinished.value) {
    activeSideLinkId.value = newMark.id;
    getProgramMarkData(newMark);
  } else if (isMarkDataLast.value && !newMark) {
    validateInputNumberMarkData();

    if (!isErrosInMarkDataScore.value) {
      finishProgram(program.value.task_id);
    }
  } else {
    validateInputNumberMarkData();

    if (!isErrosInMarkDataScore.value) {
      saveProgramMarkData(newMark);
      activeSideLinkId.value = newMark.id;
    }
  }
};

const changeMarkData = (newMark) => {
  isLoading.value = true;
  getProgramMarkData(newMark);
  activeSideLinkId.value = newMark.id;
};

//Проверяем все чекбоксы и их ссылки на заполненность. Если есть хотя бы один чекбокс, который сам выбран, но ссылка его пустая, то возвращаем true
const isAnyResourceLinkNotFilled = computed(() => {
  if (markData.value[0]?.type === 'checkbox') {
    const notFilledLink = markData.value.find((item) => {
      let checkboxes = form.value[item.data_id].checkbox_values_ids;
      let checkBoxLinkLength = form.value[item.data_id]?.resourse_links[item.id]?.length;
      let checkbox = item.id;
      return (
        !preloadedCheckboxesIds.value.includes(checkbox) &&
        checkboxes.includes(checkbox) &&
        !checkBoxLinkLength
      );
    });

    return notFilledLink ? true : false;
  }
  return false;
});

const isCheckboxAlreadyChecked = (checkboxes, newCheckbox) => checkboxes.includes(newCheckbox);

const isCheckboxLinkFilled = (resourceLink) => Boolean(resourceLink?.length);

const updateCheckboxesLinks = (resourceLink, checkboxes, newCheckbox) => {
  const checkboxIndex = checkboxes.indexOf(newCheckbox);

  if (resourceLink?.length && checkboxIndex < 0) {
    checkboxes.push(newCheckbox);
  } else if (!resourceLink?.length && checkboxIndex >= 0) {
    checkboxes.splice(checkboxIndex, 1);
  }
};

const isErrosInMarkDataScore = computed(() => {
  const errorIds = Object.keys(errors.value);

  const filledError = errorIds.find((errorId) => errors.value[errorId] !== '');

  return filledError ? true : false;
});

const resetNumberInputError = (inputId) => {
  errors.value[inputId] = '';
};

const validateInputNumberMarkData = () => {
  // Проверяем, что наша markData для текущего показателя, это числовые инпуты
  if (
    markData.value[0]?.data &&
    markData.value[0]?.data?.values[0]?.type !== 'checkbox' &&
    markData.value[0]?.data?.values[0]?.type !== 'radio'
  ) {
    markData.value.forEach((item) => {
      let itemScore = Number(form.value[item.data_id].score);
      let setMinScore = item.data.values?.find((value) => value?.type === 'min');
      let minScore = setMinScore ? setMinScore : 0;
      let maxScore = item.data.values?.find((value) => value?.type === 'max');

      if (itemScore < minScore || itemScore > maxScore?.value) {
        errors.value[item.data_id] = maxScore
          ? `Введенное значение должно быть от ${0} и до ${maxScore?.value}`
          : `Введенное значение должно быть от ${0}`;
        isLoading.value = false;
      } else {
        errors.value[item.data_id] = '';
      }
    });
  }
};

async function saveProgramMarkDisagreementReasons() {
  isLoading.value = true;
  await Monitoring.saveProgramMarkDisagreementReasons(
    activeMark.value.id,
    activeMark.value.disagreement_reasons
  );
  isLoading.value = false;
}

async function finishProgram(taskId) {
  try {
    await saveProgramMarkData();
    redirectToTaskPrograms(taskId, program.value.level_id);
  } catch (error) {
    window.alert('Не удалось завершить программу');
  }
}

async function saveProgramMarkData(newMark) {
  try {
    const data = Object.entries(form.value).map(([key, value]) => {
      const item = {
        data_id: key,
        id: value.id,
      };

      if (value.score != null) {
        item.score = value.score;
      }

      if (value.checkbox_values_ids != null) {
        item.checkbox_values_ids = value.checkbox_values_ids.reduce((obj, item) => {
          return { ...obj, [item]: { resourse_link: value.resourse_links[item] } };
        }, {});
      }

      if (value.radio_value_id != null) {
        item.radio_value_id = value.radio_value_id;
      }

      item.disagreement_reasons = value.disagreement_reasons;

      return item;
    });

    const isMarkPreloadableAndPreloaded =
      isIndicatorPreloadable(levelId.value, activeMark.value.mark_id) &&
      activeMark.value.is_preloaded;
    if (!isMarkPreloadableAndPreloaded) {
      await Monitoring.saveProgramMarkDataValues(activeMark.value.id, data, task.value.id);
    }
    form.value = {};
    markData.value = [];
    if (newMark) {
      getProgramMarkData(newMark);
    }
  } catch (error) {
    window.alert('Не удалось отправить данные по показателю');
  }
}

const setProgramMarkDataValue = (serverResponseDatas) => {
  // Если показатель с чекбокасами, тогда markData.value будет равен массиву чекбоксов
  if (serverResponseDatas[0].data?.values[0]?.type === 'checkbox') {
    return serverResponseDatas[0].data?.values.sort((itemA, itemB) => itemA.order - itemB.order);
  }
  return serverResponseDatas;
};

const setFormMarkData = (serverResponseMarkDatas) => {
  serverResponseMarkDatas.forEach((item) => {
    switch (item.data.values[0]?.type) {
      case 'checkbox':
        form.value[item.data_id] = {
          id: item.id,
          checkbox_values_ids: item.values.map((item) => item.id),
          disagreement_reasons: item.data.disagreement_reasons,
          resourse_links: item.values.reduce((obj, item) => {
            return { ...obj, [item.id]: item.pivot.resourse_link };
          }, {}),
        };
        break;
      case 'radio':
        form.value[item.data_id] = {
          id: item.id,
          radio_value_id: item.values.length ? item.values[0].id : item.data.values[0].id,
          disagreement_reasons: item.data.disagreement_reasons,
        };
        break;
      default:
        form.value[item.data_id] = {
          id: item.id,
          score: item.score,
          disagreement_reasons: item.data.disagreement_reasons,
        };
    }
  });
};

const setErrorsMarkData = (serverResponseMarkDatas) => {
  serverResponseMarkDatas.forEach((item) => {
    if (item.data.values[0]?.type !== 'checkbox' && item.data.values[0]?.type !== 'radio') {
      errors.value[item.data_id] = '';
    }
  });
};

async function getProgramMarkData(mark) {
  activeMark.value = mark;

  try {
    const response = await Monitoring.getProgramMarkData(mark.id);
    calcMethodImagePath.value = `/storage/${response.calc_method}`;

    /* Какая-то херота.
     * Обновляем два нужных поля если они изменились с момента открытия экрана с показателями данными о показателе, полученными из API
     */
    activeMark.value.is_preloaded = response.is_preloaded;
    activeMark.value.result = response.result;
    markData.value = setProgramMarkDataValue(response.datas);
    isDataCheckboxAndPrefilled.value =
      response.datas[0]?.data?.values[0]?.type === 'checkbox' && response.datas[0]?.is_preloaded;
    preloadedCheckboxesIds.value = response.datas[0]?.values
      .map((value) => (value?.pivot?.is_preloaded ? value.id : null))
      .filter((id) => id != null);

    setFormMarkData(response.datas);
    setErrorsMarkData(response.datas);
  } catch (error) {
    window.alert('Не удалось получить данные для рассчета аккредитационного показателя');
  } finally {
    isLoading.value = false;
  }
}

const isTaskFinished = computed(() => task.value?.status_id === MONITORING_STATUS_ID.FINISHED);

async function getTask() {
  try {
    const response = await Monitoring.getTask(program.value.task_id);
    task.value = response;
  } catch (error) {
    window.alert('Не удалось получить данные задачи');
  }
}

async function getMarks() {
  try {
    const response = await Monitoring.getProgramMarksByProgramId(programId);
    program.value = response.program;
    marks.value = filterAndSortMarks(response.marks);
    levelId.value = response.marks[0].mark.level_id;
    activeSideLinkId.value = marks.value[0].id;
    await getTask();
    await getProgramMarkData(marks.value[0]);
  } catch (error) {
    console.log(error);
    window.alert('Не удалось получить аккредитационные показатели программы');
  }
}

const isMagistracyLevelId = (programEduLevelId) =>
  programEduLevelId === '765d52aa34e645d0b0bf85e4015fb960';
const isHigherLevel = (level) => level === 1;
const isSecondaryVocationalLevel = (level) => level === 5;

const isSpecialProgramCode = (programCode) => {
  const programLevelCode = programCode?.split('.')[1];
  return programLevelCode === '08' || programLevelCode === '09';
};

// const isSchoolLevel = (level) => level === 2 || level === 3 || level === 4;

const filterAndSortMarks = (marks) => {
  // Скрытие показателей 1 и 1.1 для ВО при выбранной/не выбранной опции ДВИ
  if (isHigherLevel(program.value.level_id) && program.value.dvi) {
    marks = marks.filter((mark) => mark.mark_id !== 1);
  } else {
    marks = marks.filter((mark) => mark.mark_id !== 2);
  }

  // Скрытие показателей 1, 1.1 и 8 для ВО-магистратуры
  if (isMagistracyLevelId(program.value.edu_level_id)) {
    marks = marks.filter((mark) => mark.mark_id !== 1 && mark.mark_id !== 2 && mark.mark_id !== 9);
  }

  // Скрытие показателей 1, 1.1 и 8 для ВО с кодами 08/09
  if (
    isHigherLevel(program.value.level_id) &&
    isSpecialProgramCode(program.value.licensed_program_code)
  ) {
    marks = marks.filter((mark) => mark.mark_id !== 1 && mark.mark_id !== 2 && mark.mark_id !== 9);
  }

  // Скрытие показателя 4 для СПО с опцией "Нет" в столбце "Демо"
  if (isSecondaryVocationalLevel(program.value.level_id) && program.value.demo === 'Нет') {
    marks = marks.filter((mark) => mark.mark_id !== 29);
  }

  // Скрытие показателя 4 для СПО с опцией "Нет" в столбце "Наличие выпуска в 2022 году (СПО, общее образование)"
  if (
    isSecondaryVocationalLevel(program.value.level_id) &&
    program.value.graduation_in_2022 === 'Нет'
  ) {
    marks = marks.filter((mark) => mark.mark_id !== 29);
  }

  //Скрытие показателя 3 для СПО c выбранной опцией "11 классов" в столбце "База набора"
  if (
    isSecondaryVocationalLevel(program.value.level_id) &&
    program.value.recruit_base === '11 классов'
  ) {
    marks = marks.filter((mark) => mark.mark_id !== 28);
  }

  /*  // Скрытие показателя 2 для общего образования при выбранной/не выбранной опции в столбце "Адаптированная"
  if (isSchoolLevel(program.value.level_id) && program.value.is_adapted) {
    marks = marks.filter(
      (mark) => mark.mark_id !== 11 && mark.mark_id !== 15 && mark.mark_id !== 21
    );
  } */

  return marks.sort((a, b) => (a.mark.number > b.mark.number ? 1 : -1));
};

onBeforeMount(() => {
  isLoading.value = true;
});

onMounted(() => {
  getMarks();
});
</script>

<style lang="scss" scoped></style>
