<template>
  <div class="main__body">
    <div class="main__block">
      <div class="block">
        <div class="block__main">
          <div class="tbl-wrap">
            <table class="tbl">
              <thead>
                <tr>
                  <th v-for="column in $options.columns" :key="column">{{ column }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="documents && !documents.length">
                  <td :colspan="$options.columns.length" class="align-center">Документы не найдены</td>
                </tr>
                <template v-else>
                  <tr v-for="document in documents" :key="document.id">
                    <td>{{ document.type }}</td>
                    <td>{{ document.number }}</td>
                    <td>
                      <a
                        v-if="document.file && document.file.path"
                        :href="document.file.path"
                        :download="document.file.name ? document.file.name : ''"
                      >
                        {{ document.file.name }}
                      </a>
                    </td>
                    <td>{{ this.$filters.formatDate(document.file?.createdAt) }}</td>
                    <td>{{ document.comment }}</td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Expertises } from '@/repositories';

export default {
  name: 'ExpertiseDocumentsForExpert',
  columns: ['Тип документа', 'Номер', 'Название файла', 'Дата загрузки', 'Примечание'],
  created() {
    this.getDocuments();
  },
  watch: {
    id() {
      this.getDocuments();
    },
  },
  data() {
    return {
      documents: null,
    };
  },
  methods: {
    getDocuments() {
      Expertises.getDocumentsForExpert(this.id).then((documents) => (this.documents = documents));
    },
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
};
</script>
