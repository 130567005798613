<template>
  <header class="header">
    <div class="header__wrap">
      <div class="header__logo">
        <router-link :to="{ path: homePath }" class="logo">
          <img src="~@/assets/images/logo-visual.svg" width="42" height="36" alt="Логотип ИС ГА" class="logo__img" />
          <img src="~@/assets/images/logo-text.svg" width="81" height="19" alt="ИС ГА" class="logo__text" />
        </router-link>
      </div>

      <nav class="header__nav h-nav">
        <ul class="h-nav__list">
          <li v-for="link in links" :key="link.label" class="h-nav__item">
            <router-link
              :to="{ name: link.to }"
              custom
              v-slot="{ route, isActive, navigate, href }"
            >
              <a
                :href="href"
                @click="navigate"
                :class="{'h-nav__link--active': isActive || $route.path.startsWith(route.path)}"
                class="h-nav__link"
              >
                {{ link.label }}
              </a>
            </router-link>
          </li>
        </ul>
      </nav>

      <div class="header__profile h-profile">
        <div class="h-profile__name">{{ userName }}</div>
        <div @click="logout" class="h-profile__exit link link--gray">
          <svg-icon name="exit" :size="24" />
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import SvgIcon from '@/components/SvgIcon';
export default {
  name: 'NavHeader',
  components: { SvgIcon },
  computed: {
    userName() {
      return this.$store.state.user.name;
    },
    homePath() {
      return this.$store.getters.homePath;
    },
  },
  methods: {
    logout() {
      this.$store.dispatch('logout');
      this.$router.push({ name: 'login' });
    },
  },
  props: {
    links: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
