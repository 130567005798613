<template>
  <div class="main__body">
    <div class="main__block">
      <div class="block">
        <div class="block__main">
          <div class="row row--aife">
            <div class="col col--full">
              <text-area
                  label="Наименование организации"
                  :model-value="expertise.organization?.name"
                  isDisabled
              />
            </div>
            <div class="col col--full">
              <input-text
                  label="Место нахождения"
                  :model-value="expertise.organization?.legalAddress"
                  isDisabled
              />
            </div>
            <div class="col col--full">
              <input-text
                  label="Руководитель образовательной организации"
                  :model-value="headFullName"
                  isDisabled
              />
            </div>
            <div class="col col--full">
              <input-text
                  label="Контактные данные"
                  :model-value="organizationContacts"
                  isDisabled
              />
            </div>
            <div class="col col--full">
              <input-text label="Куратор" :model-value="expertise.curator" isDisabled />
            </div>
            <div class="col col--full">
              <input-text label="Договор" :model-value="expertise.contract?.number" isDisabled />
            </div>
            <div class="col col--three-fourths">
              <input-text
                  label="Руководитель экспертной группы"
                  :model-value="expertise.chairman"
                  isDisabled
              />
            </div>
            <div class="col col--fourth" v-if="expertIsChairman">
              <custom-button label="Сформировать заключение" is-expandable @click="conclusionModal.open = true"/>
            </div>
            <div v-if="expertise.conclusion" class="col col--full">
              <a
                  :href="
                  expertise.conclusion.path ? `/storage/uploads/${expertise.conclusion.path}` : null
                "
              >{{ expertise.conclusion.name }}</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="main__block">
      <div class="block">
        <div class="block__main">
          <div class="tbl-wrap">
            <table class="tbl">
              <thead>
              <tr>
                <th>Код УГСН</th>
                <th>Наименование УГСН</th>
                <th>Код направления</th>
                <th>Направление подготовки</th>
                <th>Уровень ОП</th>
                <th>Отчет</th>
                <th>Итоги тестирования</th>
              </tr>
              </thead>
              <tbody>
              <tr v-if="expertise.educationPrograms && !expertise.educationPrograms.length">
                <td :colspan="$options.columns.length" class="align-center">
                  Задания не найдены
                </td>
              </tr>
              <template v-else>
                <tr v-for="program in expertise.educationPrograms" :key="program.id">
                  <td>{{ program.egsCode }}</td>
                  <td>{{ program.egsName }}</td>
                  <td>{{ program.code }}</td>
                  <td>{{ program.name }}</td>
                  <td>{{ program.educationLevel }}</td>
                  <td>
                    <template v-if="program.report">
                      <a :href="program.report.file?.path">{{ program.report.file?.name }}</a>
                      <button
                          class="link link--gray"
                          type="button"
                          @click="removeReport(program.report.id)"
                      >
                        <svg-icon name="delete" :size="16" />
                      </button>
                    </template>
                    <template v-else-if="program.assignedToCurrentExpert">
                      <a href="javascript:" @click="showReportModal(program)">Сформировать</a>
                    </template>
                  </td>
                  <td>
                    <template v-if="program.assignedToCurrentExpert">
                      <custom-button label="Показать" @click="showTestingResults(program)"/>
                    </template>
                  </td>
                </tr>
              </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <generate-expertise-report
        :organization="expertise.organization"
        :expert="this.$store.state.user.name"
        :order="expertise.order"
        :organizationId="reportModal.organizationId"
        :speciality-code="reportModal.speciality.code"
        :speciality-name="reportModal.speciality.name"
        :egs-code="reportModal.egs.code"
        :egs-name="reportModal.egs.name"
        :education-level="reportModal.educationLevel"
        :expertise-id="id"
        :open="reportModal.open"
        @close="closeReportModal"
    />
    <generate-expertise-conclusion
        :organization="expertise.organization?.name"
        :expert="this.$store.state.user.name"
        :order="expertise.order"
        :open="conclusionModal.open"
        :programs="expertise.educationPrograms"
        :expertise-id="id"
        @close="conclusionModal.open = false"
    />
  </div>
</template>

<script>
import TextArea from '@/components/TextArea';
import InputText from '@/components/InputText';
import { Expertises } from '@/repositories';
import SvgIcon from '@/components/SvgIcon';
import CustomButton from '@/components/CustomButton';
import GenerateExpertiseReport from '@/components/modals/GenerateExpertiseReport';
import GenerateExpertiseConclusion from '@/components/modals/GenerateExpertiseConclusion';
export default {
  name: 'ExpertiseTasks',
  components: {
    TextArea,
    InputText,
    SvgIcon,
    CustomButton,
    GenerateExpertiseReport,
    GenerateExpertiseConclusion,
  },
  columns: [
    'Код УГСН',
    'Наименование УГСН',
    'Код направления',
    'Направление подготовки',
    'Уровень ОП',
    'Отчет',
    '',
  ],
  created() {
    this.getTask();
  },
  watch: {
    id() {
      this.getTask();
    },
  },
  data() {
    return {
      expertise: {},
      reportModal: {
        egs: {},
        speciality: {},
        educationLevel: null,
        organizationId: null,
        open: false,
      },
      conclusionModal: {
        open: false,
      },
    };
  },
  computed: {
    headFullName() {
      const head = this.expertise.organization?.head;
      if (!head) {
        return null;
      }
      const firstName = head.firstName || '';
      const surname = head.surname ? ` ${head.surname}` : '';
      const patronymic = head.patronymic ? ` ${head.patronymic}` : '';
      return firstName + surname + patronymic;
    },
    organizationContacts() {
      const contacts = this.expertise.organization?.contacts;
      if (!contacts) {
        return null;
      }
      const phones = contacts.phoneNumbers || '';
      const emails = contacts.emails ? ` ${contacts.emails}` : '';
      return phones + emails;
    },
  },
  methods: {
    getTask() {
      Expertises.getTask(this.id).then((expertise) => (this.expertise = expertise));
    },
    removeReport(reportId) {
      if (!window.confirm('Удалить документ?')) {
        return;
      }
      Expertises.removeReport(this.id, reportId).then(this.getTask);
    },
    showReportModal(program) {
      this.reportModal.educationLevel = program.educationLevel;
      this.reportModal.egs.code = program.egsCode;
      this.reportModal.egs.name = program.egsName;
      this.reportModal.speciality.code = program.code;
      this.reportModal.speciality.name = program.name;
      this.reportModal.organizationId = program.organizationId;
      this.reportModal.open = true;
    },
    closeReportModal() {
      this.reportModal.open = false;
    },
    showTestingResults(program) {
      const path = process.env.VUE_APP_API_URL;
      const query = {
        speciality_id: program.licensedProgramId,
        organization_id: program.organizationId,
        expertise_id: this.id,
      };

      const url =
          path +
          '/testing-results' +
          '?' +
          Object.entries(query)
              .map(([key, value]) => `${key}=${value}`)
              .join('&');

      window.open(url, '_blank');
    },
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    expertIsChairman: Boolean,
  },
};
</script>
