import AuthenticationLayout from '@/layouts/AuthenticationLayout';
import Login from '@/views/Auth/Login';
import Registration from '@/views/Auth/Registration';

export const routes = [
  {
    path: '/authentication',
    component: AuthenticationLayout,
    children: [
      {
        path: '',
        name: 'login',
        component: Login,
      },
      {
        path: 'registration',
        name: 'registration',
        component: Registration,
      },
    ],
  },
];
