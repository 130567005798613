export const sidebar = {
  namespaced: true,
  state() {
    return {
      collapsed: false,
    };
  },
  getters: {},
  mutations: {
    open(state) {
      state.collapsed = false;
    },
    close(state) {
      state.collapsed = true;
    },
  },
  actions: {
    toggle({ commit, state }) {
      commit(state.collapsed ? 'open' : 'close');
    },
  },
};
