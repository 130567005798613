import { ValidationError } from '@/exceptions';
import axios from '@/api/axios';

export const user = {
  state() {
    return {
      name: '',
      email: '',
      login: '',
      role: '',
      isExternal: false,
    };
  },
  getters: {
    homePath({ role, isExternal }) {
      if (role === 'user' && isExternal) {
        return '/external';
      }

      if (role === 'user') {
        return '/eo';
      }

      if (role === 'expert') {
        return '/expert';
      }

      if (role === 'curator') {
        return '/curator';
      }

      return '/authentication';
    },
    authenticated(state) {
      return Boolean(state.role);
    },
  },
  mutations: {
    storeUser(state, { name, email, login, role, is_external }) {
      state.name = name;
      state.email = email;
      state.login = login;
      state.role = role;
      state.isExternal = is_external;
    },
    removeUser(state) {
      state.name = state.email = state.role = state.login = '';
      state.isExternal = false;
    },
  },
  actions: {
    async authenticate({ dispatch }, credentials) {
      try {
        await axios.get('/sanctum/csrf-cookie');
        await axios.post('/login', credentials);
      } catch (error) {
        if (error.response?.status !== 422) {
          throw error;
        }
        throw new ValidationError(error.response.data.errors);
      }

      return dispatch('getAuthenticatedUser');
    },
    logout({ commit }) {
      axios.get('/logout');
      commit('removeUser');
    },
    async getAuthenticatedUser({ commit }) {
      try {
        const response = await axios.get('/user');
        commit('storeUser', response.data);
      } catch {
        commit('removeUser');
      }
    },
  },
};
