import axios from '@/api/axios';

export const Expert = {
  /** @return {Promise<ExpertInfo>} */
  async getInfo() {
    const response = await axios.get('expert');
    return response.data.data;
  },
  /** @return {Promise<ExpertPower[]>} */
  async getPowers() {
    const response = await axios.get('expert/powers');
    return response.data.data;
  },
  /** @return {Promise<Certificate[]>} */
  async getCertificates() {
    const response = await axios.get('expert/certificates');
    return response.data.data;
  },

  /**
   * Получить все заявления поданные экспертом.
   *
   * @returns {Promise<Object[]>}
   */
  async getApplications() {
    const response = await axios.get('expert/applications');
    return response.data.data;
  },
  /**
   * @returns {Promise<void>}
   */
  async createApplication() {
    await axios.post('expert/applications');
  },
  /**
   * @param {object} expert
   * @param {string|null} expert.short
   * @param {string|null} expert.identity_document_details
   * @param {string} expert.address
   * @param {string|null} expert.fact_address
   * @param {string} expert.region_id
   * @param {boolean|null} expert.is_gos_secret
   * @param {string|null} expert.level_access
   * @param {string|number|null} expert.academic_rank_id
   * @param {string|number|null} expert.academic_degree_id
   * @param {string|null} expert.post
   * @param {string|null} expert.director
   * @param {string|number|null} expert.kpp
   * @param {string|number|null} expert.ogrn
   * @param {string|number|null} expert.invoice
   * @param {string|number|null} expert.bik
   * @param {string|number|null} expert.okpo
   * @param {object|null} expert.contacts
   * @param {string|null} expert.contacts.fax
   * @param {string|null} expert.contacts.email
   * @param {string|null} expert.contacts.phone
   * @returns {Promise<void>}
   */
  async update(expert) {
    await axios.patch('expert', expert);
  },
  async getAcademicDegrees() {
    const response = await axios.get('expert/academic-degrees');
    return response.data.data;
  },
  async getAcademicRanks() {
    const response = await axios.get('expert/academic-ranks');
    return response.data.data;
  },
};

/**
 * @typedef {object} ExpertInfo
 * @property {string|null} [surname]
 * @property {string|null} name
 * @property {string|null} [patronymic]
 * @property {string|null} [sex]
 * @property {string|null} [birthDate]
 * @property {Workplace[]} workplaces
 * @property {Education[]} educations
 * @property {Degree[]|null} academic_degrees
 * @property {AcademicRank[]|null} academic_ranks
 * @property {string|null} position
 * @property {string|null} inn
 * @property {Region|null} region
 * @property {string|null} address
 * @property {boolean} hasAccessToStateSecret
 * @property {number} [accessLevel]
 * @property {boolean} belongsToReligiousOrganization
 * @property {Contacts|null} contacts
 * @property {boolean} is_organization
 * @property {boolean} is_candidate
 */

/**
 * @typedef {object} Workplace
 * @property {string} id
 * @property {string|null} organization_name
 * @property {string|null} startedAt
 * @property {string|null} endedAt
 * @property {boolean} [isCurrent]
 * @property {boolean} [isPrevious]
 */

/**
 * @typedef {object} Education
 * @property {string} id
 * @property {string|null} organization
 */

/**
 * @typedef {object} Degree
 * @property {string} id
 * @property {string|null} name
 * @property {string|null} code
 * @property {string|null} conferment_year
 */

/**
 * @typedef {object} AcademicRank
 * @property {string} id
 * @property {string|null} code
 * @property {string|null} name
 * @property {string|null} conferment_year
 * @property {string|null} field_of_activity
 */

/**
 * @typedef {object} Region
 * @property {string} id
 * @property {string|null} name
 * @property {FedOkr|null} federal_district
 */

/**
 * @typedef {object} FedOkr
 * @property {string} id
 * @property {string|null} name
 * @property {string|null} shortName
 */

/**
 * @typedef {object} Contacts
 * @property {string|null} email
 * @property {string|null} fax
 * @property {string|null} phoneNumber
 */

/**
 * @typedef ExpertPower
 * @property {string|null} egsCode
 * @property {string|null} egsName
 * @property {string|null} educationLevel
 * @property {string|null} expiresAt
 */

/**
 * @typedef {object} Certificate
 * @property {string|null} orderNumber
 * @property {string} orderType
 * @property {string|null} orderIssuedAt
 * @property {string|null} expiresAt
 */
