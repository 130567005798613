<template>
  <div class="main-wrap">
    <nav-header :links="$options.links" />
    <router-view />
  </div>
</template>

<script>
import NavHeader from '@/components/NavHeader';
export default {
  name: 'UserLayout',
  components: { NavHeader },
  links: [
    { label: 'Главная', to: 'home' },
    { label: 'Мониторинг', to: 'monitoring.tasks' },
  ],
  data() {
    return {
      userName: this.$store.state.user.name,
    };
  },
  methods: {
    logout() {
      this.$store.dispatch('logout');
      this.$router.push({ name: 'login' });
    },
  },
};
</script>
