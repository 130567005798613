const dateOptions = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
};

/**
 * @param {string|number|null} date - Timestamp or date string valid for Date.parse
 * @return {string|null}
 */
export function formatDate(date) {
  if (date == null) {
    return null;
  }
  return new Date(date).toLocaleDateString('ru', dateOptions);
}
