<template>
  <div class="form-group">
    <div class="form-label">Файл</div>
    <form-field
      class="form-field--files form-field--2 form-field--s form-field--primary"
      :class="{ 'has-error': errors.length }"
    >
      <div class="file-attach">
        <div class="file-attach__main">
          <template v-if="files.length || errors.length">
            <div class="file-attach__cover" :class="{ 'file-attach__cover--error': errors.length }">
              <div class="loader">
                <SvgIcon v-if="errors.length" name="warning" :size="24" />
                <SvgClr v-else-if="files.length" name="loader" :size="24" />
              </div>
            </div>
          </template>
          <div class="file-attach__placeholder">{{ placeholder }}</div>
        </div>
        <label class="file-attach__label">
          <input type="file" @change="uploadFiles" ref="input" accept=".sig" multiple class="file-attach__input" />
          <span class="file-attach__btn">Обзор</span>
        </label>
      </div>
    </form-field>
    <div v-if="errors.length" class="error-text error-text--right">
      <template v-for="error in errors" :key="error">
        {{ error }} <br/>
      </template>
    </div>
  </div>
</template>

<script>
import FormField from '@/components/FormField';
import SvgClr from '@/components/SvgClr';
import SvgIcon from '@/components/SvgIcon';
import { ValidationError } from '@/exceptions';
import { Request } from '@/repositories/user/Request';

export default {
  name: "RequestDocumentUploader",
  components: { FormField, SvgClr, SvgIcon },
  data() {
    return {
      files: [],
      errors: [],
    };
  },
  computed: {
    placeholder() {
      return this.files.length
        ? this.files.map(file => file.name).join(', ')
        : 'Прикрепите файл';
    },
  },
  methods: {
    uploadFiles(event) {
      const files = event.target.files;
      this.files = [...files];
      if (!files.length) {
        return;
      }
      this.errors = [];
      Request
        .addDocument(this.requestId, this.getUploadForm())
        .then(() => this.$emit('uploaded'))
        .then(this.resetForm)
        .catch(this.handleUploadError);
    },
    getUploadForm() {
      const form = new FormData;
      this.files.forEach(file => form.append('files[]', file));
      form.append('requestId', this.requestId);
      form.append('documentTypeId', this.documentTypeId);
      form.append('programId', this.programId);
      return form;
    },
    resetForm() {
      this.files = [];
      this.$refs.input.value = '';
    },
    handleUploadError(error) {
      if (!(error instanceof ValidationError)) {
        this.error = 'Не удалось сохранить файл. Попробуйте, пожалуйста позже!';
        throw error;
      }
      /* TODO Добавить имя файла к его ошибке */
      this.errors = error.getAllErrors();
    }
  },
  props: {
    requestId: {
      type: [String, Number],
      required: true,
    },
    documentTypeId: {
      type: [String, Number],
      required: true,
    },
    programId: {
      type: [String, Number],
      required: true,
    },
  }
}
</script>
