<template>
  <div class="block">
    <div class="block__top">
      <div class="block-title">{{ title }}</div>
    </div>

    <div class="block__main">
      <div class="block__subblock" v-for="(accreditation, index) in modelValue" :key="index">
        <div class="row row--aife">
          <div class="col col--full">
            <text-area
              label="Организация, проводившая общественную аккредитацию"
              placeholder="Наименование организации"
              v-model="accreditation.organization"
            />
          </div>
          <div class="col col--third">
            <input-text
              label="Дата выдачи свидетельства"
              placeholder="Выберите дату"
              v-model="accreditation.date"
              button="calendar"
              is-date
            />
          </div>
          <div class="col col--two-thirds">
            <text-area
              label="Реквизиты свидетельства"
              placeholder="Реквизиты свидетельства"
              v-model="accreditation.requisite"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="block__bottom">
      <custom-button :type="4" icon="add" label="Добавить общественную аккредитацию" @click="addAccreditation"/>
    </div>
  </div>
</template>

<script>
import TextArea from '@/components/TextArea';
import CustomButton from '@/components/CustomButton';
import InputText from '@/components/InputText';
export default {
  name: 'OrganizationAccreditation',
  components: { TextArea, CustomButton, InputText },
  methods: {
    addAccreditation() {
      const emptyAccreditation = {
        organization: null,
        date: null,
        requisite: null,
      };
      this.$emit('update:modelValue', [...this.modelValue, emptyAccreditation]);
    }
  },
  props: {
    modelValue: {
      type: Array,
      default: () => [],
    },
    title: String,
  },
};
</script>
