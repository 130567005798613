<template>
  <main class="main">
    <div class="main__wrap">
      <div class="main__body">
        <div class="main__block">
          <div class="block">
            <div class="block__top">
              <div class="block-title">
                К сожалению, данных для вашей организации пока нет. Но они очень скоро обязательно
                появятся!
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: 'OrganizationDetails',
};
</script>
