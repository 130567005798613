<template>
  <div class="block">
    <div class="block__main">
      <div class="row row--aife">
        <div class="col col--third">
          <input-text label="Фамилия" :model-value="expert.surname" is-disabled />
        </div>

        <div class="col col--third">
          <input-text label="Имя" :model-value="expert.name" is-disabled />
        </div>

        <div class="col col--third">
          <input-text label="Отчество" :model-value="expert.patronymic" is-disabled />
        </div>

        <div class="col col--half">
          <input-text
            label="Реквизиты документа, удостоверяющего личность"
            :model-value="expert.identity_document_details"
            is-disabled
          />
        </div>

        <div class="col col--fourth">
          <input-text label="СНИЛС" :model-value="expert.snils" is-disabled />
        </div>

        <div class="col col--fourth">
          <input-text label="ИНН" :model-value="expert.inn" is-disabled />
        </div>

        <div class="col col--full">
          <input-text label="Адрес местонахождения" :model-value="expert.address" is-disabled />
        </div>

        <div class="col col--third">
          <input-text label="Рабочий телефон" :model-value="expert.contacts?.fax" is-disabled />
        </div>

        <div class="col col--third">
          <input-text
            label="Мобильный телефон"
            :model-value="expert.contacts?.phoneNumber"
            is-disabled
          />
        </div>

        <div class="col col--third">
          <input-text label="Электронная почта" :model-value="expert.contacts?.email" is-disabled />
        </div>

        <div class="col col--full">
          <div class="form-label">Сведения об образовании</div>
          <div class="tbl-wrap">
            <table class="tbl-list">
              <thead>
                <tr>
                  <th>Образовательное учреждение</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="education in expert.educations" :key="education.id">
                  <td>{{ education.organization }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="col col--full">
          <div class="form-label">Ученые степени</div>
          <div class="tbl-wrap">
            <table class="tbl-list">
              <thead>
                <tr>
                  <th style="width: 75%">Ученая степень</th>
                  <th style="width: 25%">Год присуждения</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="degree in expert.academic_degrees" :key="degree.id">
                  <td>{{ degree.name }}</td>
                  <td>{{ degree.conferment_year ?? 'Не указан' }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="col col--full">
          <div class="form-label">Ученые звания</div>
          <div class="tbl-wrap">
            <table class="tbl-list">
              <thead>
                <tr>
                  <th style="width: 40%">Ученое звание</th>
                  <th style="width: 40%">Научная специальность</th>
                  <th style="width: 20%">Год присуждения</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="rank in expert.academic_ranks" :key="rank.id">
                  <td>{{ rank.name }}</td>
                  <td>{{ rank.field_of_activity }}</td>
                  <td>{{ rank.conferment_year ?? 'Не указан' }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="col col--full">
          <input-text
            label="Место работы"
            :model-value="
              (expert.workplaces.filter((workplace) => workplace.isCurrent)[0] || {})
                .organization_name
            "
            is-disabled
          />
        </div>

        <div class="col col--full">
          <input-text label="Должность" :model-value="expert.position" is-disabled />
        </div>

        <div class="col col--third">
          <switch-input
            type="checkbox"
            size="m"
            :model-value="expert.hasAccessToStateSecret"
            label="Доступ к гос. тайне"
            disabled
          />
        </div>

        <div v-if="expert.hasAccessToStateSecret" class="col col--two-thirds">
          <input-text
            label="Уровень доступа"
            :model-value="`${expert.accessLevel || 0}-й уровень`"
            is-disabled
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputText from '@/components/InputText';
import SwitchInput from '@/components/SwitchInput';

export default {
  name: 'ExpertDetails',
  props: {
    expert: {
      type: Object,
      required: true,
    },
  },
  components: {
    SwitchInput,
    InputText,
  },
};
</script>
