/**
 * Упорядочивает список объектов по их ключам. Порядок по умолчанию - возрастающий.
 * @param {object[]} array - Массив объектов, который нужно отсортировать
 * @param {(string|Key)[]} keys - Ключи, по которым необходимо отсортировать массив
 * @return {array} - Упорядоченная копия массива
 */
export function orderBy(array, keys) {
  return array.slice().sort((a, b) => {
    for (let item of keys) {
      const { key, order } = getKeyAndOrder(item);

      if (a[key] === b[key]) {
        continue;
      }

      if (order === 'asc') {
        return compare(a[key], b[key]);
      }

      if (order === 'desc') {
        return -compare(a[key], b[key]);
      }
    }

    return 0;
  });
}

/**
 * @param key
 * @return {{key: string, order: string}}
 */
function getKeyAndOrder(key) {
  if (typeof key === 'string') {
    return { key, order: 'asc' };
  }
  return { key: key.key, order: key.order || 'asc' };
}

/**
 * @param {any} a
 * @param {any} b
 * @return {number}
 */
function compare(a, b) {
  if (typeof a === 'string' && typeof b === 'string') {
    return new Intl.Collator('ru').compare(a, b);
  }

  if (isNullOrUndefined(a)) {
    return -1;
  }
  if (isNullOrUndefined(b)) {
    return 1;
  }

  return a > b ? 1 : -1;
}

/**
 * @param {any} value
 * @return {boolean}
 */
function isNullOrUndefined(value) {
  return value == null;
}

/**
 * @typedef Key
 * @property {string} key - Имя ключа
 * @property {string} order - Порядок сортировки. asc или desc.
 */
