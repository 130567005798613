import axios from '@/api/axios';

export const ExpertAcademicDegreesRepository = {
  /**
   * @param {object} academicDegree
   * @param {string} academicDegree.id
   * @param {number|null} academicDegree.confermentYear
   * @returns {Promise<void>}
   */
  async addForAuthenticatedExpert(academicDegree) {
    await axios.post('expert/academic-degrees', {
      academic_degree_id: academicDegree.id,
      conferment_year: academicDegree.confermentYear,
    });
  },
  /**
   * @param {string} academicDegreeId
   * @returns {Promise<void>}
   */
  async removeForAuthenticatedExpert(academicDegreeId) {
    await axios.delete(`expert/academic-degrees/${academicDegreeId}`);
  },
};
