<template>
  <div class="wrap">
    <main class="main">
      <div class="main__wrap main__wrap--single">
        <div class="main__header">
          <div class="main__title">
            <!--  Добавить получение названия  -->
            <div class="title">
              Аккредитация №{{ expertise.number }} {{ expertise.organization.shortName }}
            </div>
          </div>
        </div>
        <div class="main__tabs">
          <nav-tabs :links="links" />
        </div>

        <router-view :expert-is-chairman="expertise.expert.isChairman" />
      </div>
    </main>
  </div>
</template>

<script>
import NavTabs from '@/components/NavTabs';
import { Expertises } from '@/repositories';
export default {
  name: 'ExpertiseLayout',
  components: { NavTabs },
  created() {
    this.getExpertiseDescription();
  },
  watch: {
    id() {
      this.getExpertiseDescription();
    },
  },
  data() {
    return {
      expertise: {
        number: null,
        organization: {
          shortName: null,
        },
        expert: {
          isChairman: false,
        },
        application: {
          hasRequest: false,
        },
      },
    };
  },
  computed: {
    links() {
      const id = this.id;
      const links = [
        {
          label: 'Свойства заявления',
          to: { name: 'expert.expertises.show', params: { id } },
        },
        {
          label: 'Состав группы',
          to: { name: 'expert.expertises.show.experts', params: { id } },
        },
        { label: 'Задания', to: { name: 'expert.expertises.show.tasks', params: { id } } },
        {
          label: 'Документы для эксперта',
          to: { name: 'expert.expertises.show.documents-for-expert', params: { id } },
        },
        {
          label: 'Документы от эксперта',
          to: { name: 'expert.expertises.show.expert-documents', params: { id } },
        },
        {
          label: 'Документы заявления',
          to: { name: 'expert.expertises.show.application-documents', params: { id } },
        },
      ];
      if (this.expertise.application.hasRequest) {
        links.push({
          label: 'Документы по запросу',
          to: { name: 'expert.expertises.show.requested-documents', params: { id } },
        });
      }
      return links;
    },
  },
  methods: {
    getExpertiseDescription() {
      Expertises.getDescription(this.id).then((expertise) => (this.expertise = expertise));
    },
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
};
</script>