<template>
  <main class="main">
    <div class="main__wrap">
      <div class="main__header">
        <div class="title">
          {{ organizationName }}
        </div>
      </div>

      <div class="main__body">
        <div class="main__block">
          <div class="block">
            <div class="block__main">
              <div class="row row--aife">
                <div class="col col--third">
                  <input-text
                    label="Регистрационный номер лицензии"
                    :model-value="license.registrationNumber"
                    is-disabled
                  />
                </div>
                <div class="col col--two-thirds">
                  <input-text label="Решение о предоставлении" :model-value="order" is-disabled />
                </div>
                <div class="col col--third">
                  <input-text
                    label="Срок действия"
                    :model-value="license.expiresAt || 'Бессрочная'"
                    is-disabled
                  />
                </div>
                <div class="col col--two-thirds">
                  <input-text
                    label="Текущий статус лицензии"
                    :model-value="license.status"
                    is-disabled
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="main__block">
          <div class="block">
            <div class="block__top">
              <div class="block-title">Сведения о лицензируемом виде деятельности</div>
            </div>

            <div class="block__main">
              <div class="row row--aife">
                <div class="col col--full">
                  <custom-select
                    label="Организация"
                    placeholder="Выберите"
                    :options="options"
                    v-model="selectedOrganizationIndex"
                  />
                </div>
                <div class="col col--third">
                  <input-text
                    label="Статус"
                    :model-value="selectedOrganization.status"
                    is-disabled
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="main__block">
          <div class="block">
            <div class="block__main">
              <div class="tbl-wrap">
                <table class="tbl">
                  <thead>
                  <tr>
                    <th v-for="column in $options.tableColumns" :key="column">{{ column }}</th>
                  </tr>
                  </thead>
                  <tbody>
                  <template v-for="(rows, index) in tableRows" :key="index">
                    <tr>
                      <td :colspan="$options.tableColumns.length" class="tbl-panel">
                        {{ rows.title }}
                      </td>
                    </tr>
                    <tr v-for="(row, index) in rows.rows" :key="index">
                      <td>{{ row[0] }}</td>
                      <td>{{ row[1] }}</td>
                      <td>{{ row[2] }}</td>
                      <td>
                        <span v-for="qualification in row[3]?.split('\\n')" :key="qualification">
                        {{ qualification }} <br/>
                        </span>
                      </td>
                    </tr>
                  </template>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="main__footer main__footer--sticky">
        <div class="line line--jcfe">
          <div class="line__item">
            <report-error-button />
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import InputText from '@/components/InputText';
import CustomSelect from '@/components/CustomSelect';
import ReportErrorButton from '@/components/ReportErrorButton';
import { educationalOrganization } from '@/repositories';
import { groupBy } from '@/utils/helpers';

export default {
  name: 'Licenses',
  components: { InputText, CustomSelect, ReportErrorButton },
  tableColumns: ['Код', 'Наименование', 'Уровень образования', 'Квалификация'],
  created() {
    educationalOrganization.getLicense().then((license) => (this.license = license));
    if (this.$store.state.eo.name != null) {
      this.organizationName = this.$store.state.eo.name;
    } else {
      this.$store.dispatch('eo/getName').then(name => this.organizationName = name);
    }
  },
  data() {
    return {
      license: {
        organizations: [{ educationPrograms: [] }],
      },
      organizationName: '',
      selectedOrganizationIndex: 0,
    };
  },
  computed: {
    selectedOrganization() {
      return this.license.organizations[this.selectedOrganizationIndex] || [];
    },
    options() {
      return this.license.organizations.map((organization, index) => {
        const orderDate = this.$filters.formatDate(organization.orderDate);
        return {
          value: index,
          text: `${organization.organizationName} (№${organization.orderNumber} от ${orderDate})`,
        };
      });
    },
    order() {
      if (this.license.orderDate && this.license.orderNumber) {
        const orderDate = this.$filters.formatDate(this.license.orderDate);
        return `Распоряжение ${this.license.orderNumber} от ${orderDate}`;
      }

      return 'Нет данных';
    },
    tableRows() {
      const groupedPrograms = groupBy(
        this.selectedOrganization.educationPrograms,
        'educationLevel',
        'Другие'
      );

      return Object.keys(groupedPrograms).map((key) => {
        return {
          title: key,
          rows: groupedPrograms[key].map(this.programToRow),
        };
      });
    },
  },
  methods: {
    programToRow({ code, name, educationLevel, qualification }) {
      return [code, name, educationLevel, qualification];
    },
  },
};
</script>
