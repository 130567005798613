<template>
  <div class="main__body">
    <div class="main__block">
      <template v-if="expert">
        <expert-details v-if="!expert.is_organization" :expert="expert" />
        <organization-details v-if="expert.is_organization" :organization="expert" />
      </template>
    </div>
    <div v-if="application.commentary" class="main__block">
      <text-area label="Комментарий" :model-value="application.commentary" is-disabled />
    </div>
  </div>
</template>

<script>
import { ExpertApplicationsRepository } from '@/repositories/expert/ExpertApplicationsRepository';
import { Expert } from '@/repositories';
import ExpertDetails from '@/components/blocks/expert/application/ExpertDetails';
import OrganizationDetails from '@/components/blocks/expert/application/OrganizationDetails';
import TextArea from '@/components/TextArea';

export default {
  name: 'ApplicationDetails',
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  created() {
    ExpertApplicationsRepository.find(this.id).then(
      (application) => (this.application = application)
    );
    Expert.getInfo().then((expert) => (this.expert = expert));
  },
  data() {
    return {
      application: {},
      expert: null,
    };
  },
  components: {
    OrganizationDetails,
    ExpertDetails,
    TextArea,
  },
};
</script>
