import axios from '@/api/axios';

export const ExpertAcademicRanksRepository = {
  /**
   * @param {object} academicRank
   * @param {string} academicRank.id
   * @param {number} academicRank.confermentYear
   * @param {string} academicRank.fieldOfActivity
   * @returns {Promise<void>}
   */
  async addForAuthenticatedExpert(academicRank) {
    await axios.post('expert/academic-ranks', {
      academic_rank_id: academicRank.id,
      conferment_year: academicRank.confermentYear,
      field_of_activity: academicRank.fieldOfActivity,
    });
  },
  /**
   * @param {string} academicRankId
   * @returns {Promise<void>}
   */
  async removeForAuthenticatedExpert(academicRankId) {
    await axios.delete(`expert/academic-ranks/${academicRankId}`);
  },
};
