import Experts from '@/views/registries/experts/Experts';
import Expert from '@/views/registries/experts/Expert';

export const routes = [
  {
    path: '/registries/experts',
    component: Experts,
  },
  {
    path: '/registries/experts/:id',
    component: Expert,
    props: true,
    name: 'registries.experts.show',
  },
];
