<template>
  <div class="block">
    <div class="block__top">
      <div class="block-title">Реквизиты организации</div>
    </div>

    <div class="block__main">
      <div class="row row--aife">
        <div v-if="taxRegistration.inn !== undefined" class="col col--third">
          <input-text label="ИНН" :model-value="taxRegistration.inn" is-disabled />
        </div>
        <div v-if="taxRegistration.kpp !== undefined" class="col col--third">
          <input-text label="КПП" :model-value="taxRegistration.kpp" is-disabled />
        </div>
        <div v-if="taxRegistration.registrationDate !== undefined" class="col col--third">
          <input-text
            label="Дата постановки на налоговый учет"
            :model-value="taxRegistration.registrationDate"
            button="calendar"
            is-date
            is-disabled
          />
        </div>
        <div v-if="taxRegistration.certificateNumber !== undefined" class="col col--third">
          <input-text
            label="№ свидетельства о постановке на налоговый учет"
            :model-value="taxRegistration.certificateNumber"
            is-disabled
          />
        </div>
        <div v-if="taxRegistration.certificateDate !== undefined" class="col col--third">
          <input-text
            label="Дата свидетельства о постановке на налоговый учет"
            :model-value="taxRegistration.certificateDate"
            button="calendar"
            is-date
            is-disabled
          />
        </div>
        <div v-if="stateRegistration.ogrn !== undefined" class="col col--third">
          <input-text label="ОГРН" :model-value="stateRegistration.ogrn" is-disabled />
        </div>
        <div v-if="stateRegistration.certificateNumber !== undefined" class="col col--third">
          <input-text
            label="№ свидетельства о государственной регистрации"
            :model-value="stateRegistration.certificateNumber"
            is-disabled
          />
        </div>
        <div v-if="stateRegistration.certificateDate !== undefined" class="col col--third">
          <input-text
            label="Дата свидетельства о государственной регистрации"
            :model-value="stateRegistration.certificateDate"
            button="calendar"
            is-date
            is-disabled
          />
        </div>
        <div v-if="stateRegistration.registrationAddress !== undefined" class="col col--full">
          <input-text
            label="Адреса места нахождения органа, осуществившего государственную регистрацию"
            :model-value="stateRegistration.registrationAddress"
            is-disabled
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputText from '@/components/InputText';
export default {
  name: 'OrganizationDetails',
  components: { InputText },
  props: {
    taxRegistration: {
      type: Object,
      default: () => ({}),
    },
    stateRegistration: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
