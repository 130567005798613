<template>
  <div class="block">
    <div class="block__top">
      <div class="block-title">Место нахождения</div>
    </div>

    <div class="block__main">
      <div class="row row--aife">
        <div class="col col--full">
          <input-text label="Страна" :model-value="country" is-disabled />
        </div>
        <div class="col col--full">
          <input-text label="Регион" :model-value="region" is-disabled />
        </div>
        <div class="col col--full">
          <input-text label="Город" :model-value="city" is-disabled />
        </div>
        <div class="col col--full">
          <input-text label="Место нахождения" :model-value="fullAddress" is-disabled />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputText from '@/components/InputText';
export default {
  name: 'LocationBlock',
  components: { InputText },
  props: {
    country: String,
    region: String,
    city: String,
    fullAddress: String,
  },
};
</script>
