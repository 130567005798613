<template>
  <div class="block">
    <div class="block__top">
      <div class="block-title">Руководитель организации</div>
    </div>

    <div class="block__main">
      <div class="row row--aife">
        <div class="col col--third">
          <input-text label="Фамилия" :model-value="surname" is-disabled />
        </div>
        <div class="col col--third">
          <input-text label="Имя" :model-value="name" is-disabled />
        </div>
        <div class="col col--third">
          <input-text label="Отчество" :model-value="patronymic" is-disabled />
        </div>
        <div class="col col--full">
          <input-text label="Должность" :model-value="position" is-disabled />
        </div>
        <div class="col col--third">
          <input-text
            input-type="tel"
            prefix="+7"
            label="Тел. руководителя"
            :model-value="phoneNumber"
            is-disabled
          />
        </div>
        <div class="col col--third">
          <input-text
            input-type="tel"
            prefix="+7"
            label="Факс руководителя"
            :model-value="fax"
            is-disabled
          />
        </div>
        <div class="col col--third">
          <input-text label="E-mail руководителя" :model-value="email" is-disabled />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputText from '@/components/InputText';
export default {
  name: 'OrganizationHead',
  components: { InputText },
  props: {
    surname: String,
    name: String,
    patronymic: String,
    position: String,
    phoneNumber: String,
    fax: String,
    email: String,
  },
};
</script>
