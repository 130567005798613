export class ValidationError extends Error {
  /**
   * @var {Object.<string, string>}
   */
  errors;

  /**
   * @param {Object.<string, string>} errors Errors that happened to during validation
   */
  constructor(errors) {
    super();
    this.errors = { ...errors };
  }

  /**
   * Returns an array with all error messages
   * @return string[]
   */
  getAllErrors() {
    return Object.values(this.errors).flat();
  }
}
