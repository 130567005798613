<template>
  <div class="main__body" v-bind="$attrs" >
    <div class="main__block">
      <div class="block">
        <div class="block__main">
          <div class="row row--aife">
            <div class="col col--full">
              <switch-input
                type="checkbox"
                size="m"
                label="Экспертиза без выезда"
                disabled
                :model-value="expertise.noLeave"
              />
            </div>
            <div class="col col--fourth">
              <input-text label="Экспертиза №" :model-value="expertise.number" isDisabled />
            </div>
            <div class="col col--fourth">
              <input-text
                label="Дата начала экспертизы"
                :model-value="this.$filters.formatDate(expertise.startsAt)"
                button="calendar"
                isDisabled
              />
            </div>
            <div class="col col--fourth">
              <input-text
                label="Дата окончания экспертизы"
                :model-value="this.$filters.formatDate(expertise.endsAt)"
                button="calendar"
                isDisabled
              />
            </div>
            <div class="col col--full">
              <text-area
                label="Организация"
                :model-value="expertise.organization?.name"
                isDisabled
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="main__block">
      <div class="block">
        <div class="block__top">
          <div class="block-title">Содержание заявления</div>
        </div>

        <div class="block__main">
          <div class="row row--aife">
            <div class="col col--fourth">
              <input-text label="Номер заявления" :model-value="applicationNumber" isDisabled />
            </div>
            <div class="col col--three-fourths"></div>
            <div class="col col--half">
              <div class="form-label">Копия заявления</div>
              <div class="files">
                <file-item
                  :name="getFileName(expertise.application?.copy?.fileName)"
                  :extension="getFileExtension(expertise.application?.copy?.filePath)"
                  :link="
                    expertise.application?.copy?.filePath
                      ? `/storage/applications/${expertise.application?.copy?.filePath}`
                      : null
                  "
                />
              </div>
            </div>
            <div class="col col--full">
              <input-text
                label="Тип заявления"
                :model-value="expertise.application?.type"
                isDisabled
              />
            </div>
            <div class="col col--full">
              <input-text label="Основание заявления" :model-value="reasons" isDisabled />
            </div>
            <div class="col col--half">
              <input-text label="Предыдущее свидетельство" :model-value="certificate" isDisabled />
            </div>
            <div class="col col--half">
              <input-text label="Действующая лицензия" :model-value="license" isDisabled />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="main__block">
      <div class="block">
        <div class="block__top">
          <div class="block-title">Контактные данные</div>
        </div>

        <div class="block__main">
          <div class="row row--aife">
            <div class="col col--full"></div>
            <div class="col col--full">
              <input-text
                label="Место нахождения"
                :model-value="expertise.organization?.address"
                isDisabled
              />
            </div>
            <div class="col col--full">
              <input-text
                label="Телефон(ы)"
                :model-value="expertise.organization?.contacts?.phoneNumbers"
                isDisabled
              />
            </div>
            <div class="col col--full">
              <input-text
                label="Факс(ы)"
                :model-value="expertise.organization?.contacts?.faxes"
                isDisabled
              />
            </div>
            <div class="col col--full">
              <input-text
                label="E-mail"
                :model-value="expertise.organization?.contacts?.emails"
                isDisabled
              />
            </div>
            <div class="col col--full">
              <input-text
                inputType="url"
                label="Веб-сайт"
                :model-value="expertise.organization?.contacts?.site"
                isDisabled
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="main__block">
      <div class="block">
        <div class="block__top">
          <div class="block-title">Руководитель организации</div>
        </div>

        <div class="block__main">
          <div class="row row--aife">
            <div class="col col--fourth">
              <input-text
                label="Фамилия"
                :model-value="expertise.organization?.head?.surname"
                isDisabled
              />
            </div>
            <div class="col col--fourth">
              <input-text
                label="Имя"
                :model-value="expertise.organization?.head?.firstName"
                isDisabled
              />
            </div>
            <div class="col col--fourth">
              <input-text
                label="Отчество"
                :model-value="expertise.organization?.head?.patronymic"
                isDisabled
              />
            </div>
            <div class="col col--three-fourths">
              <input-text
                label="Должность"
                :model-value="expertise.organization?.head?.position"
                isDisabled
              />
            </div>
            <div class="col col--fourth"></div>
            <div class="col col--fourth">
              <input-text
                label="Тел. руководителя"
                inputType="tel"
                :model-value="expertise.organization?.head?.phoneNumbers"
                isDisabled
              />
            </div>
            <div class="col col--fourth">
              <input-text
                label="Факс руководителя"
                inputType="tel"
                :model-value="expertise.organization?.head?.faxes"
                isDisabled
              />
            </div>
            <div class="col col--fourth">
              <input-text
                label="E-mail руководителя"
                :model-value="expertise.organization?.head?.emails"
                isDisabled
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="expertise.responseGiven === false" class="main__footer main__footer--sticky">
    <div class="line">
      <div class="line__item line__item--mra"><custom-button label="Предоставить согласие" @click="openModal('accept')"/></div>
      <div class="line__item"><custom-button label="Предоставить отказ" color="gray" @click="openModal('refuse')" /></div>
    </div>
  </div>

  <generic-modal v-if="expertise.responseGiven === false" :opened="modals.accept.open" @close="closeModal('accept')" close-button>
    <template #header><div class="modal-box__title">Согласие на участие в экспертизе</div></template>
    <template #body>
      <div class="col col--full">
        <file-attach label="Скан согласия" v-model="modals.accept.file" is-required/>
      </div>
      <div class="col">
        <text-area label="Примечание" v-model="modals.accept.comment"/>
      </div>
    </template>
    <template #footer><custom-button label="Сохранить" @click="acceptExpertise"/></template>
  </generic-modal>

  <generic-modal v-if="expertise.responseGiven === false" :opened="modals.refuse.open" @close="closeModal('refuse')" close-button>
    <template #header><div class="modal-box__title">Отказ от участия в экспертизе</div></template>
    <template #body>
      <div class="col">
        <text-area label="Примечание" v-model="modals.refuse.comment"/>
      </div>
    </template>
    <template #footer><custom-button label="Сохранить" @click="refuseExpertise"/></template>
  </generic-modal>
</template>

<script>
import CustomButton from '@/components/CustomButton';
import FileAttach from '@/components/FileAttach';
import GenericModal from '@/components/modals/GenericModal';
import InputText from '@/components/InputText';
import TextArea from '@/components/TextArea';
import SwitchInput from '@/components/SwitchInput';
import FileItem from '@/components/FileItem';
import { Expertises } from '@/repositories';
import { getFileExtension, getFileName } from '@/utils/helpers';
export default {
  name: 'ExpertiseProperties',
  components: { GenericModal, CustomButton, FileAttach, InputText, TextArea, SwitchInput, FileItem },
  created() {
    this.getProperties();
  },
  watch: {
    id() {
      this.getProperties();
    },
  },
  data() {
    return {
      expertise: {},
      modals: {
        accept: {
          open: false,
          file: null,
          comment: null,
        },
        refuse: {
          open: false,
          comment: null,
        },
      },
    };
  },
  methods: {
    getProperties() {
      Expertises.getProperties(this.id).then((expertise) => (this.expertise = expertise));
    },
    getFileExtension(filePath) {
      return filePath == null ? null : getFileExtension(filePath);
    },
    getFileName(fileName) {
      return fileName == null ? null : getFileName(fileName);
    },
    openModal(modal) {
      this.closeModals();

      if (modal in this.modals) {
        this.modals[modal].open = true;
      }
    },
    closeModals() {
      for (const modal in this.modals) {
        if (!Object.prototype.hasOwnProperty.call(this.modals, modal)) {
          continue;
        }
        this.modals[modal].open = false;
      }
    },
    closeModal(modal) {
      if (modal in this.modals) {
        this.modals[modal].open = false;
      }
    },
    async acceptExpertise() {
      /* TODO Add form errors handling */
      if (!this.modals.accept.file) {
        return window.alert('Необходимо приложить скан согласия!');
      }

      const success = await Expertises.accept(
        this.id,
        this.modals.accept.file,
        this.modals.accept.comment
      );
      if (!success) {
        window.alert(
            'Не удалось согласиться на участие в экспертизе. Попробуйте, пожалуйста, позже!'
        );
        return;
      }

      this.closeModal('accept');
      this.resetModal('accept');

      this.updateResponseStatus();
    },
    async refuseExpertise() {
      const success = await Expertises.refuse(this.id, this.modals.refuse.comment);
      if (!success) {
        window.alert(
          'Не удалось отказаться от участия в экспертизе. Попробуйте, пожалуйста, позже!'
        );
        return;
      }

      this.closeModal('refuse');
      this.resetModal('refuse');

      this.updateResponseStatus();
    },
    resetModal(modal) {
      if (modal === 'accept') {
        this.modals.accept.comment = null;
        this.modals.accept.file = null;
      }

      if (modal === 'refuse') {
        this.modals.refuse.comment = null;
      }
    },
    updateResponseStatus(given = true) {
      this.expertise.responseGiven = given;
      this.getProperties();
    }
  },
  computed: {
    applicationNumber() {
      const number = this.expertise.application?.registrationNumber || '';
      const date = this.$filters.formatDate(this.expertise.application?.registeredAt) || '';
      return number && date ? `${number} от ${date}` : '';
    },
    reasons() {
      return this.expertise.application?.reasons?.map((reason) => reason.name).join('; ');
    },
    certificate() {
      const number = this.expertise.application?.certificate?.registrationNumber || '';
      const date =
        this.$filters.formatDate(this.expertise.application?.certificate?.issuedAt) || '';
      const status = this.expertise.application?.certificate?.status?.name || '';
      return number && date && status ? `№ ${number} от ${date} (${status})` : '';
    },
    license() {
      const number = this.expertise.application?.license?.registrationNumber || '';
      const date = this.$filters.formatDate(this.expertise.application?.license?.orderDate) || '';
      const status = this.expertise.application?.license?.status?.name || '';
      return number && date && status ? `№ ${number} от ${date} (${status})` : '';
    },
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
};
</script>
