import ExpertLayout from '@/layouts/ExpertLayout';
import MainPageLayout from '@/layouts/expert/MainPageLayout';
import GeneralInfo from '@/views/expert/GeneralInfo';
import Accreditations from '@/views/expert/Accreditations';
import ExpertisesLayout from '@/layouts/expert/ExpertisesLayout';
import Expertises from '@/views/expert/Expertises';
import ExpertiseLayout from '@/layouts/expert/ExpertiseLayout';
import ExpertiseProperties from '@/views/expert/ExpertiseProperties';
import ExpertiseExperts from '@/views/expert/ExpertiseExperts';
import ExpertiseTasks from '@/views/expert/expertise/ExpertiseTasks';
import ExpertiseDocumentsForExpert from '@/views/expert/expertise/ExpertiseDocumentsForExpert';
import ExpertiseExpertDocuments from '@/views/expert/expertise/ExpertiseExpertDocuments';
import ApplicationDocuments from '@/views/expert/expertise/ApplicationDocuments';
import RequestedDocuments from '@/views/expert/expertise/RequestedDocuments';
import Applications from '@/views/expert/Applications';
import ApplicationLayout from '@/layouts/expert/ApplicationLayout';
import ApplicationDetails from '@/views/expert/applications/ApplicationDetails';
import ApplicationPrograms from '@/views/expert/applications/ApplicationPrograms';
import ExpertApplicationDocuments from '@/views/expert/applications/ApplicationDocuments';

export const routes = [
  {
    path: '/expert',
    component: ExpertLayout,
    redirect: { name: 'expert.home' },
    meta: {
      requiresAuth: true,
      role: 'expert',
    },
    children: [
      {
        path: 'home',
        component: MainPageLayout,
        children: [
          {
            path: '',
            name: 'expert.home',
            component: GeneralInfo,
          },
          {
            path: 'accreditation',
            name: 'expert.accreditation',
            component: Accreditations,
          },
          {
            path: 'applications',
            name: 'expert.applications.index',
            component: Applications,
          },
        ],
      },
      {
        path: 'applications/:id',
        component: ApplicationLayout,
        props: true,
        children: [
          {
            path: '',
            component: ApplicationDetails,
            name: 'expert.applications.show.details',
            props: true,
          },
          {
            path: 'programs',
            component: ApplicationPrograms,
            name: 'expert.applications.show.programs',
            props: true,
          },
          {
            path: 'documents',
            component: ExpertApplicationDocuments,
            name: 'expert.applications.show.documents',
            props: true,
          },
        ],
      },
      {
        path: 'expertises',
        component: ExpertisesLayout,
        children: [
          {
            path: '',
            component: Expertises,
            name: 'expert.expertises.index',
          },
        ],
      },
      {
        path: 'expertises/:id',
        component: ExpertiseLayout,
        props: true,
        children: [
          {
            path: '',
            component: ExpertiseProperties,
            name: 'expert.expertises.show',
            props: true,
          },
          {
            path: 'experts',
            component: ExpertiseExperts,
            name: 'expert.expertises.show.experts',
            props: true,
          },
          {
            path: 'tasks',
            component: ExpertiseTasks,
            name: 'expert.expertises.show.tasks',
            props: true,
          },
          {
            path: 'documents-for-expert',
            component: ExpertiseDocumentsForExpert,
            name: 'expert.expertises.show.documents-for-expert',
            props: true,
          },
          {
            path: 'expert-documents',
            component: ExpertiseExpertDocuments,
            name: 'expert.expertises.show.expert-documents',
            props: true,
          },
          {
            path: 'application-documents',
            component: ApplicationDocuments,
            name: 'expert.expertises.show.application-documents',
            props: true,
          },
          {
            path: 'requested-documents',
            component: RequestedDocuments,
            name: 'expert.expertises.show.requested-documents',
            props: true,
          },
        ],
      },
    ],
  },
];
