<template>
  <div class="tabs" :class="`tabs--${size} tabs--${type}`">
    <ul class="tabs__list">
      <li v-for="(link, index) in links" :key="index" class="tabs__item">
        <component
          :is="link.to ? 'router-link' : 'a'"
          :to="link.to"
          class="tabs__link"
          :class="{ 'tabs__link--disabled': link.disabled }"
          exact-active-class="tabs__link--active"
          >{{ link.label }}</component
        >
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'NavTabs',
  props: {
    size: {
      type: String,
      default: 'm',
    },
    type: {
      type: Number,
      default: 2,
    },
    links: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
