import { helpers } from '@vuelidate/validators';

function isExactLength(length) {
  return function (value) {
    return !helpers.req(value) || value.length === length;
  };
}

export const exactLength = function (length) {
  return {
    $validator: isExactLength(length),
    $message: `Поле должно содержать ${length} символов`,
    $params: {
      length,
      type: 'exactLength',
    },
  };
};
