<template>
  <div class="main__body">
    <div class="main__block">
      <div class="block">
        <div class="block__main">
          <div class="tbl-wrap">
            <table class="tbl">
              <thead>
              <tr>
                <th v-for="column in $options.columns" :key="column">{{ column }}</th>
              </tr>
              </thead>
              <tbody>
              <template v-if="experts">
                <template v-if="experts.length > 0">
                  <tr v-for="expert in experts" :key="expert.id" class="tbl-link">
                    <td>{{ expert.fullName }}</td>
                    <td>{{ expert.role }}</td>
                    <td>{{ expert.worksAt }}</td>
                    <td>{{ expert.position }}</td>
                    <td>{{ expert.contacts?.phoneNumber }}</td>
                    <td>{{ expert.contacts?.email }}</td>
                  </tr>
                </template>
                <tr v-else>
                  <td :colspan="$options.columns.length" class="align-center">Эксперты не найдены</td>
                </tr>
              </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Expertises } from '@/repositories';

export default {
  name: 'ExpertiseExperts',
  columns: ['Эксперт', 'Роль', 'Место работы', 'Должность', 'Телефон',  'E-mail'],
  created() {
    this.getExperts();
  },
  watch: {
    id() {
      this.getExperts();
    }
  },
  data() {
    return {
      experts: null,
    };
  },
  methods: {
    getExperts() {
      Expertises.getExperts(this.id).then(experts => this.experts = experts);
    }
  },
  props: {
    id: {
      type: String,
      required: true,
    }
  }
};
</script>
