import axios from '@/api/axios';

export const Monitoring = {
  /** @return {Promise<Tasks[]>} */
  async getTasks() {
    const response = await axios.get('monitoring/tasks');
    return response.data;
  },
  async getTask(taskId) {
    const response = await axios.get(`monitoring/tasks/${taskId}`);
    return response.data;
  },
  async getOrganizationTasks(filters) {
    const response = await axios.get('monitoring/organization-tasks?', { params: filters });
    return response.data;
  },
  async getMonitoringDataReportTemplate(programId) {
    const response = await axios.get(
      `monitoring/get-monitoring-data-report-template/${programId}`,
      {
        responseType: 'blob',
      }
    );
    return new File([response.data], 'Данные аккредитационного мониторинга.docx');
  },
  async getMonitoringResultReportTemplate(programId) {
    const response = await axios.get(
      `monitoring/get-monitoring-result-report-template/${programId}`,
      {
        responseType: 'blob',
      }
    );
    return new File([response.data], 'Результаты аккредитационного мониторинга.docx');
  },
  async closeTask(taskId) {
    await axios.post(`monitoring/close-task/${taskId}`);
  },
  async sendProgram(programId, programFile) {
    await axios.post(`monitoring/send-program/${programId}`, programFile);
  },
  /** @return {Promise<Programs[]>} */
  async getPrograms(taskId, levelsIds) {
    const response = await axios.get(`monitoring/get-programs/${taskId}`, {
      params: { levels: levelsIds },
    });
    return response.data;
  },
  async getProgram(programId) {
    const response = await axios.get(`monitoring/programs/${programId}`);
    return response.data;
  },
  async rejectTaskProgram(programId, isRejected, rejectReason) {
    await axios.patch(`monitoring/programs/${programId}`, {
      is_rejected: isRejected,
      reject_reason: rejectReason,
    });
  },
  async addTaskProgram(taskId, addingProgramForm) {
    await axios.post('monitoring/programs', {
      task_id: taskId,
      licensed_program_id: addingProgramForm.licencedProgramId,
      edu_program_name: addingProgramForm.eduProgramName,
      add_reason: addingProgramForm.addReason,
    });
  },
  async addBasicTaskProgram(taskId, eduLevelName) {
    await axios.post('monitoring/add-basic-program', {
      task_id: taskId,
      edu_level_name: eduLevelName,
    });
  },
  async replaceTaskProgram(programId, replacingProgramForm) {
    await axios.post(`monitoring/replace-program/${programId}`, {
      program_id:
        !replacingProgramForm.licencedProgramId || replacingProgramForm.licencedProgramId === 'null'
          ? null
          : replacingProgramForm.licencedProgramId,
      edu_program_name: replacingProgramForm.eduProgramName,
      replace_reason: replacingProgramForm.replaceReason,
    });
  },
  async getUgsnIds() {
    const response = await axios.get('monitoring/get-ugsn-ids');
    return response.data;
  },
  async getLicensedProgramsByUgsnId(ugsnId) {
    const response = await axios.get(`monitoring/get-licensed-programs-by-ugsn-id/${ugsnId}`);
    return response.data;
  },
  async getLicensedPrograms(organizationId, eduLevelId) {
    const response = await axios.get(
      `monitoring/get-licensed-programs/${organizationId}/${eduLevelId}`
    );
    return response.data;
  },
  async updateDvi(programId, dvi) {
    const response = await axios.patch(`monitoring/programs/${programId}`, {
      dvi: dvi,
    });
    return response.data;
  },
  async updateEduFormat(programId, educationForm) {
    const response = await axios.patch(`monitoring/programs/${programId}`, {
      education_form: educationForm,
    });
    return response.data;
  },
  async updateAdmission(programId, admission) {
    const response = await axios.patch(`monitoring/programs/${programId}`, {
      admission: admission,
    });
    return response.data;
  },
  async updateGraduationIn2021(programId, graduationIn2021) {
    const response = await axios.patch(`monitoring/programs/${programId}`, {
      graduation_in_2021: graduationIn2021,
    });
    return response.data;
  },
  async updateGraduationIn2022(programId, graduationIn2022) {
    const response = await axios.patch(`monitoring/programs/${programId}`, {
      graduation_in_2022: graduationIn2022,
    });
    return response.data;
  },
  async updateDemo(programId, demo) {
    const response = await axios.patch(`monitoring/update-demo/${programId}`, {
      demo: demo,
    });
    return response.data;
  },
  async updateRecruitBase(programId, recruitBase) {
    const response = await axios.patch(`monitoring/programs/${programId}`, {
      recruit_base: recruitBase,
    });
    return response.data;
  },
  async updateIsAdaptedFlag(programId, isAdapted) {
    const response = await axios.patch(`monitoring/update-is-adapted-flag/${programId}`, {
      is_adapted: isAdapted,
    });
    return response.data;
  },
  async saveProgramMarkDisagreementReasons(programMarkId, disagreementReasons) {
    await axios.patch(`monitoring/save-program-mark-disagreement-reasons/${programMarkId}`, {
      disagreement_reasons: disagreementReasons,
    });
  },
  async getProgramMarksByProgramId(program) {
    const response = await axios.get(`monitoring/get-program-marks-by-program/${program}`);
    return response.data;
  },
  async getProgramMarkData(markId) {
    const response = await axios.get(`monitoring/get-program-mark-data/${markId}`);
    return response.data;
  },
  async saveProgramMarkDataValues(markId, markData, taskId) {
    await axios.post(`monitoring/save-program-mark-data-values/${markId}`, {
      data: markData,
      taskId: taskId,
    });
  },
};

/**
 * @typedef Tasks
 * @property {string} id
 * @property {string} data_start
 * @property {string|null} data_end
 * @property {string} organization_id
 * @property {string} status_id
 */

/**
 * @typedef Programs
 * @property {string} Id
 * @property {string} fk_eiisEducationalOrganization
 * @property {string} Name
 * @property {string} name
 */
