<template>
  <generic-modal :opened="opened" @close="$emit('close')">
    <template v-slot:header>
      <div class="modal-box__title">Хотите открепить ЭЦП?</div>
      <button class="link link--gray modal-close" type="button" @click="$emit('close')">
        <svg-icon name="cross" :size="24"/>
      </button>
    </template>
    <template v-slot:body>
      <div class="modal-box__cover">
        <img src="~@/assets/images/img-signature.svg" alt="Изображение электронной подписи">
      </div>
    </template>
    <template v-slot:footer>
      <div class="line line--jcsb">
        <div class="line__item">
          <a :href="unsignedPath">
            <custom-button label="Открепить и скачать" :type="2"/>
          </a>
        </div>
        <div class="line__item">
          <a :href="signedPath">
            <custom-button label="Скачать с ЭЦП"/>
          </a>
        </div>
      </div>
    </template>
  </generic-modal>
</template>

<script>
import GenericModal from '@/components/modals/GenericModal';
import SvgIcon from '@/components/SvgIcon';
import CustomButton from '@/components/CustomButton';
export default {
  name: 'UnsignFileModal',
  components: { GenericModal, SvgIcon, CustomButton },
  props: {
    signedPath: {
      type: String,
      required: true,
    },
    unsignedPath: {
      type: String,
      required: true
    },
    opened: Boolean
  },
  emits: ['close']
};
</script>
