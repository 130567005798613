import axios from '@/api/axios';

export const educationalOrganization = {
  /**
   * @return {Promise<EducationalOrganization>}
   */
  async getGeneralInfo() {
    const response = await axios.get('/user/organization/general-info');
    return response.data.organization;
  },
  /**
   * @return {Promise<EducationalOrganization[]>}
   */
  async getBranches() {
    const response = await axios.get('/user/organization/branches');
    return response.data.data;
  },
  /**
   * @return {Promise<number>}
   */
  async getBranchesCount() {
    const response = await axios.get('/user/organization/branches/count');
    return response.data.count;
  },
  /**
   * @return {Promise<License>}
   */
  async getLicense() {
    const response = await axios.get('/user/organization/license');
    return response.data.license;
  },
  /** @return {Promise<EOLicense[]>} */
  async getLicenses() {
    const response = await axios.get('user/organization/licenses');
    return response.data.data;
  },
  async getCertificates() {
    const response = await axios.get('user/organization/certificates');
    return response.data.data;
  },
  /**
   * @return {Promise<AccreditationCertificate[]>}
   */
  async getAccreditationCertificates() {
    const response = await axios.get('/user/organization/accreditation-certificates');
    return response.data.data;
  },
  /**
   * @param {Accreditations} accreditations
   * @return {Promise<void>}
   */
  async putAccreditations(accreditations) {
    await axios.put('user/organization/accreditations', accreditations);
  },
};

/**
 * @typedef {object} EducationalOrganization
 * @property {string} name
 * @property {string} shortName
 * @property {string} fullName
 * @property {CasedName} casedName
 * @property {string} legalForm
 * @property {string} specificity
 * @property {Founder[]} founders
 * @property {Contacts} contacts
 * @property {Location[]} locations
 * @property {Head} head
 * @property {Address} address
 * @property {Accreditations} accreditations
 * @property {TaxRegistrationAccount} taxRegistration
 * @property {StateRegistrationAccount} stateRegistration
 */

/**
 * @typedef {object} CasedName
 * @property {string} accusative
 * @property {string} dative
 * @property {string} genitive
 * @property {string} instrumental
 */

/**
 * @typedef {object} Founder
 * @property {string} name
 */

/**
 * @typedef {object} Contacts
 * @property {string} emails
 * @property {string} faxes
 * @property {string} phoneNumbers
 * @property {string} site
 */

/**
 * @typedef {object} Location
 * @property {string} address
 * @property {string} postcode
 */

/**
 * @typedef {object} Head
 * @property {string} email
 * @property {string} fax
 * @property {string} name
 * @property {string} patronymic
 * @property {string} phoneNumbers
 * @property {string} position
 * @property {string} surname
 */

/**
 * @typedef {object} Bank
 * @property {string} accountNumber
 * @property {string} bic
 * @property {string} correspondentAccount
 * @property {string} name
 */

/**
 * @typedef {object} Address
 * @property {string} country
 * @property {string} region
 * @property {string} city
 * @property {string} fullAddress
 */

/**
 * @typedef Accreditations
 * @property {Accreditation[]} public
 * @property {Accreditation[]} publicInternational
 * @property {Accreditation[]} publicProfessional
 */

/**
 * @typedef {object} Accreditation
 * @property {string} date
 * @property {string} organization
 * @property {string} requisite
 */

/**
 * @typedef {object} TaxRegistrationAccount
 * @property {string|null} [certificateDate]
 * @property {string|null} [certificateNumber]
 * @property {string|null} [inn]
 * @property {string|null} [kpp]
 * @property {string|null} [registrationDate]
 */

/**
 * @typedef {object} StateRegistrationAccount
 * @property {string|null} [certificateDate]
 * @property {string|null} [certificateNumber]
 * @property {string|null} [certificateDate]
 * @property {string|null} [certificateDate]
 */


/**
 * @typedef EOLicense
 * @property {string} id
 * @property {string|null} registrationNumber
 * @property {string|null} orderDate
 * @property {LicenseStatus|null} status
 */

/**
 * @typedef LicenseStatus
 * @property {string|null} name
 * @property {boolean} valid
 */


/**
 * @typedef {object} License
 * @property {string|null} registrationNumber
 * @property {string|null} orderDate
 * @property {string|null} orderNumber
 * @property {string|null} expiresAt
 * @property {string|null} status
 * @property {LicensedOrganization[]} organizations
 */

/**
 * @typedef {object} LicensedOrganization
 * @property {string|null} organizationName
 * @property {string|null} status
 * @property {string|null} orderDate
 * @property {string|null} orderNumber
 * @property {EducationalProgram[]} educationPrograms
 */

/**
 * @typedef {object} EducationalProgram
 * @property {string|null} name
 * @property {string|null} code
 * @property {string|null} qualification
 * @property {string|null} educationLevel
 */

/**
 * @typedef {object} AccreditationCertificate
 * @property {string|null} type
 * @property {string|null} issuedAt
 * @property {string|null} expiresAt
 * @property {string|null} status
 * @property {string|null} serialNumber
 * @property {string|null} formNumber
 * @property {object|null} controlOrgan
 * @property {string|null} controlOrgan.name
 * @property {AccreditationCertificateSupplement[]} supplements
 */

/**
 * @typedef {object} AccreditationCertificateSupplement
 * @property {string|null} status
 * @property {string|null} organizationName
 * @property {string|null} name
 * @property {AccreditedProgram[]} accreditedPrograms
 */

/**
 * @typedef {object} AccreditedProgram
 * @property {string|null} code
 * @property {string|null} name
 * @property {string|null} educationLevel
 * @property {EnlargedGroupSpeciality} enlargedGroupSpeciality
 */

/**
 * @typedef {object} EnlargedGroupSpeciality
 * @property {string|null} code
 * @property {string|null} name
 */
