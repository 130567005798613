<template>
  <aside class="side" :class="{ collapsed }">
    <div class="side__wrap">
      <div class="side__main">
        <div class="side-nav">
          <ul class="side-nav__list">
            <li v-for="link in links" :key="link.label" class="side-nav__item">
              <router-link
                :to="{ name: link.to }"
                class="side-link"
                exact-active-class="side-link--active"
              >
                <span class="side-link__icons">
                  <svg-clr :name="link.icon" :size="24" />
                  <svg-clr :name="link.icon + '-on'" :size="24" />
                </span>
                <span class="side-link__label">{{ link.label }}</span>
                <span v-if="link.qty != null" class="side-link__qty">
                  {{ link.qty }}
                </span>
              </router-link>
            </li>
          </ul>
        </div>
      </div>

      <div class="side__bottom">
        <button @click="toggle" class="side-btn" :class="{ collapsed }" type="button">
          <svg-clr name="roll-up" :size="24" />
          <svg-clr name="roll-up-on" :size="24" />
        </button>
      </div>
    </div>
  </aside>
</template>

<script>
import SvgClr from '@/components/SvgClr';
import { mapActions, mapState } from 'vuex';
export default {
  name: 'NavigationSidebar',
  components: { SvgClr },
  computed: {
    ...mapState(`sidebar`, [`collapsed`]),
  },
  methods: {
    ...mapActions(`sidebar`, [`toggle`]),
  },
  props: {
    links: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
