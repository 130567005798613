<template>
  <main class="main">
    <div class="main__wrap">
      <div class="main__body">
        <div class="main__block">
          <div class="block">
            <div class="block__top">
              <div class="block-title">Личные данные</div>
            </div>
            <div class="block__main">
              <div class="row row--aife">
                <div class="col col--full">
                  <InputText
                    label="Полное наименование организации"
                    :model-value="organization.name"
                    is-disabled
                  />
                </div>
                <div class="col col--full">
                  <InputText
                    label="Сокращенное наименование организации"
                    v-model="form.short"
                    :has-error="vuelidate.form.short.$error"
                    :vuelidate-errors="vuelidate.form.short.$errors"
                  />
                </div>
                <div class="col col--half">
                  <CustomSelect
                    label="Федеральный округ"
                    placeholder="Выберите федеральный округ"
                    v-model="federalDistrictId"
                    :options="federalDistrictOptions"
                  />
                </div>
                <div class="col col--half">
                  <CustomSelect
                    label="Регион"
                    placeholder="Выберите регион"
                    v-model="form.region_id"
                    :options="regionOptions"
                    :has-error="vuelidate.form.region_id.$error"
                    :vuelidate-errors="vuelidate.form.region_id.$errors"
                  />
                </div>
                <div class="col col--full">
                  <InputText
                    label="Адрес местонахождения"
                    v-model="form.address"
                    :has-error="vuelidate.form.address.$error"
                    :vuelidate-errors="vuelidate.form.address.$errors"
                  />
                </div>
                <div class="col col--full">
                  <InputText label="Фактический адрес" v-model="form.fact_address" />
                </div>
                <div class="col col--third">
                  <InputText label="Контактный телефон" v-model="form.contacts.phone" />
                </div>
                <div class="col col--third">
                  <InputText label="Email" v-model="form.contacts.email" />
                </div>
                <div class="col col--third">
                  <InputText
                    label='Адрес официального сайта в сети "Интернет"'
                    v-model="form.contacts.website_url"
                  />
                </div>
                <div class="col col--full">
                  <InputText label="ФИО руководителя" v-model="form.director" />
                </div>
                <div class="col col--third">
                  <InputText
                    label="ОГРН"
                    v-model="form.ogrn"
                    :has-error="vuelidate.form.ogrn.$error"
                    :vuelidate-errors="vuelidate.form.ogrn.$errors"
                  />
                </div>
                <div class="col col--third">
                  <InputText
                    label="ИНН"
                    :model-value="organization.inn"
                    is-disabled
                  />
                </div>
                <div class="col col--third">
                  <InputText
                    label="КПП"
                    v-model="form.kpp"
                    :has-error="vuelidate.form.kpp.$error"
                    :vuelidate-errors="vuelidate.form.kpp.$errors"
                  />
                </div>
                <div class="col col--third">
                  <SwitchInput
                    label="Доступ к гос. тайне"
                    v-model="form.is_gos_secret"
                    type="checkbox"
                    size="m"
                  />
                </div>
                <div class="col col--two-thirds">
                  <CustomSelect
                    v-if="form.is_gos_secret"
                    label="Уровень доступа"
                    placeholder="Укажите уровень доступа"
                    :options="accessLevelOptions"
                    v-model="form.level_access"
                  />
                </div>
              </div>
            </div>
            <div class="block__bottom">
              <div class="line line--jcsb">
                <div class="line__item"></div>
                <div class="line__item">
                  <CustomButton
                    :label="formLoading ? 'Сохраняем...' : 'Сохранить'"
                    @click="submit"
                    :disabled="formLoading"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import CustomSelect from '@/components/CustomSelect';
import InputText from '@/components/InputText';
import SwitchInput from '@/components/SwitchInput';
import CustomButton from '@/components/CustomButton';
import useVuelidate from '@vuelidate/core';
import { required } from '@/utils/validators';
import { Expert } from '@/repositories';
import { FederalDistrictsRepository } from '@/repositories/FederalDistrictsRepository';
import { RegionsRepository } from '@/repositories/RegionsRepository';

export default {
  name: 'CandidateOrganizationDetails',
  props: {
    organization: {
      type: Object,
      required: true,
    },
  },
  components: {
    CustomButton,
    CustomSelect,
    InputText,
    SwitchInput,
  },
  setup() {
    return { vuelidate: useVuelidate() };
  },
  created() {
    FederalDistrictsRepository.all().then((districts) => (this.federalDistricts = districts));
    if (this.federalDistrictId) {
      RegionsRepository.getByFederalDistrictId(this.federalDistrictId).then(
        (regions) => (this.regions = regions)
      );
    }
  },
  watch: {
    federalDistrictId(newId) {
      RegionsRepository.getByFederalDistrictId(newId).then((regions) => (this.regions = regions));
      this.form.region_id = null;
    },
  },
  data() {
    return {
      form: {
        short: this.organization.shortName,
        ogrn: this.organization.ogrn,
        kpp: this.organization.kpp,
        address: this.organization.address,
        fact_address: this.organization.actual_address,
        region_id: this.organization.region?.id,
        director: this.organization.head,
        contacts: {
          phone: this.organization.contacts?.phoneNumber,
          email: this.organization.contacts?.email,
          website_url: this.organization.contacts?.website_url,
        },
        is_gos_secret: this.organization.hasAccessToStateSecret,
        level_access: this.organization.accessLevel,
      },
      formLoading: false,
      accessLevelOptions: [
        { value: 1, text: '1-ый уровень' },
        { value: 2, text: '2-ой уровень' },
        { value: 3, text: '3-ий уровень' },
      ],
      federalDistrictId: this.organization.region?.federal_district?.id,
      federalDistricts: [],
      regions: [],
    };
  },
  validations() {
    return {
      form: {
        short: { required },
        ogrn: { required },
        kpp: { required },
        address: { required },
        region_id: { required },
      },
    };
  },
  computed: {
    federalDistrictOptions() {
      return this.federalDistricts.map((district) => ({ value: district.id, text: district.name }));
    },
    regionOptions() {
      return this.regions.map((region) => ({ value: region.id, text: region.name }));
    },
  },
  methods: {
    async submit() {
      this.vuelidate.form.$touch();
      if (this.vuelidate.form.$invalid) {
        return;
      }

      this.formLoading = true;

      try {
        await Expert.update(this.form);
      } catch (error) {
        window.alert('Не удалось сохранить данные. Попробуйте, пожалуйста, позже!');
        throw error;
      } finally {
        this.formLoading = false;
      }
    },
  },
};
</script>
