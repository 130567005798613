import axios from '@/api/axios';

export const ExpertApplicationDocumentTypesRepository = {
  /**
   * @param {number|string} id
   * @returns {Promise<ApplicationDocumentType[]>}
   */
  async getByApplicationId(id) {
    const response = await axios.get(`expert/applications/${id}/document-types`);
    return response.data.data;
  },
};

/**
 * @typedef ApplicationDocumentType
 * @property {number} id
 * @property {string} name
 */
