<template>
  <div class="wrap wrap--side" :class="{ collapsed }">
    <navigation-sidebar :links="links" />
    <router-view />
  </div>
</template>

<script setup>
import NavigationSidebar from '@/components/NavigationSidebar';
import { computed } from 'vue';
import { useStore } from 'vuex';

const store = useStore();

const links = computed(() => {
  return [
    {
      label: 'Аккредитационный мониторинг',
      to: 'monitoring.tasks',
      icon: 'accreditation-monitoring',
    },
  ];
});

const collapsed = computed(() => {
  return store.state.sidebar.collapsed;
});
</script>
