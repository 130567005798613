<template>
  <div
    class="form-field"
    :class="{ 'form-field--focus': focused }"
    @focusin="focused = true"
    @focusout="focused = false"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'FormField',
  data() {
    return {
      focused: false,
    };
  },
};
</script>
