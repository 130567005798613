import UserLayout from '@/layouts/UserLayout';
import MainPageLayout from '@/layouts/user/MainPageLayout';
import GeneralInfo from '@/views/user/GeneralInfo';
import Branches from '@/views/user/Branches';
import Licenses from '@/views/user/Licenses';
import AccreditationCertificate from '@/views/user/AccreditationCertificate';
import MonitoringLayout from '@/layouts/user/MonitoringLayout';
import MonitoringTasks from '@/views/user/MonitoringTasks';
import MonitoringTaskPrograms from '@/views/user/MonitoringTaskPrograms';
import MonitoringProgramMarksLayout from '@/layouts/user/MonitoringProgramMarksLayout';
import MonitoringProgramMarks from '@/views/user/MonitoringProgramMarks';

export const routes = [
  {
    path: '/eo',
    component: UserLayout,
    redirect: { name: 'home' },
    meta: {
      requiresAuth: true,
      role: 'user',
    },
    children: [
      {
        path: 'home',
        component: MainPageLayout,
        children: [
          {
            path: '',
            name: 'home',
            component: GeneralInfo,
          },
          {
            path: 'branches',
            name: 'branches',
            component: Branches,
          },
          {
            path: 'licenses',
            name: 'licenses',
            component: Licenses,
          },
          {
            path: 'certificate',
            name: 'accreditation-certificate',
            component: AccreditationCertificate,
          },
        ],
      },
      {
        path: 'monitoring',
        component: MonitoringLayout,
        children: [
          {
            path: '',
            name: 'monitoring.tasks',
            component: MonitoringTasks,
          },
          {
            path: 'task-programs/:id',
            name: 'monitoring.task-programs',
            component: MonitoringTaskPrograms,
          },
        ],
      },
      {
        path: 'monitoring',
        component: MonitoringProgramMarksLayout,
        children: [
          {
            path: 'program-marks/:id',
            component: MonitoringProgramMarks,
            name: 'monitoring.program-marks',
          },
        ],
      },
    ],
  },
];
