import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { filters } from '@/utils/filters';
import { Datepicker } from 'vanillajs-datepicker';
import ru from 'vanillajs-datepicker/js/i18n/locales/ru';
import axios from '@/api/axios';

store.dispatch('getAuthenticatedUser').then(() => {
  const app = createApp(App);
  app.use(store).use(router).mount('#app');
  app.config.globalProperties.$filters = filters;
});

Object.assign(Datepicker.locales, ru);

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      router.push({ name: 'login' });
    }
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 404) {
      router.replace({ name: 'not-found' });
    }

    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 503) {
      router.replace({ name: 'maintenance' });
    }

    return Promise.reject(error);
  }
);
