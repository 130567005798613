<template>
  <details class="more-wrap">
    <summary class="more-ctrl col col--full">
      <span class="more-ctrl__label">Показать склонения</span>
      <span class="more-ctrl__icon">
        <svg-icon name="arrow-down" :size="16" />
      </span>
    </summary>

    <div class="col col--full">
      <text-area
        label="Наименование организации в родительном падеже"
        :model-value="genitive"
        is-disabled
      />
    </div>
    <div class="col col--full">
      <text-area
        label="Наименование организации в винительном падеже"
        :model-value="accusative"
        is-disabled
      />
    </div>
    <div class="col col--full">
      <text-area
        label="Наименование организации в дательном падеже"
        :model-value="dative"
        is-disabled
      />
    </div>
    <div class="col col--full">
      <text-area
        label="Наименование организации в творительном падеже"
        :model-value="instrumental"
        is-disabled
      />
    </div>
  </details>
</template>

<script>
import SvgIcon from '@/components/SvgIcon';
import TextArea from '@/components/TextArea';
export default {
  name: 'CasedNames',
  components: { SvgIcon, TextArea },
  props: {
    genitive: String,
    accusative: String,
    dative: String,
    instrumental: String,
  },
};
</script>
