<template>
  <button class="gray-link">
    <span class="gray-link__icon">
      <svg-icon name="delete" :size="16" />
    </span>
    <span class="gray-link__label">Удалить</span>
  </button>
</template>

<script>
import SvgIcon from '@/components/SvgIcon';
export default {
  name: 'RemoveFieldButton',
  components: { SvgIcon },
};
</script>
