import axios from '@/api/axios';
import { ValidationError } from '@/exceptions';

export const ExpertApplicationDocumentsRepository = {
  /**
   * @param {number|string} id
   * @returns {Promise<ApplicationDocument[]>}
   */
  async getByApplicationId(id) {
    const response = await axios.get(`expert/applications/${id}/documents`);
    return response.data.data;
  },
  /**
   * @param {object} document
   * @param {number} document.expert_application_id
   * @param {number} document.document_type_id
   * @param {File} document.file
   * @returns {Promise<void>}
   * @throws ValidationError
   */
  async add(document) {
    const payload = new FormData();
    payload.append('file', document.file);
    payload.append('document_type_id', String(document.document_type_id));

    try {
      await axios.post(`expert/applications/${document.expert_application_id}/documents`, payload);
    } catch (error) {
      if (error.response?.status !== 422) {
        throw error;
      }
      throw new ValidationError(error.response.data.errors);
    }
  },
  /**
   * @param {number|string} id
   * @returns {Promise<void>}
   */
  async remove(id) {
    await axios.delete(`expert/applications/documents/${id}`);
  },
};

/**
 * @typedef ApplicationDocument
 * @property {number} id
 * @property {string} name
 * @property {string} path
 * @property {string} created_at
 * @property {number} document_type_id
 */
